/** @format */

import * as actionType from '../constants/actionType'

export const DEFAULT_STATE_FLOOR = {
    listApartments: [],
    selectedFloor: {},
    listLayout: [],
    listFloor: [],
    floorLink: '',
    isLoading: false,
    error: false,
    message: '',
    errMsg: '',
    ssMsg: '',
}

const floorReducer = (state = DEFAULT_STATE_FLOOR, action) => {
    let {type, payload} = action
    switch (type) {
        case actionType.GET_FLOOR_DETAIL_BY_CLIENT.REQUEST:
        case actionType.GET_LAYOUT.REQUEST:
        case actionType.GET_FLOOR.REQUEST:
        case actionType.ADD_FLOOR.REQUEST:
        case actionType.UPDATE_FLOOR.REQUEST:
        case actionType.GET_LIST_FLOOR_BY_BUILDING.REQUEST:
        case actionType.DELETE_FLOOR.REQUEST:
            return {
                ...state,
                isLoading: true,
                error: false,
                message: '',
            }

        case actionType.ADD_FLOOR.SUCCESS:
        case actionType.UPDATE_FLOOR.SUCCESS:
        case actionType.DELETE_FLOOR.SUCCESS:
            return {
                ...state,
                isLoading: true,
                error: false,
                message: '',
                ssMsg: 'Update success !',
            }
        case actionType.GET_FLOOR_DETAIL_BY_CLIENT.SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: '',
                listApartments: payload.listApartments,
                floorName: payload.floorName,
                floorLink: payload.floorLink,
            }

        case actionType.GET_LAYOUT_BY_BUILDING.SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: '',
                listLayout: payload.layoutData,
            }

        case actionType.GET_LAYOUT.SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: '',
                listLayout: payload,
            }

        case actionType.GET_APARTMENTLAYOUT.SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: '',
                listLayout: payload.layoutData,
            }
        case actionType.GET_FLOOR.SUCCESS:
        case actionType.GET_LIST_FLOOR_BY_BUILDING.SUCCESS:
            return {
                ...state,
                isFetching: false,
                isLoading: false,
                error: false,
                message: '',
                listFloor: payload.listFloor,
                // selectedFloor:payload.listFloor[0]
            }
        case actionType.GET_FLOOR_DETAIL_BY_CLIENT.FAILURE:
        case actionType.GET_LAYOUT.FAILURE:
        case actionType.GET_FLOOR.FAILURE:
        case actionType.ADD_FLOOR.FAILURE:
        case actionType.UPDATE_FLOOR.FAILURE:
        case actionType.GET_LIST_FLOOR_BY_BUILDING.FAILURE:
        case actionType.DELETE_FLOOR.FAILURE:
            return {
                ...state,
                isLoading: false,
                error: true,
                message: action.payload.message,
                ssMsg: '',
                errMsg: payload.message,
            }
        case 'RESET_PROJECT_STATE':
            return DEFAULT_STATE_FLOOR

        case actionType.SET_FLOOR_DEFAULT.SUCCESS:
            return {
                ...state,
                selectedFloor: payload,
            };
        default:
            return state
    }
}

export default floorReducer
