/** @format */

import {makeStyles} from '@material-ui/core'
import {isWindow} from "../../../../utils/help";

export default makeStyles((theme) => {
    return {
        overlay: {
            position: 'absolute',
            zIndex: 0,
            top: 0,
            filter: 'blur(1px)',
            width: '100vw',
            height: '100vh',
            backgroundColor: "#0000003d"
        },
        rootViewProject: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        },
        chooseBuilding: {
            zIndex: 1,
            color: "#ffffff",
            fontSize: 16,
            fontWeight: 600,
            width: "70%",
            margin: "auto",
            textAlign: "center",
            '& $chooseBuildingInput': {
                marginTop: 8,
                background: "#FDFDFD",
                // border: "1px solid #C1C1C1",
                boxSizing: "border-box",
                borderRadius: 5,
                '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                    padding: "4px 16px",
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#00030E"
                }
            }
        },
        shadowText: {
            // textShadow: '1px 2px 2px #333'
        },
        chooseBuildingInput: {},
        buildingCard: {
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            bottom: 0,
            height: (isWindow ? window.screen.width : 1920)  * (165 / 375),
            backgroundColor: 'rgba(131,135,136, 0.8)',
            '&.single': {
                '&.swiper-slide-active': {
                    width: '100% !important',
                },
            },
        },
        single: {
            '&.swiper-slide-active': {
                width: '100% !important',
            },
        },
        cardWrapper: {
            width: `calc(${(isWindow ? window.screen.width : 1920) } * 0.8px)`,
            height: '80%',
            borderRadius: '10px',
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'row',
            margin: '0 auto'
        },
        cardLeft: {
            width: '40%',
            height: (isWindow ? window.screen.width : 968)  * (70 / 375),
            '&.disableHeight': {
                height: 'auto',
            },
        },
        name: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        cardRight: {
            width: '60%',
            padding: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        cardImage: {
            width: '100%',
            height: '100%',
            borderBottomLeftRadius: '10px',
            borderTopLeftRadius: '10px',
        },
        cardTitle: {
            display: 'flex',
            alignItems: 'flex-end',
        },
        projectName: {
            marginLeft: theme.spacing(3),
            marginTop: theme.spacing(3),
            color: 'white',
        },
        btnSelectFloor: {
            marginTop: 34,
            background: "#FF8133",
            width: '60%',
            borderRadius: 16,
            borderColor: 'transparent',
            padding: "10px 36px",
            '& .MuiButton-label': {
                color: "#ffffff"
            },
        },
        floorSidebar: {
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 3
        },
    }
})
