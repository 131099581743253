/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useState} from 'react'
import {Grid, Box, Typography, TextField} from '@material-ui/core'
import {footerStyles, headerStyles} from './ViewApartmentFragmentStyles'
import {Autocomplete} from '@material-ui/lab'
import Logo from '../../../../assets/images/Artboard_40_4x.png'
import useWindowSize from '../../../../utils/useWindowSize'
import {SharedBox} from '../../../../components/Sidebar/ApartmentSidebarFragments'
import IconBack from "../../../../assets/images/v2/icon_back.svg";
import {useHistory} from "react-router-dom";
import {isWindow, substringCustom} from "../../../../utils/help";

export const ApartmentHeader = (props) => {
    const {
        listApartment,
        apartmentCurrent,
        setApartmentCurrent,
        buildingName,
        projectName,
        floorName,
        floorSlug,
        projectSlug,
        buildingSlug,
    } = props
    const history = useHistory()
    const MAX_LENGTH = 10
    const [selected, setSelected] = useState([])
    const classes = headerStyles()
    const windowSize = useWindowSize()
    const [windowSizeSt, setWindowSizeSt] = useState(0)
    const [listApartmentSort, setListApartmentSort] = useState([])

    useEffect(() => {
        setWindowSizeSt(windowSize.width)
    }, [windowSize.width])
    useEffect(() => {
        if (apartmentCurrent) {
            setSelected(apartmentCurrent)
        }
    }, [apartmentCurrent])

    useEffect(() => {
        if (listApartment.length > 1) {
            let arr = listApartment.sort((a, b) =>
                a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
            )
            setListApartmentSort(arr)
        } else {
            if (listApartment.length > 0) {
                setListApartmentSort(listApartment)
            }
        }
    }, [listApartment])

    return (
        <Grid container alignItems='center' className={classes.container}>
            <Grid item sm={12}>
                <Box className={classes.item1}>
                    <img
                        onClick={() => {
                            history.push(`/view/${props.projectSlug}/${props.buildingSlug}/${props.floorSlug}`)
                        }}
                        src={IconBack}
                        className={classes.IconBack}
                        alt=''
                    />
                    <Typography
                        component='span'
                        className={classes.title}
                        style={windowSizeSt >= 426 ? {} : {width: '75px'}}
                    >
                        Căn hộ
                    </Typography>
                    <Autocomplete
                        className={classes.button}
                        value={selected}
                        onChange={(e, value) => {
                            if (value?.slug) {
                                setApartmentCurrent(value)
                                history.push(`/view/${projectSlug}/${buildingSlug}/${floorSlug}/${value?.slug}`)
                            }
                        }}
                        options={listApartmentSort}
                        renderInput={(params) => (
                            <TextField
                                variant="outlined"
                                style={{padding: '3px 0 0px !important'}}
                                {...params}
                            />
                        )}
                        getOptionLabel={(option) => option.name || ''}
                        size='small'
                        closeIcon=''
                        autoHighlight
                    />

                </Box>
                <Box className={classes.infoHeader}>
                    <Typography
                        component='span'>Tầng {substringCustom(floorName, MAX_LENGTH)} |Tòa {substringCustom(buildingName, MAX_LENGTH)} |Dự
                        án {substringCustom(projectName, MAX_LENGTH)} </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}
export const ApartmentFooter = (props) => {
    const {apartmentCurrent, statusLogo} = props
    const classes = footerStyles()
    const windowSize = useWindowSize()
    const [windowSizeSt, setWindowSizeSt] = useState(0)
    const [ratio, setRatio] = useState(isWindow ? window.innerWidth / window.innerHeight : 1920 / 968)
    const [data, setData] = useState({
        size: 0,
        apartmentType: '',
        numDesign: 0,
    })
    useEffect(() => {
        setWindowSizeSt(windowSize.width)
    }, [windowSize.width])
    useEffect(() => {
        setRatio(isWindow ? window.innerWidth / window.innerHeight : 1920 / 968)
    }, [isWindow && window?.innerWidth, isWindow && window?.innerHeight])

    useEffect(() => {
        if (apartmentCurrent?.acreage) {
            setData({
                size: apartmentCurrent?.acreage ? apartmentCurrent?.acreage : 0,
                apartmentType: apartmentCurrent?.layoutsType?.name
                    ? apartmentCurrent?.layoutsType?.name
                    : '',
                numDesign: apartmentCurrent?.numDesign ? apartmentCurrent?.numDesign : 0,
            })
        }
    }, [apartmentCurrent])

    return (
        <Box
            style={
                ratio > 1.6
                    ? {padding: '2.5% 0', height: '10%'}
                    : {padding: '2.5% 0'}
            }
        >
            <Grid
                container
                justifyContent='space-between'
                alignItems='center'
                className={`${ratio < 1.6 && classes.gridContainer}`}
            >
                <Grid item sm={windowSizeSt > 1023 ? 6 : windowSizeSt > 700 ? 6 : 12}>
                    <Box className={classes.blockLeft}>
                        {statusLogo ? (
                            <Box className={classes.blockLeft}>
                                <Box>
                                    <img className={classes.image} src={Logo} alt=''/>
                                </Box>
                                <SharedBox/>
                            </Box>
                        ) : statusLogo === false ? (
                            <></>
                        ) : (
                            <Box className={classes.blockLeft}>
                                <Box>
                                    <img className={classes.image} src={Logo} alt=''/>
                                </Box>
                                <SharedBox/>
                            </Box>
                        )}
                    </Box>
                </Grid>
                {windowSizeSt >= 700 && (
                    <Grid item sm={6}>
                        <Box className={classes.blockRight}>
                            <Typography component={'div'} className={classes.text}>
                                {/* Diện tích : {apartmentCurrent?.size}m2 */}
                                Diện tích : {data.size}m2
                                <span style={{color: '#9a9595', paddingLeft: '5px'}}> |</span>
                            </Typography>
                            <Typography component={'div'} className={classes.text}>
                                {/* {apartmentCurrent?.apartmentType} */}
                                {data.apartmentType}
                                <span style={{color: '#9a9595', paddingLeft: '5px'}}> |</span>
                            </Typography>
                            <Typography component={'div'} className={classes.text}>
                                {data.numDesign} Thiết kế
                            </Typography>
                            {/* <Typography component={'div'} className={classes.text}>
                1 KTS
              </Typography> */}
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}
