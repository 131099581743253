/** @format */

import React, {useEffect, useState} from 'react'
import {Box, makeStyles, IconButton, Button, Grid, ButtonBase} from '@material-ui/core'
import {
    //   NavigateNext as Next,
    //   NavigateBefore as Prev,
    ThreeDRotation,
    Fullscreen,
    Clear,
} from '@material-ui/icons'
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y} from 'swiper'

import {DOMAIN_IMAGE} from '../../../../redux/constants/actionType'
import {isNotEmptyObject, isWindow} from "../../../../utils/help";
import ImageNotAvailable from "../../../../assets/images/v2/image-not-avalible.png";
import VRIcon from "../../../../assets/images/v2/VR-View.svg";


SwiperCore.use([Navigation, Pagination])

const ViewApartmentMobilePreview = (props) => {
    const {apartmentCurrent, portfolioCurrent} = props
    const classes = useStyles()
    const [openVR, setOpenVR] = useState(false)
    const [srcImage, setSrcImage] = useState({})
    const [slide, setSlide] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        setCurrentIndex(0)
        if (isNotEmptyObject(portfolioCurrent) && portfolioCurrent?.gallery_images) {
            if (portfolioCurrent.vr_url) {
                const itemVR = {vr_url: portfolioCurrent?.vr_url, url: portfolioCurrent?.featured_image?.url}
                setSrcImage(itemVR)
                setSlide([itemVR].concat(portfolioCurrent.gallery_images))
            } else {
                setSrcImage(portfolioCurrent?.gallery_images[0])
                setSlide(portfolioCurrent?.gallery_images)
            }
        }
    }, [portfolioCurrent])

    return (
        <Box className={classes.container}>
            <Box className={classes.preview}>
                <div className={`${classes.previewIframe} ${srcImage?.vr_url ? classes.show : classes.hide}`}>
                    <IconButton className={classes.btnView} onClick={() => setOpenVR(true)}>
                        <Fullscreen className={classes.fullscreenIcon}/>
                    </IconButton>
                    <iframe
                        className={classes.VRPreview}
                        src={portfolioCurrent?.vr_url}
                        title='1'
                        allowFullScreen
                        frameBorder='0'
                    />
                </div>
                <Box
                    className={`${classes.imagePreview} ${srcImage?.vr_url ? classes.hide : classes.show}`}
                    style={{backgroundImage: `url(${DOMAIN_IMAGE + srcImage?.url})`}}
                />
            </Box>
            <Box className={classes.designGallerySlider}>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={8}
                    slidesPerView={2.2}
                    // navigation={{
                    //     nextEl: '.swiper-button-next',
                    //     prevEl: '.swiper-button-prev',
                    // }}

                    style={{
                        height: (isWindow ? window.innerHeight : 968) * (160 / 1050),
                        marginTop: (isWindow ? window.innerHeight : 968)* (1 / 1050),
                        // maxWidth: '90%'
                    }}
                >
                    {slide &&
                        slide.map((item, index) => (
                            <SwiperSlide key={`portfolio_${index}`}>
                                <Box
                                    onClick={() => {
                                        setCurrentIndex(index)
                                        setSrcImage(item)
                                    }}
                                    className={``}
                                >
                                    <Box
                                    >
                                        <Grid
                                            container
                                            className={`${classes.sliderItem} ${
                                                currentIndex === index && classes.active
                                            }`}
                                            component={ButtonBase}
                                        >
                                            {item.vr_url ? (
                                                <Grid item xs={12}>
                                                    <Box
                                                        className={`${classes.VRItem} ${
                                                            currentIndex === index ? classes.VRActive : classes.VRImage
                                                        }`}
                                                        style={{
                                                            backgroundImage: `url(${item?.url ? DOMAIN_IMAGE + item?.url : ImageNotAvailable})`,
                                                        }}
                                                    />
                                                </Grid>
                                            ) : (
                                                <Grid item xs={12}>
                                                    <Box
                                                        className={`${
                                                            currentIndex === index
                                                                ? classes.sliderImageActive
                                                                : classes.sliderImage
                                                        }`}
                                                        style={{backgroundImage: `url(${item?.url ? DOMAIN_IMAGE + item?.url : ImageNotAvailable})`}}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                </Box>
                            </SwiperSlide>
                        ))}
                </Swiper>
                <div className={`${openVR ? classes.show : classes.hide}`}>
                    <ViewVR apartmentCurrent={apartmentCurrent} portfolioCurrent={portfolioCurrent}/>
                    <IconButton className={classes.clearButton} onClick={() => setOpenVR(false)}>
                        <Clear className={classes.clearIcon}/>
                    </IconButton>
                </div>
            </Box>
        </Box>
    )
}

export default ViewApartmentMobilePreview

const useStyles = makeStyles((theme) => {
    return {
        active: {},
        VRItem: {
            '&::after': {
                top: '18%',
                left: '15%',
                color: 'white',
                width: 40,
                height: 40,
                // fontWeight: 800,
                textAlign: 'center',
                content: '""',
                // fontStyle: 'italic',
                zIndex: 10,
                position: 'absolute',
                //   fontSize: '11px',
                backgroundImage: `url(${VRIcon})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain"
                // fontSize: '15px',
                // borderRadius: '5px',
                // paddingTop: '1px',
            },
        },
        VRActive: {
            height: (isWindow ? window.innerHeight : 968) * (136 / 1050),
            boxShadow: theme.shadows[3],
            borderRadius: 5,
            paddingBottom: 5,
            overflow: 'hidden',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            // '&::before': {
            //     content: '"Đang xem"',
            //     top: '64%',
            //     left: '8px',
            //     width: ' calc(100% - 16px)',
            //     height: '30%',
            //     zIndex: 10,
            //     position: 'absolute',
            //     backgroundColor: '#272322b3',
            //     borderBottomLeftRadius: '5px',
            //     borderBottomRightRadius: '5px',
            //     color: 'white',
            //     textAlign: 'center',
            //     fontSize: '11px',
            //     paddingTop: '10%',
            //     lineHeight: '10%',
            // },
            // '&::after': {
            //     top: '18%',
            //     right: '15%',
            //     color: 'white',
            //     width: '20%',
            //     height: '20%',
            //     fontWeight: 800,
            //     textAlign: 'center',
            //     content: '"VR"',
            //     fontStyle: 'italic',
            //     zIndex: 10,
            //     position: 'absolute',
            //     //   fontSize: '11px',
            //     backgroundColor: '#66615fb3',
            //     fontSize: '15px',
            //     borderRadius: '5px',
            //     paddingTop: '1px',
            // },
        },
        VRImage: {
            height: (isWindow ? window.innerHeight : 968) * (136 / 1050),
            boxShadow: theme.shadows[3],
            borderRadius: 5,
            paddingBottom: 5,
            overflow: 'hidden',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            // '&::after': {
            //     top: '18%',
            //     right: '15%',
            //     color: 'white',
            //     width: '20%',
            //     height: '20%',
            //     fontWeight: 800,
            //     textAlign: 'center',
            //     content: '"VR"',
            //     fontStyle: 'italic',
            //     zIndex: 10,
            //     position: 'absolute',
            //     // fontSize: '11px',
            //     backgroundColor: '#66615fb3',
            //     fontSize: '15px',
            //     paddingTop: '1px',
            //     borderRadius: '5px',
            // },
        },
        sliderItem: {
            width: "100%",
            height: "100%",
            // padding: '0 10px',
            borderRadius: 5,
            transition: `300ms`,
            overflow: 'hidden',
            "& $sliderImageActive , $VRActive": {
                height: (isWindow ? window.innerHeight : 968) * (136 / 1050),
                borderRadius: 10,
                paddingBottom: 5,
                overflow: 'hidden',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                border: "3px solid #006CD9",
                boxSizing: "border - box",
                // '&::before': {
                //     content: '"Đang xem"',
                //     top: '64%',
                //     left: '8px',
                //     width: ' calc(100% - 16px)',
                //     height: '30%',
                //     zIndex: 10,
                //     position: 'absolute',
                //     backgroundColor: '#272322b3',
                //     borderBottomLeftRadius: '5px',
                //     borderBottomRightRadius: '5px',
                //     color: 'white',
                //     textAlign: 'center',
                //     fontSize: '11px',
                //     paddingTop: '10%',
                //     lineHeight: '10%',
                // },
            },
        },
        sliderImage: {
            height: (isWindow ? window.innerHeight : 968) * (136 / 1050),
            width: "100%",
            boxShadow: theme.shadows[3],
            borderRadius: 10,
            paddingBottom: 5,
            overflow: 'hidden',
            backgroundPosition: 'center',
            backgroundSize: 'cover',

        },
        sliderImageActive: {},
        container: {
            padding: '20px 0',
            position: 'relative',
            paddingBottom: 0
        },
        preview: {
            width: '100vw',
            height: '30vh',

        },
        previewIframe:{
            position: 'relative',
            width: '100%',
            height: '100%',
        },
        designGallerySlider: {
            margin: "16px 0px 16px 20px",
            marginBottom: 0

        },
        imagePreview: {
            width: "100%",
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            objectFit: 'cover',
        },
        show: {
            display: "inherit"
        },
        hide: {
            display: 'none'
        },
        boxImage: {
            width: '100vw',
            height: '30vh',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        },
        VRPreview: {
            width: '100%',
            height: '100%',
            // backgroundPosition: 'center',
            // backgroundSize: 'cover',
            marginBottom: -5,
            zIndex: 0,
            position: 'relative',
        },
        btnView: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            backgroundColor: 'transparent',
            zIndex: 1
        },
        fullscreenIcon: {
            color: '#FFFFFF',
            fontSize: "1.8rem"
        },
        image: {
            width: '100%',
            height: '30vh',
            objectFit: 'cover',
        },
        groupButton: {
            display: 'flex',
            flexDirection: 'column',
        },
        swiperButtonNext: {
            color: '#000',
        },
        clearButton: {
            position: 'fixed',
            top: 0,
            right: 10,
            zIndex: 4000,
        },
        clearIcon: {
            color: '#fff',
            fontSize: 24,
        },
    }
})

const ViewVR = (props) => {
    const {portfolioCurrent} = props
    return (
        <Box
            // open={open}
            // onClose={close}
            style={{
                width: '100%',
                height: '100%',
                position: 'fixed',
                zIndex: 3000,
                top: 0,
                left: 0,
            }}
        >
            <iframe
                src={portfolioCurrent?.vr_url}
                title='1'
                allowFullScreen
                frameBorder='0'
                width='100%'
                height='100%'
            />
        </Box>
    )
}
