/** @format */

import {makeStyles} from '@material-ui/core'
import {isWindow} from "../../utils/help";

export default makeStyles((theme) => ({
    projectSidebar: {
        backgroundColor: '#fafafa',
        // width: '100%',
        width: (isWindow ? window.screen.width : 1920)  * (496 / 1920),
        height: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        zIndex: 10,
        boxShadow: theme.shadows[10],
        '&::-webkit-scrollbar': {
            width: `2px`,
        },

    },

    buildingTabs: {
        backgroundColor: "#F2F2F2",
        padding: "44px 32px",
        paddingTop: 42,
        // marginTop: theme.spacing(2),
        // marginBottom: theme.spacing(2),
        [theme.breakpoints.between('1249', '1251')]: {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    tabHeader: {
        fontSize: theme.fontSizing.f16,
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-around",
        marginBottom: 44,
        "& .MuiAutocomplete-inputRoot": {
            padding: "10px 20px 10px 20px",
            "& .MuiAutocomplete-input": {
                fontSize: 16,
                fontWeight: 600,
                padding: "0px  !important"
            }
        },
    },
    buildingName: {
        color: theme.palette.secondary.main,
        fontSize: '16px',
        '&.active': {
            fontWeight: 'bold',
            color: theme.palette.secondary.main,
        },
    },
    scrollable: {
        display: 'flex',
        width: '100%',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            height: 0,
        },
    },
    tabHeaderButton: {
        padding: theme.spacing(1),
        whiteSpace: 'nowrap',
        '&.active': {
            fontWeight: 'bold',
            color: theme.palette.secondary.main,
        },
    },
    tabFigure: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}))

export const useStylesProjectInfo = makeStyles((theme) => {
    return {
        projectName: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 32,
            paddingBottom: 24,
            fontWeight: 'bold',
            letterSpacing: '0.3px',
            fontSize: 33,
            [theme.breakpoints.down('sm')]: {
                fontSize: 24,
                fontWeight: 700,
                color: "#ffffff",
                textTransform: "uppercase"
            },
        },
        info: {
            cursor: 'pointer'
        },
        shadowText: {
            // textShadow: '1px 2px 3px #000'
        },
        popoverInfoProject: {
            fontSize: theme.fontSizing.f14,
            paddingBottom: theme.spacing(1),
            letterSpacing: '0.3px',
            '& span': {
                fontSize: theme.fontSizing.f12,
                color: "#F37021",
                fontWeight: 'bold',
            },
        },
        mouseOverPopoverInfo: {
            pointerEvents: 'none',
            '& .MuiPopover-paper': {
                background: "#FDFDFD !important",
                border: " 1px solid #C1C1C1 !important",
                boxSizing: "border-box !important",
                borderRadius: "10px !important",
                padding: "16px 22px !important",
                maxWidth: "calc(35% - 32px) !important",
                // left: "calc(60% - 32px) !important"
                [theme.breakpoints.down('sm')]: {
                    maxWidth: "max-content !important",
                    left: "auto !important"
                }
            }
        }

    }
})

export const useStylesSharedBox = makeStyles((theme) => ({
    root: {
        marginTop: 32,
        marginBttom: 20,
        fontSize: theme.fontSizing.f16,

        // display: 'flex',
        // justifyContent: 'space-between',
        // alignItems: 'center',
        // [theme.breakpoints.down('1249')]: {
        //     // marginBottom: theme.spacing(7),
        //     flexDirection: 'column',
        // },
    },
    socialShareBox: {
        backgroundColor: 'white !important',
        boxShadow: '2px 2px 5px #a2a2a2 !important',
    },
    shareButton: {
        display: 'flex !important',
        padding: '10px !important',
        alignItems: 'center !important',
        justifyContent: 'space-between !important',
        textDecoration: "unset !important",
        cursor: "pointer !important",
        '&:hover': {
            textDecoration: "underline !important"
        }
    },
    shareButtonCopy: {
        display: 'flex !important',
        padding: '10px !important',
        alignItems: 'center !important',
        justifyContent: 'start !important',
        textDecoration: "unset !important",
        cursor: "pointer !important",
        '&:hover': {
            textDecoration: "underline !important"
        }
    },
    popoverShare: {
        "& .MuiPopover-paper": {
            // top: "calc(78% - 32px) !important",
            background: "#F5F9FF !important",
            border: "1px solid #C1C1C1 !important",
            boxSizing: "border-box !important",
            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2) !important",
            borderRadius: "10px !important",
        }
    },
    footerTop: {
        display: "flex",
        justifyContent: "center",
        // display: 'flex',
        // justifyContent: 'space-between',
        // alignItems: 'center',
        // [theme.breakpoints.down('1249')]: {
        //     // marginBottom: theme.spacing(7),
        //     flexDirection: 'column',
        // },
    },
    footerLogo: {
        marginTop: 35,
        marginBottom: 25,
        textAlign: "center"
    },
    phone: {
        padding: "8px 16px",
        background: "#006CD9",
        color: "#FFFFFF",
        borderRadius: 20,
        textTransform: "uppercase",
        marginLeft: 6,
        "& span": {
            color: "#FFFFFF",
            textDecoration: "none",
            fontWeight: 700,
        },
        '&:hover': {
            background: "#4c92d9d9",

        }

        // textDecoration: 'none',
        // color: theme.palette.secondary.dark,
        // display: 'flex',
    },
    link: {
        padding: "8px 16px",
        color: "#6A6A6A",
        background: "#FDFDFD",
        textTransform: "uppercase",
        boxSizing: "border-box",
        border: "1px solid #C1C1C1",
        borderRadius: 20,
        marginRight: 6,
        fontWeight: 700,
    },
    linkIcon: {
        fontSize: (isWindow ? window.innerWidth : 1920) * (14 / 1920),

        [theme.breakpoints.down('768')]: {
            fontSize: (isWindow ? window.innerWidth : 1920) * (12 / 768),
        },
        [theme.breakpoints.down('400')]: {
            fontSize: (isWindow ? window.innerWidth : 1920) * (12 / 375),
        },
        // fontSize: '15px',
        margin: '5px',
    },
}))
export const useStylesSharedBox2 = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 2,
        width: (isWindow ? window.innerWidth : 1920) * 200 / 1920,
        margin: "auto",
        '&$matchPC': {
            position: "absolute",
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            right: 20,
            left: 'auto',
            bottom: 'auto',
            top:isWindow ? window.innerHeight * 415 / 884 :968 * 415 / 884,
            '&$rotApartment': {
                top: 'unset',
                zIndex: 1,
                bottom: 0,
            },
            '&$unFloorSlug': {
                top: 'unset',
                zIndex: 4,
                bottom: 0,
            }
        },
    },
    socialShareBox: {
        backgroundColor: 'white !important',
        boxShadow: '2px 2px 5px #a2a2a2 !important',
    },
    shareButton: {
        display: 'flex !important',
        padding: '10px !important',
        alignItems: 'center !important',
        justifyContent: 'space-between !important',
        textDecoration: "unset !important",
        cursor: "pointer !important",
        '&:hover': {
            textDecoration: "underline !important"
        }
    },
    shareButtonCopy: {
        display: 'flex !important',
        padding: '10px !important',
        alignItems: 'center !important',
        justifyContent: 'start !important',
        textDecoration: "unset !important",
        cursor: "pointer !important",
        '&:hover': {
            textDecoration: "underline !important"
        }
    },
    matchPC: {},
    rotApartment: {},
    unFloorSlug: {},
    info: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },

    },
    style: {
        padding: "4px 8px",
        background: "#E6E6E6",
        borderRadius: 20,
        color: "#6A6A6A",
        fontWeight: 600,
        fontSize: 10,
        marginRight: 8,
        marginBottom:5
    },
    title: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 16,
        flexWrap: 'wrap',
        "& div:first-child": {
            width:"30%",
            color: "#00030E",
            fontWeight: 400,
            fontSize: 16
        }
    },
    inspirations: {
        display: "flex",
        justifyContent: "start",
        flexWrap: 'wrap',
        width: "70%"
    },
    textRating: {
        fontSize: 16,
        padding: '1px 0',
        lineHeight: 1,
    },
    popoverShare: {
        "& .MuiPopover-paper": {
            // top: "calc(78% - 32px) !important",
            background: "#F5F9FF !important",
            border: "1px solid #C1C1C1 !important",
            boxSizing: "border-box !important",
            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2) !important",
            borderRadius: "10px !important",
        }
    },

    phone: {
        padding: "8px 16px",
        background: "#A8A8A8",
        borderRadius: 20,
        textTransform: "uppercase",
        marginLeft: 6,
        "& a": {
            color: "#FFFFFF",
            textDecoration: "none",
            fontWeight: 700,

        },

        // textDecoration: 'none',
        // color: theme.palette.secondary.dark,
        // display: 'flex',
    },
    close: {
        cursor: "pointer",
        width: 25,
        height: 25,
        position: "absolute",
        right: "-5px",
        top: "-10px",

    },
    linkIcon: {
        fontSize: (isWindow ? window.innerWidth : 1920) * (14 / 1920),

        [theme.breakpoints.down('768')]: {
            fontSize: (isWindow ? window.innerWidth : 1920) * (12 / 768),
        },
        [theme.breakpoints.down('400')]: {
            fontSize: (isWindow ? window.innerWidth : 1920) * (12 / 375),
        },
        // fontSize: '15px',
        margin: '5px',
    },
}))
