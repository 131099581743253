/** @format */

import React from 'react';
import { Switch, Route, Redirect} from 'react-router-dom';
import NotFound from './NotFound';
import ViewBase from "../containers/View/ViewBase";
export default function Router() {
    return (
        <Switch>
            <Route
                path={
                    '/view/:projectSlug?/:buildingSlug?/:floorSlug?/:apartmentSlug?/:portfolioSlug?'
                }
                component={ViewBase}
            />
            <Route path={'/notFound'} component={NotFound}/>
            <Redirect path={'*'} to={'/notFound'}/>
        </Switch>
    );
}
