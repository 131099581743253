/** @format */

import * as actionType from '../constants/actionType'

const DEFAULT_STATE = {
  listApartmentLayout: [],
  listBuildings: [],
  listProjects: [],
  listApartmenTypes: [],
  listLayoutDrawings: [],
  dataSearchApartment: [],
  listPortfolios: [],
  listSearchProjects: [],
  listInvestors: [],
  listProvinces: [],
  listDistricts: [],
  listWards: [],
  isFetching: false,
  dataFetched: false,
  error: false,
  message: '',
  isFetchingBuildingFilter: false,
  isFetchingProjectFilter: false,
  isFetchingApartmenTypesFilter: false,
  isFetchingInvestors: false,
  isFetchingProvinces: false,
  isFetchingDistricts: false,
  isFetchingWards: false,
  isLayoutDrawings: false,
  isPortfolios: false,
  projectSearch: '',
  apartmentTypeSearch: '',
  textSearchApartment: '',
}

const filterReducer = (state = DEFAULT_STATE, action) => {
  let { type } = action
  switch (type) {
    case actionType.SEARCH_LAYOUTDRAWING_FORM.REQUEST:
      return {
        ...state,
        // isFetching: true,
        error: false,
        dataFetched: false,
        message: null,
        isLayoutDrawings: true,
      }

    case actionType.SEARCH_FILTER_APARTMENTLAYOUT.REQUEST:
    case actionType.SEARCH_PROJECT_BY_FILTER.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        dataFetched: false,
        message: null,
      }
    case actionType.GET_FILTER_DISTRICT_LIST.REQUEST:
    case actionType.GET_DISTRICT_LIST.REQUEST:
      return {
        ...state,
        error: false,
        dataFetched: false,
        message: null,
        isFetchingDistricts: true,
      }
    case actionType.GET_FILTER_WARD_LIST.REQUEST:
    case actionType.GET_WARD_LIST.REQUEST:
      return {
        ...state,
        error: false,
        dataFetched: false,
        message: null,
        isFetchingWards: true,
      }
    case actionType.GET_FILTER_INVESTOR_LIST.REQUEST:
      return {
        ...state,
        error: false,
        dataFetched: false,
        message: null,
        isFetchingInvestors: true,
      }
    case actionType.GET_FILTER_PROVINCE_LIST.REQUEST:
    case actionType.GET_PROVINCE_LIST.REQUEST:
      return {
        ...state,
        error: false,
        dataFetched: false,
        message: null,
        isFetchingProvinces: true,
      }
    case actionType.GET_PORTFOLIOS.REQUEST:
      return {
        ...state,
        error: false,
        dataFetched: false,
        message: null,
        isPortfolios: true,
      }
    case actionType.SEARCH_APARTMENT_TYPE.REQUEST:
      return {
        ...state,
        isFetchingApartmenTypesFilter: true,
        error: false,
        dataFetched: false,
        message: null,
      }
    case actionType.BUILDING_FILTER.REQUEST:
    case actionType.SEARCH_BUILDING_LIST.REQUEST:
      return {
        ...state,
        error: false,
        dataFetched: false,
        message: null,
        isFetchingBuildingFilter: true,
        listBuildings: [],
        buildingSearch: '',
      }
    case actionType.SEARCH_PROJECT_LIST.REQUEST:
      return {
        ...state,
        error: false,
        dataFetched: false,
        message: null,
        isFetchingProjectFilter: true,
        listProjects: [],
      }

    case actionType.SEARCH_PROJECT_LIST.SUCCESS:
      return {
        ...state,
        // isFetching: false,
        isFetchingProjectFilter: false,
        error: false,
        message: null,
        listProjects: action.payload.listProjects,
      }
    case actionType.BUILDING_FILTER.SUCCESS:
      return {
        ...state,
        isFetchingBuildingFilter: false,
        error: false,
        message: '',
      }
    case actionType.SEARCH_BUILDING_LIST.SUCCESS:
      return {
        ...state,
        // isFetching: false,
        isFetchingBuildingFilter: false,
        error: false,
        message: null,
        listBuildings: action.payload.listbuildings,
      }
    case actionType.SEARCH_APARTMENT_TYPE.SUCCESS:
      return {
        ...state,
        isFetchingApartmenTypesFilter: false,
        error: false,
        message: null,
        listApartmenTypes: action.payload.listApartmenType,
      }
    case actionType.SEARCH_LAYOUTDRAWING_FORM.SUCCESS:
      return {
        ...state,
        error: false,
        message: null,
        listLayoutDrawings: action.payload.listLayoutDrawings,
        isLayoutDrawings: false,
      }
    case actionType.SEARCH_FILTER_APARTMENTLAYOUT.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: null,
        // listApartmentLayout: action.payload.listApartmentLayout,
        // totalPage:action.payload.totalPage,
        // textSearchApartment:action.payload.textSearchApartment
      }
    case actionType.GET_FILTER_INVESTOR_LIST.SUCCESS:
      return {
        ...state,
        // isFetching: false,
        error: false,
        message: '',
        dataFetched: true,
        listInvestors: action.payload.listInvestors,
        isFetchingInvestors: false,
      }
    case actionType.GET_FILTER_PROVINCE_LIST.SUCCESS:
    case actionType.GET_PROVINCE_LIST.SUCCESS:
      return {
        ...state,
        // isFetching: false,
        error: false,
        message: '',
        listProvinces: action.payload.listProvinces,
        isFetchingProvinces: false,
      }
    case actionType.GET_FILTER_DISTRICT_LIST.SUCCESS:
    case actionType.GET_DISTRICT_LIST.SUCCESS:
      return {
        ...state,
        // isFetching: false,
        error: false,
        message: '',
        listDistricts: action.payload.listDistricts,
        isFetchingDistricts: false,
      }
    case actionType.GET_FILTER_WARD_LIST.SUCCESS:
    case actionType.GET_WARD_LIST.SUCCESS:
      return {
        ...state,
        // isFetching: false,
        error: false,
        message: '',
        listWards: action.payload.listWards,
        isFetchingWards: false,
      }
    case actionType.SEARCH_PROJECT_BY_FILTER.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: '',
      }
    case actionType.GET_PORTFOLIOS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: '',
        listPortfolios: action.payload.listPortfoliosData,
        isPortfolios: false,
      }

    // case actionType.SEARCH_BUILDING_LIST.FAILURE:
    case actionType.SEARCH_LAYOUTDRAWING_FORM.FAILURE:
    case actionType.SEARCH_FILTER_APARTMENTLAYOUT.FAILURE:
    case actionType.GET_FILTER_INVESTOR_LIST.FAILURE:
    case actionType.GET_FILTER_PROVINCE_LIST.FAILURE:
    case actionType.GET_FILTER_DISTRICT_LIST.FAILURE:
    case actionType.SEARCH_PROJECT_BY_FILTER.FAILURE:
    case actionType.GET_PORTFOLIOS.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        message: action.payload.message,
      }
    case actionType.GET_FILTER_WARD_LIST.FAILURE:
      return {
        ...state,
        isFetchingWards: false,
        error: true,
        message: action.payload.message,
      }
    case actionType.SEARCH_APARTMENT_TYPE.FAILURE:
      return {
        ...state,
        isFetchingApartmenTypesFilter: false,
        error: true,
        message: action.payload.message,
      }
    case actionType.SEARCH_BUILDING_LIST.FAILURE:
    case actionType.BUILDING_FILTER.FAILURE:
      return {
        ...state,
        isFetchingBuildingFilter: false,
        error: true,
        message: action.payload.message,
      }
    case actionType.SEARCH_PROJECT_LIST.FAILURE:
      return {
        ...state,
        isFetchingProjectFilter: false,
        error: true,
        message: action.payload.message,
      }
    case 'RESET_PROJECT_STATE':
      return DEFAULT_STATE
    default:
      return state
  }
}

export default filterReducer
