/** @format */

import React, {useState} from 'react'
import {Box, makeStyles, ButtonBase, Typography} from '@material-ui/core'
// import { NavigateNext as Next, NavigateBefore as Prev } from '@material-ui/icons';
// import {Rating} from '@material-ui/lab'
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y} from 'swiper'
// import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper.min.css'
import {DOMAIN_IMAGE} from '../../../../redux/constants/actionType'
import CloseIcon from "@material-ui/icons/Close";
import {SliderItem} from "../../../../components/Slider/DesignSliderFragments";
import {Skeleton} from "antd";
import {isWindow} from "../../../../utils/help";

SwiperCore.use([Navigation, Pagination])
const ViewApartmentMobileDesigner = (props) => {
    const {
        portfolios,
        portfolioCurrent,
        setPortfolioCurrent,
        apartmentCurrent,
        currentIndex,
        setCurrentIndex,
        isShow, setIsShow
    } = props
    const classes = useStyles()
    const [ratio] = useState(isWindow ? window.innerWidth / window.innerHeight : 1920/968)

    return (
        <Box className={`${classes.container} ${isShow && classes.show}`}>
            <Box className={classes.listPortfolioContent}>
                <CloseIcon className={classes.close} onClick={() => setIsShow(false)}/>
                <div className={classes.title}>Danh sách thiết kế mẫu <br/>
                    ({portfolios.length} thiết kế)
                </div>
                <Box className={`${classes.bodyPortfolios}`}>
                    {
                        portfolios?.map((item, index) => (
                            <Box
                                className={classes.itemPortfolio}
                                style={{
                                    height: ratio > 1
                                        ? "unset"
                                        : (isWindow ? window.innerHeight : 968) * 0.22
                                }}
                                onClick={() => {
                                    setCurrentIndex(index);
                                    setPortfolioCurrent(item);
                                }}
                            >
                                <SliderItem
                                    setIsShow={setIsShow}
                                    index={index}
                                    ratio={ratio}
                                    currentIndex={currentIndex}
                                    item={item}
                                />
                            </Box>
                        ))
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default ViewApartmentMobileDesigner

// const buttonSlider = makeStyles((theme) => {
//     return ({
//         container: {
//             width: '20px',
//             height: '20px',
//             backgroundColor: '#f1f1f1',
//             borderRadius: '50%',

//         }, icon: {
//             fontSize: '14px'
//         }
//     })
// })

const useStyles = makeStyles((theme) => {
    return {
        button: {
            fontSize: 16,
            fontWeight: 600,
            textDecoration: 'underline',
            color: '#006CD9',
            textAlign: "right"
        },
        show: {},
        bodyPortfolios: {
            height: "100%",
            overflowY: 'scroll',
            overflow:'hidden',
            paddingBottom: 120
        },
        itemPortfolio: {
            marginBottom: 16,

        },
        swiperButtonPrev: {
            transform: "rotate(-90deg)",
            position: 'unset',
            margin: "auto"

        },
        swiperButtonNext: {
            position: 'unset',
            transform: "rotate(90deg)",
            margin: "auto"
        },

        listPortfolioContent: {
            width: "80%",
            height: "100vh",
            float: "right",
            backgroundColor: "#fff",
            padding: "20px 18px",
            position: 'relative',

        },
        sliderItemMobile: {
            width: "100%",
            height: (isWindow ? window.innerHeight : 968) * 0.25,
            borderRadius: 20,
        },
        activeMobile: {
            border: "3px solid #006CD9",
            boxSizing: "border-box",
        },
        close: {
            position: 'absolute',
            right: 20,
            top: 20,
            color: "#6A6A6A",
            border: "1px solid #C1C1C1",
            borderRadius: "50%",
            zIndex: 9999999

        },
        title: {
            fontWeight: 600,
            fontSize: 16,
            paddingTop: 56,
            textAlign: 'left',
            color: '#00030E',
            paddingBottom: 16,
            paddingLeft: 20
        },
        container: {
            top: 0,
            left: 0,
            position: "fixed",
            zIndex: 9999,
            transform: `translateX(100%)`,
            height: "100%",
            width: "100%",
            overflow:'hidden',
            transition: "0.4s",
            '&$show': {
                transform: `translateX(0)`,
            },
            backgroundColor: '#00030e87',

        },
        boxContent: {
            width: '80vw',
            height: '23vh',
            borderRadius: 10,
            // padding: '0 10px',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            // backgroundColor: '#f1f1f1',
        },
        image: {
            width: '100%',
            height: '23vh',
            borderRadius: 10,
            objectFit: 'cover',
        },
        SwiperSlideItem: {
            marginRight: '25 !important',
        },

        // info
        containerInfo: {
            width: '100%',
            height: '45%',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'absolute',
            left: 0,
            bottom: 5,
        },
        item: {
            height: '100%',
            width: '100%',
            backgroundColor: '#00000055',
            margin: '0 5px',
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
        },
        boxImg: {
            height: '8vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
        },
        designerImg: {
            height: '8vh',
            width: '8vh',
            borderRadius: '50%',
        },
        info: {
            flex: 3,
            textAlign: 'left',
        },
        text: {
            fontSize: '2vh',
            padding: '1px 0',
            lineHeight: 1,
            color: '#f1f1f1',
        },
        textRating: {
            fontSize: '2vh',
            padding: '1px 0',
            lineHeight: 1,
        },
    }
})
