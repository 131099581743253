/** @format */

const cssVariables = {
  fontSizing: {
    f10: '10px',
    f12: '12px',
    f14: '14px',
    f15: '15px',
    f16: '16px',
    f17: '17px',
  },
};

export default cssVariables;
