/** @format */

import {makeStyles} from '@material-ui/core'
import {isWindow} from "../../../../utils/help";

export default makeStyles((theme, props) => {
    let sidebarWidthMobile = 50
    return {
        bgOverlay: {
            width: "100%",
            height: "100%",
            backgroundColor: "#00030E",
            opacity: 0.5,
            position: "absolute",
            top: 0,
            left: 0,
        },
        viewProject: {
            backgroundColor: "#000000",
            width: '100%',
            // height: '100vh',
            display: 'flex',
            position: 'relative',
            overflowY: 'hidden',
        },
        sidebar: {
            position: 'fixed',
            zIndex: 10,
            width: (isWindow ? window.screen.width : 1920)  * (496 / 1920),
            transition: '500ms',
            transform: `translateX(-${(isWindow ? window.screen.width : 1920)  * (496 / 1920)}px)`,
            top: 0,
            left: 0,
            [theme.breakpoints.down('lg')]: {
                '& canvas': {
                    width: '92%'// width: '90%'
                }
            },
            '&.toggle': {
                transform: `translateX(0)`,
            },
            [theme.breakpoints.down('xs')]: {
                transform: `translateX(-350%)`,
            },
        },
        sidebarMobile: {
            position: 'fixed',
            zIndex: 10,
            width: `${sidebarWidthMobile}%`,
            transition: '300ms',
            transform: `translateX(-100%)`,
            top: 0,
            left: 0,
            '&.toggle': {
                transform: `translateX(0)`,
            },
            [theme.breakpoints.down('xs')]: {
                transform: `translateX(-350%)`,
            },
        },
        sidebarToggle: {
            position: 'absolute',
            backgroundColor: '#ebebeb9e',
            zIndex: 100,
            top: '48%',
            left: (isWindow ? window.screen.width : 1920)  * (496 / 1920),
            transform: `translateY(-50%)`,
            height: 96,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,

            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        toggleCompass:{

        },
        reverse: {
            transform: 'rotate(180deg)',
        },
        sidebarToggleMobile: {
            position: 'fixed',
            zIndex: 99,
            left: 20,
            top: 20,
            backgroundColor: theme.palette.secondary.dark,
            '&:focus': {
                backgroundColor: theme.palette.secondary.dark,
            },
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        toggleMobile: {
            backgroundColor: theme.palette.secondary.dark,
        },
        main: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100vh',
            position: 'relative',
            zIndex: 1,
            backgroundBlendMode: 'overlay',
            backgroundColor: 'rgba(0,0,0,0)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            left: (isWindow ? window.screen.width : 1920)  * (496 / 1920),
            width: `calc(100% - ${(isWindow ? window.screen.width : 1920)  * (496 / 1920)}px)`,
            overflow: 'hidden',

            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column !important',
                overflowY: 'hidden',
            },
            '&.expand': {
                left: 0,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
                [theme.breakpoints.down('md')]: {
                    '& canvas': {
                        maxWidth: 900
                    }
                },
                [theme.breakpoints.between('1249', '1251')]: {
                    alignItems: 'flex-start',
                    '& canvas': {
                        maxWidth: 850,
                    }
                },
                [theme.breakpoints.down('1248')]: {
                    alignItems: 'flex-start',
                    '& canvas': {
                        maxWidth: 700
                    }
                },
                [theme.breakpoints.down('1100')]: {
                    flexDirection: 'column !important',
                }
            },
        },
        mainMobile: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100vh',
            position: 'relative',
            zIndex: 1,
            backgroundBlendMode: 'overlay',
            backgroundColor: 'rgba(0,0,0,0)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            left: `${sidebarWidthMobile}%`,
            width: `calc(100% - ${sidebarWidthMobile}%)`,
            transition: '300ms',
            [theme.breakpoints.down('md')]: {
                width: '100%',
                left: 0,
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column !important',
            },
            '&.expand': {
                left: 0,
                width: '100%',
                flexDirection: 'row',
                [theme.breakpoints.down('md')]: {
                    flexDirection: 'column !important',
                },
            },
        },
        hidden: {
            display: 'none',
        },
    }
})
