/** @format */

import * as actionType from '../constants/actionType'
// import {GET_LIST_FLOOR_BY_BUILDING} from "../constants/actionType";

const createActionSet = (type) => {
    return {
        request: (payload) => ({type: `${type.REQUEST}`, payload}),
        success: (payload) => ({type: `${type.SUCCESS}`, payload}),
        failure: (payload) => ({type: `${type.FAILURE}`, payload}),
    }
}
export const login = createActionSet(actionType.LOGIN)
export const removeGroupFloor = createActionSet(actionType.REMOVE_GROUP_FLOOR)

export const getBuildingFilter = createActionSet(actionType.BUILDING_FILTER)
export const createBuilding = createActionSet(actionType.CREATE_BUILDING)
export const updateBuilding = createActionSet(actionType.UPDATE_BUILDING)
export const deleteBuilding = createActionSet(actionType.DELETE_BUILDING)
export const publishBuilding = createActionSet(actionType.PUBLISH_BUILDING)
export const unpublishBuilding = createActionSet(actionType.UNPUBLISH_BUILDING)

//All layout
export const getLayout = createActionSet(actionType.GET_LAYOUT)

//Floor Manager
export const getFloor = createActionSet(actionType.GET_FLOOR)
export const addFloor = createActionSet(actionType.ADD_FLOOR)
export const updateFloor = createActionSet(actionType.UPDATE_FLOOR)
export const deleteFloor = createActionSet(actionType.DELETE_FLOOR)

//Group
export const getGroup = createActionSet(actionType.GET_GROUP)
export const addGroup = createActionSet(actionType.ADD_GROUP)
export const updateGroup = createActionSet(actionType.UPDATE_GROUP)
export const updateGroupFloor = createActionSet(actionType.UPDATE_GROUP_FLOOR)
export const deleteGroup = createActionSet(actionType.DELETE_GROUP)

//Floor Link
export const getFloorLink = createActionSet(actionType.GET_FLOOR_LINK)
export const addFloorLink = createActionSet(actionType.ADD_FLOOR_LINK)
export const updateFloorLink = createActionSet(actionType.UPDATE_FLOOR_LINK)
export const deleteFloorLink = createActionSet(actionType.DELETE_FLOOR_LINK)

//Layout Link
export const getLayoutLink = createActionSet(actionType.GET_LAYOUT_LINK)
export const addLayoutLink = createActionSet(actionType.ADD_LAYOUT_LINK)
export const updateLayoutLink = createActionSet(actionType.UPDATE_LAYOUT_LINK)
export const deleteLayoutLink = createActionSet(actionType.DELETE_LAYOUT_LINK)
//project
export const getListProjects = createActionSet(actionType.GET_LIST_PROJECT)
export const createProjects = createActionSet(actionType.CREATE_PROJECT)
export const updateProject = createActionSet(actionType.UPDATE_PROJECT)
export const deleteProject = createActionSet(actionType.DELETE_PROJECT)
export const updateStatusProject = createActionSet(
    actionType.UPDATE_STATUS_PROJECT,
)
export const getProjectsDetail = createActionSet(actionType.GET_PROJECT_DETAIL)
//end project

//filter
export const getListInvestors = createActionSet(
    actionType.GET_FILTER_INVESTOR_LIST,
)
export const getListProvinces = createActionSet(
    actionType.GET_FILTER_PROVINCE_LIST,
)
export const getListDistricts = createActionSet(
    actionType.GET_FILTER_DISTRICT_LIST,
)

export const getListProvincesCMS = createActionSet(
    actionType.GET_PROVINCE_LIST,
);
export const getListDistrictsCMS = createActionSet(
    actionType.GET_DISTRICT_LIST,
);
export const getListWardCMS = createActionSet(
    actionType.GET_WARD_LIST
)

export const getListWard = createActionSet(actionType.GET_FILTER_WARD_LIST)
export const searchProjectByFilter = createActionSet(
    actionType.SEARCH_PROJECT_BY_FILTER,
)
//end filter minh làm

export const getBuilding = createActionSet(actionType.GET_BUILDING)
export const getDetailBuilding = createActionSet(actionType.GET_DETAIL_BUILDING)
//action apartmentLayout
export const getApartmentLayout = createActionSet(
    actionType.GET_APARTMENTLAYOUT,
)
export const getApartmentLayoutDetail = createActionSet(
    actionType.GET_APARTMENTDETAIL,
)
export const searchProjectFilter = createActionSet(
    actionType.SEARCH_PROJECT_LIST,
)
export const searchBuildingFilter = createActionSet(
    actionType.SEARCH_BUILDING_LIST,
)
export const searchApartmentFilter = createActionSet(
    actionType.SEARCH_APARTMENT_TYPE,
)
export const searchLayoutDrawingForm = createActionSet(
    actionType.SEARCH_LAYOUTDRAWING_FORM,
)
export const searchFilterApartmentLayout = createActionSet(
    actionType.SEARCH_FILTER_APARTMENTLAYOUT,
)
export const deleteApartmentLayout = createActionSet(
    actionType.DELETE_APARTMENTLAYOUT,
)
export const updateApartmentLayout = createActionSet(
    actionType.UPDATE_APARTMENTLAYOUT,
)
export const statusApartmentLayout = createActionSet(
    actionType.STATUS_APARTMENTLAYOUT,
)
export const addApartmentLayout = createActionSet(
    actionType.ADD_APARTMENTLAYOUT,
)
export const getPortfolios = createActionSet(actionType.GET_PORTFOLIOS)
// end user
export const getProjectDetailByClient = createActionSet(
    actionType.GET_PROJECT_DETAIL_BY_CLIENT,
)
export const getBuildingDetailByClient = createActionSet(
    actionType.GET_BUILDING_DETAIL_BY_CLIENT,
)
export const getFloorDetailByClient = createActionSet(
    actionType.GET_FLOOR_DETAIL_BY_CLIENT,
)
export const getListFloorByBuilding = createActionSet(
    actionType.GET_LIST_FLOOR_BY_BUILDING,
)
export const getPortFoliosByLayoutDrawings = createActionSet(
    actionType.GET_PORTFOLIOS_BY_LAYOUTDRAWING,
)
export const clearStateByClient = createActionSet(
    actionType.CLEAR_STATE_APARTMENT_BY_CLIENT,
)
export const getListNumDesignByClient = createActionSet(
    actionType.GET_LIST_NUM_DESIGN_BY_CLIENT,
)
export const getBuildingByProject = createActionSet(
    actionType.GET_BUILDING_BY_PROJECT,
)
export const setSelectedBuilding = createActionSet(
    actionType.SET_BUILDING_DEFAULT,
)
export const setSelectedFloor = createActionSet(
    actionType.SET_FLOOR_DEFAULT,
)
export const getLayoutByBuilding = createActionSet(
    actionType.GET_LAYOUT_BY_BUILDING,
)
export const addLayoutDrawing = createActionSet(actionType.ADD_LAYOUTDRAWING)

//reset store apartmentLayout
export const resetApartmentLayoutState = () => {
    return {
        type: 'RESET_APARTMENTLAYOUT_STATE',
    }
}
export const resetBuildingState = () => {
    return {
        type: 'RESET_BUILDING_STATE',
    }
}

export const resetProjectState = () => {
    return {
        type: 'RESET_PROJECT_STATE',
    }
}

export const resetMessageState = () => {
    return {
        type: 'RESET_MESSAGE_STATE',
    }
}
