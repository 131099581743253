/** @format */

import { takeLatest, put } from '@redux-saga/core/effects'
import * as actionType from '../constants/actionType'
import * as action from '../actions'
import {
  getListBuildings,
} from '../../api/buildingApi'
import * as buildingApi from '../../api/buildingApi'
import { getFloorListByBuilding } from '../../api/floorApi'
function* getBuildingList({ payload }) {
  try {
    let res = yield getListBuildings(
      null,
      {},
      // { page: payload?.page, pageSize: payload?.limit, _sort: 'name:ASC' },
      { page: payload?.page, pageSize: 5, _sort: 'name:ASC' },
      // { _sort: 'name:ASC' },
    )
    let data = res.results.map((item, idx) => {
      return {
        id: item?._id,
        index: payload.page * 5 - 5 + idx + 1,
        name: item?.name,
        project: item?.project?.name,
        status: item?.published_at ? 'publish' : 'unpublish',
      }
    })
    yield put(
      action.getBuilding.success({
        listBuildings: data,
        totalPage: res.pagination.pageCount,
        page: payload.page,
      }),
    )
  } catch (error) {
    yield put(action.getBuilding.failure({ message: error.message }))
  }
}
//chi tiết
function* buildingDetail({ payload }) {
  try {
    // fetch API
    let result = yield getListBuildings(null, { id: payload.id }, {})
    yield put(action.getDetailBuilding.success({ buildingDetail: result }))
  } catch (error) {
    yield put(action.getDetailBuilding.failure({ message: error.message }))
  }
}


function* getBuildingDetailByClient({ payload }) {
  try {
    if (!payload?.slug) {
      alert(
        'Building slug is required. Please update building name to generate new building slug.',
      )
      throw new Error('Building slug is required')
    }
    // fetch Api || mockup
    const res = yield buildingApi.getBuildingDetailByClient(null, null, {
      slug: payload.slug,
    })

    const listFloor = yield getFloorListByBuilding(null, null, {
      buildingId: res[0]?.id,
    })

    if (res.error) throw res

    res.listFloor = listFloor.sort((a, b) => {
      if (+a.label.split(' ')[1] > +b.label.split(' ')[1]) {
        return 1
      }
      if (+a.label.split(' ')[1] < +b.label.split(' ')[1]) {
        return -1
      }
      return 0
    })

    res.listFloor = listFloor.sort((a, b) => {
      if (+a.label > +b.label) {
        return 1
      }
      if (+a.label < +b.label) {
        return -1
      }
      return 0
    })

    yield put(action.getBuildingDetailByClient.success({ buildingDetail: res }))
  } catch (error) {
    yield put(action.getBuildingDetailByClient.failure(error))
  }
}

function* getBuildingByProject({ payload }) {
  // console.log(payload,'lof duwx jie===============================');
  try {
    let res = yield getListBuildings(
      null,
      {},
      {
        project: `${payload.project}`,
        page: payload?.page,
        pageSize: 5,
        _sort: 'name:ASC',
      },
    )
    let data = res.results.map((item, idx) => {
      return {
        id: item?._id,
        index: payload.page * 5 - 5 + idx + 1,
        name: item?.name,
        project: item?.project?.name,
        status: item?.published_at ? 'publish' : 'unpublish',
      }
    })
    yield put(
      action.getBuildingByProject.success({
        listBuildingByProject: data,
        totalPage: res.pagination.pageCount,
        page: payload.page,
      }),
    )
  } catch (error) {
    yield put(action.getBuildingByProject.failure({ message: error.message }))
  }
}
const buildingSaga = [
  takeLatest(actionType.GET_BUILDING.REQUEST, getBuildingList),
  takeLatest(actionType.GET_DETAIL_BUILDING.REQUEST, buildingDetail),
  takeLatest(actionType.GET_BUILDING_DETAIL_BY_CLIENT.REQUEST, getBuildingDetailByClient),
  takeLatest(actionType.GET_BUILDING_BY_PROJECT.REQUEST, getBuildingByProject),
]

export default buildingSaga
