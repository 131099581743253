/** @format */

import {runtimeConfig} from "../../config";

const createActionTypeSet = (action) => {
  return {
    REQUEST: action + '_REQUEST',
    SUCCESS: action + '_SUCCESS',
    FAILURE: action + '_FAILURE',
  }
}

export const LOGIN = createActionTypeSet('LOGIN')

//All Layout
export const GET_LAYOUT = createActionTypeSet('GET_LAYOUT')
export const REMOVE_GROUP_FLOOR = createActionTypeSet('REMOVE_GROUP_FLOOR')

//Floor Manager
export const GET_FLOOR = createActionTypeSet('GET_FLOOR')
export const ADD_FLOOR = createActionTypeSet('ADD_FLOOR')
export const UPDATE_FLOOR = createActionTypeSet('UPDATE_FLOOR')
export const DELETE_FLOOR = createActionTypeSet('DELETE_FLOOR')

//Group
export const GET_GROUP = createActionTypeSet('GET_GROUP')
export const ADD_GROUP = createActionTypeSet('ADD_GROUP')
export const UPDATE_GROUP = createActionTypeSet('UPDATE_GROUP')
export const UPDATE_GROUP_FLOOR = createActionTypeSet('UPDATE_GROUP_FLOOR')
export const DELETE_GROUP = createActionTypeSet('DELETE_GROUP')

//Floor Link
export const GET_FLOOR_LINK = createActionTypeSet('GET_FLOOR_LINK')
export const ADD_FLOOR_LINK = createActionTypeSet('ADD_FLOOR_LINK')
export const UPDATE_FLOOR_LINK = createActionTypeSet('UPDATE_FLOOR_LINK')
export const DELETE_FLOOR_LINK = createActionTypeSet('DELETE_FLOOR_LINK')

//Layout link
export const GET_LAYOUT_LINK = createActionTypeSet('GET_LAYOUT_LINK')
export const ADD_LAYOUT_LINK = createActionTypeSet('ADD_LAYOUT_LINK')
export const UPDATE_LAYOUT_LINK = createActionTypeSet('UPDATE_LAYOUT_LINK')
export const DELETE_LAYOUT_LINK = createActionTypeSet('DELETE_LAYOUT_LINK')

export const BUILDING_FILTER = createActionTypeSet('BUILDING_FILTER')
export const CREATE_BUILDING = createActionTypeSet('CREATE_BUILDING')
export const UPDATE_BUILDING = createActionTypeSet('UPDATE_BUILDING')
export const DELETE_BUILDING = createActionTypeSet('DELETE_BUILDING')
export const PUBLISH_BUILDING = createActionTypeSet('PUBLISH_BUILDING')
export const UNPUBLISH_BUILDING = createActionTypeSet('UNPUBLISH_BUILDING')

// default
export const SET_BUILDING_DEFAULT = createActionTypeSet('SET_BUILDING_DEFAULT')
export const SET_FLOOR_DEFAULT = createActionTypeSet('SET_FLOOR_DEFAULT')
//project minh làm
export const GET_LIST_PROJECT = createActionTypeSet('GET_LIST_PROJECT')
export const GET_PROJECT_DETAIL = createActionTypeSet('GET_PROJECT_DETAIL')
export const UPDATE_STATUS_PROJECT = createActionTypeSet(
  'UPDATE_STATUS_PROJECT',
)
export const CREATE_PROJECT = createActionTypeSet('CREATE_PROJECT')
export const UPDATE_PROJECT = createActionTypeSet('UPDATE_PROJECT')
export const DELETE_PROJECT = createActionTypeSet('DELETE_PROJECT')
//end project minh làm
export const GET_LAYOUT_BY_BUILDING = createActionTypeSet(
  'GET_LAYOUT_BY_BUILDING',
)
//filter minh làm
export const GET_FILTER_INVESTOR_LIST = createActionTypeSet(
  'GET_FILTER_INVESTOR_LIST',
)
export const GET_FILTER_PROVINCE_LIST = createActionTypeSet(
  'GET_FILTER_PROVINCE_LIST',
)
export const GET_FILTER_DISTRICT_LIST = createActionTypeSet(
  'GET_FILTER_DISTRICT_LIST',
)
export const GET_FILTER_WARD_LIST = createActionTypeSet('GET_FILTER_WARD_LIST')

export const GET_PROVINCE_LIST = createActionTypeSet('GET_PROVINCE_LIST')
export const GET_DISTRICT_LIST = createActionTypeSet('GET_DISTRICT_LIST')
export const GET_WARD_LIST = createActionTypeSet('GET_WARD_LIST')
// export const GET_FILTER_WARD_LIST = createActionTypeSet('GET_FILTER_WARD_LIST')
export const SEARCH_PROJECT_BY_FILTER = createActionTypeSet(
  'SEARCH_PROJECT_BY_FILTER',
)
//end filter minh làm

export const GET_BUILDING = createActionTypeSet('GET_BUILDING')
export const GET_DETAIL_BUILDING = createActionTypeSet('GET_DETAIL_BUILDING')
export const FILTER = createActionTypeSet('FILTER')
export const GET_APARTMENTLAYOUT = createActionTypeSet('GET_APARTMENTLAYOUT')
export const GET_APARTMENTDETAIL = createActionTypeSet('GET_APRTMENTDETAIL')
export const SEARCH_PROJECT_LIST = createActionTypeSet('SEARCH_PROJECT_LIST')
export const SEARCH_BUILDING_LIST = createActionTypeSet('SEARCH_BUILDING_LIST')
export const SEARCH_APARTMENT_TYPE = createActionTypeSet(
  'SEARCH_APARTMENT_TYPE',
)
export const SEARCH_LAYOUTDRAWING_FORM = createActionTypeSet(
  'SEARCH_LAYOUTDRAWING_FORM',
)
export const SEARCH_FILTER_APARTMENTLAYOUT = createActionTypeSet(
  'SEARCH_FILTER_APARTMENTLAYOUT',
)
export const DELETE_APARTMENTLAYOUT = createActionTypeSet(
  'DELETE_APARTMENTLAYOUT',
)
export const STATUS_APARTMENTLAYOUT = createActionTypeSet(
  'STATUS_APARTMENTLAYOUT',
)
export const UPDATE_APARTMENTLAYOUT = createActionTypeSet(
  'UPDATE_APARTMENTLAYOUT',
)
export const ADD_APARTMENTLAYOUT = createActionTypeSet('ADD_APARTMENTLAYOUT')
export const GET_PORTFOLIOS = createActionTypeSet('GET_PORTFOLIOS')
export const GET_BUILDING_BY_PROJECT = createActionTypeSet(
  'GET_BUILDING_BY_PROJECT',
)
// end user
export const GET_PROJECT_DETAIL_BY_CLIENT = createActionTypeSet(
  'GET_PROJECT_DETAIL_BY_CLIENT',
)
export const GET_PORTFOLIOS_BY_LAYOUTDRAWING = createActionTypeSet(
  'GET_PORTFOLIOS_BY_LAYOUTDRAWING',
)

export const CLEAR_STATE_APARTMENT_BY_CLIENT = createActionTypeSet(
  'CLEAR_STATE_APARTMENT_BY_CLIENT',
)
export const GET_BUILDING_DETAIL_BY_CLIENT = createActionTypeSet(
  'GET_BUILDING_DETAIL_BY_CLIENT',
)

export const GET_FLOOR_DETAIL_BY_CLIENT = createActionTypeSet(
  'GET_FLOOR_DETAIL_BY_CLIENT',
)

export const GET_LIST_FLOOR_BY_BUILDING = createActionTypeSet(
  'GET_LIST_FLOOR_BY_BUILDING',
)

export const GET_LIST_NUM_DESIGN_BY_CLIENT = createActionTypeSet(
  'GET_LIST_NUM_DESIGN_BY_CLIENT',
)

export const ADD_LAYOUTDRAWING = createActionTypeSet('ADD_LAYOUTDRAWING')
// domain image


export const DOMAIN_IMAGE = runtimeConfig.RAZZLE_BACKEND_URL

export const DOMAIN = runtimeConfig.RAZZLE_BACKEND_URL

export const DOMAIN_USER = runtimeConfig.RAZZLE_USER

export const DOMAIN_USER_FLEXHOME = runtimeConfig.RAZZLE_WEBUSER_FLEXHOME_URL
