/** @format */

import { makeStyles } from '@material-ui/core'
import {isWindow} from "../../utils/help";

const useStyles = makeStyles((theme) => ({
  figureWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.between('1249', '1251')]: {
      marginTop: 16,
      marginBottom: 16
    }
  },
  figureImage: {
    flex: 7,
    [theme.breakpoints.between('1249', '1251')]: {
      // flex: 6
    }
  },
  image: {
    borderRadius: 7,
    boxShadow: theme.shadows[5],
    maxWidth: '100%',
  },
  boardFloor: {
    padding: " 0px 24px 0px 0px",
    flex: 3,
    [theme.breakpoints.between('1249', '1251')]: {
      // flex: 4
    }
  },
  listWrapper: {
    height: (props) => props.height / 1.46,
    maxHeight: (props) => props.height / 1.46,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      height: 0,
      display: 'none',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      height: '15rem',
      maxHeight: '15rem',
      overflowX: 'auto',
      scrollbarWidth: 'none' /* for Firefox */,
      '&::-webkit-scrollbar': {
        height: 0,
        display: 'none',
      },
    },
    [theme.breakpoints.between('1249', '1251')]: {
      // height: '10rem !important',
      // maxHeight: '10rem !important'
    },
    // [theme.breakpoints.between('md', 'xs')]: {
    //   width: '20rem',
    //   maxWidth: '20rem',
    //   height: 'auto!important',
    //   maxHeight: 'fit-content!important',
    //   overflowX: 'auto',
    //   '&::-webkit-scrollbar': {
    //     height: 0,
    //     display: 'none',
    //   },
    // },
    [theme.breakpoints.down('1025')]: {
      height: (props) => props.height / 3,
      maxHeight: (props) => props.height / 3,
    }
  },
  divider: {
    // width: '90%',
    margin: 'auto',
    backgroundColor: theme.palette.secondary.dark,
    // [theme.breakpoints.down('md')]: {
    //   width: '100%',
    // },
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyItems: 'flex-start',
    // [theme.breakpoints.between('md', 'xs')]: {
    //   flexDirection: 'column',
    //   height: '12em',
    //   overflowX: 'scroll',
    // },
  },
  listItem: {
    flexShrink: 0,
    flexGrow: 0,
    justifyContent: 'center',
    width: '33.33%',
    padding: '5px 10px',

    [theme.breakpoints.down('xs')]: {
      width: '49%',
      marginBottom: theme.spacing(2),
    },
  },
  itemBtn: {
    height:isWindow ? (window.innerWidth * 20) / 1280 : 1920 * 20 /1280,
    flex: `0 0 ${(( isWindow ? window.innerWidth : 1920) * 20) / 1280}px`,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: "#C1C1C1",
    color: "#6A6A6A",
    backgroundColor: "#FDFDFD",
    '&.Mui-disabled': {
      background: "#C1C1C1",
    },
    '&.active': {
      backgroundColor: "#006CD9",
      color: "#FFFFFF",
    },
    '&.hover': {
      backgroundColor: '#E6F2FF',
      // color: theme.palette.secondary.contrastText,
    },
    [theme.breakpoints.down('1400')]: {
      height: 20,
      flex: `0 0 20px`,
      fontSize: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      height: 35,
      flex: `0 0 35px`,
      fontSize: '16px',
    },
  },
  btnFloor: {
    padding: '10px',
    zIndex: 1,
  },
  actionList: {
    textAlign: 'center',
    // margin: '10px 0',
    // [theme.breakpoints.between('md', 'xs')]: {
    //   transform: 'rotate(-90deg)',
    //   width: '25%',
    // },
  },
  actionText: {
    fontSize: theme.fontSizing.f16,
    width: '80%',
    padding: theme.spacing(0.3),
    // backgroundColor: '#FAFAFA',
    fontWeight: 600,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('1400')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.fontSizing.f10,
    },
  },
}))

export default useStyles
