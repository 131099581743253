/** @format */

import React from 'react'
import {Grid, Box, Typography as Typo, ButtonBase} from '@material-ui/core'
import useStyles from './ApartmentSliderStyles'
import Arrow from '../../assets/images/v2/arrow.svg'
import ImageNotAvailable from '../../assets/images/v2/image-not-avalible.png'
import {DOMAIN_IMAGE} from '../../redux/constants/actionType'
import {useHistory} from "react-router-dom";
import {isNotEmptyObject, isWindow} from "../../utils/help";

export function SliderItem({
                               key,
                               item,
                               layoutPointHover,
                               setSelectedLayout,
                           }) {
    const classes = useStyles()
    const numDesign = item?.layout_drawing?.portfolios ? item.layout_drawing.portfolios.length : 0
    const history = useHistory()
    const handleClick = (e, _item) => {
        if (_item?.slug) {
            history.push(`${isWindow && window.location.pathname}/${_item?.slug}`)
        }
    }
    const handleHover = () => {
        setSelectedLayout({...item})
    }
    return (
        <Box key={key} style={{padding: `10px 12px`}}>
            <Grid
                container
                className={`${classes.sliderItem} ${isNotEmptyObject(layoutPointHover) && item?.id === layoutPointHover?.id && classes.activePointHover}`}
                component={ButtonBase}
                onClick={(e) => handleClick(e, item)}
                onMouseEnter={handleHover}
            >
                <Grid item xs={5}>
                    <Box
                        className={classes.sliderImage}
                        style={{
                            backgroundImage: `url(${
                                (item?.layout_drawing?.feautured_image?.url) ? DOMAIN_IMAGE + item?.layout_drawing?.feautured_image?.url : ImageNotAvailable
                            })`,
                        }}
                    />
                </Grid>
                <Grid item xs={7} style={{paddingLeft: '15px'}}>
                    <Typo variant={'body1'} className={classes.apartmentName}>
                        {' '}
                        {item?.name}
                    </Typo>
                    <Typo className={classes.apartmentInfo}>
                        {' '}
                        {item?.acreage} {' m2'}
                    </Typo>
                    <Typo className={classes.apartmentInfo}>
                        {' '}
                        {item?.layout_type?.name}
                    </Typo>
                    <Typo className={classes.apartmentInfo}>
                        {' '}
                        {numDesign} thiết kế
                    </Typo>
                </Grid>
            </Grid>
        </Box>
    )
}

export function SliderPrevArrow(props) {
    // className, style in props for default setting
    const {onClick} = props
    const classes = useStyles()
    return (
        <Box
            onClick={onClick}
            className={`${classes.sliderArrow} prev`}
            component={ButtonBase}
            style={{backgroundImage: `url(${Arrow})`}}
        />
    )
}

export function SliderNextArrow(props) {
    // className, style in props for default setting
    const {onClick} = props
    const classes = useStyles()
    return (
        <Box
            onClick={onClick}
            className={`${classes.sliderArrow} next`}
            component={ButtonBase}
            style={{backgroundImage: `url(${Arrow})`}}
        />
    )
}

export function SliderLeftArrow(props) {
    // className, style in props for default setting
    const {onClick} = props
    const classes = useStyles()
    return (
        <Box
            onClick={onClick}
            className={`${classes.sliderArrow} up`}
            component={ButtonBase}
            style={{backgroundImage: `url(${Arrow})`}}
        />
    )
}

export function SliderRightArrow(props) {
    // className, style in props for default setting
    const {onClick} = props
    const classes = useStyles()
    return (
        <Box
            onClick={onClick}
            className={`${classes.sliderArrow} down`}
            component={ButtonBase}
            style={{backgroundImage: `url(${Arrow})`}}
        />
    )
}
