/** @format */

import React from 'react'
import CompassImage from '../../assets/images/v2/Siteplan_La-ban.svg'
import {makeStyles} from '@material-ui/core'

const Showcompass = (props) => {
    const {rote, matchMobile} = props
    const classes = useStyles()
    return (
        <div>
            <div
                className={`col-12 ${classes.container}`}
                style={{
                    position: 'relative',
                    transition: '0.2s',
                    left: 20,
                    zIndex: matchMobile ? 0 : 2,
                }}
            >
                <div
                    className={`box`}
                    style={{
                        height: matchMobile ? 40 : 60,
                        width: matchMobile ? 40 : 60,
                        transition: '0.2s',
                        backgroundImage: `url('${CompassImage}')`,
                        margin: matchMobile ? '10px 0px' : '25px 25px 18.5px 25px',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        color: 'white',
                        padding: '10px',
                        msTransformOrigin: '50%',
                        WebkitTransformOrigin: '50%',
                        transformOrigin: '50%',
                        transform: `rotate(${rote}deg)`,
                        backgroundColor: '#cdc5b8',
                        borderRadius: '50%',
                    }}
                />
            </div>
        </div>
    )
}

export default Showcompass

const useStyles = makeStyles((theme) => {
    return {
        container: {
            [theme.breakpoints.up(1440)]: {
                top: '5vh',
                left: '-4vh',
            },
            [theme.breakpoints.up(1023)]: {
                top: '2vh',
                left: '-3vh',
            },
            [theme.breakpoints.up(1439)]: {
                top: '0',
                left: '0',
            },
            [theme.breakpoints.up(768)]: {
                '&:hover': {
                    transform: 'scale(1.12)',
                    transition: '0.4s',
                },
            },
        },

    }
})
