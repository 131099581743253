/** @format */

import { all } from 'redux-saga/effects';
import buildingSaga from './buildingSaga';
import projectSaga from './projectSaga';
import apartmentLayoutSaga from './apartmentLayoutSaga';
import floorSaga from './floorSaga';
import groupSaga from './groupSaga';
import floorLinkSaga from './floorLinkSaga';

export default function* rootSaga() {
  yield all([
    ...buildingSaga,
    ...projectSaga,
    ...apartmentLayoutSaga,
    ...floorSaga,
    ...groupSaga,
    ...floorLinkSaga,
  ]);
}
