/** @format */

import { Box, Button, Popover, makeStyles } from '@material-ui/core'
import React from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
// import PhoneInTalkOutlinedIcon from '@material-ui/icons/PhoneInTalkOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined'
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined'
// import Snackbar from '../';
import Snackbar from '../common/Snackbar/SnackbarEU2'
import {isWindow} from "../../utils/help";
import {runtimeConfig} from "../../config";

export function SharedBox() {
  const classes = useStylesSharedBox()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [copied, setCopied] = React.useState(false)
  async function copyTextToClipboard(text) {
    setCopied(true)
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // async function copyTextToClipboard(text) {
  //     if ('clipboard' in navigator) {
  //         return await navigator.clipboard.writeText(text);
  //     } else {
  //         return document.execCommand('copy', true, text);
  //     }
  // }
  const handleCloseSnackbar = () => {
    setCopied(false)
  }
  const open = Boolean(anchorEl)
  return (
    <Box className={classes.root}>
      <Box>
        <Button
          variant='text'
          size='small'
          className={classes.link}
          onClick={handleClick}
        >
          <ShareOutlinedIcon className={classes.linkIcon} /> Chia sẻ
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {SocialShareBox}
        </Popover>
        <Button
          variant='text'
          size='small'
          className={classes.link}
          onClick={() => {
            copyTextToClipboard(isWindow && window.location.href)
            setCopied(true)
          }}
        >
          <LinkOutlinedIcon className={classes.linkIcon} /> Short link
        </Button>
        <Snackbar
          open={copied}
          content='Copied !'
          severity='success'
          handleCloseSnackbar={handleCloseSnackbar}
        />
      </Box>
    </Box>
  )
}

const SocialShareBox = (
  <Box
    style={{
      backgroundColor: 'white',
      boxShadow: '2px 2px 5px #a2a2a2',
    }}
  >
    <Box justifyContent='center' alignItems='center'>
      <FacebookShareButton
        style={{
          display: 'flex',
          padding: '10px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        url={isWindow ? window.location.href : runtimeConfig.RAZZLE_BASE_URL}
        quote={'Share to facebook'}
      >
        <FacebookIcon size={32} round style={{ marginRight: '6px' }} />
        Share to facebook
      </FacebookShareButton>
    </Box>
    <Box justifyContent='center' alignItems='center'>
      <WhatsappShareButton
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px',
        }}
        url={isWindow ? window.location.href : runtimeConfig.RAZZLE_BASE_URL}
        quote={'Share to Whatsapp'}
      >
        <WhatsappIcon size={32} round style={{ marginRight: '6px' }} />
        Share to Whatsapp
      </WhatsappShareButton>
    </Box>
    <Box justifyContent='center' alignItems='center'>
      <TwitterShareButton
        style={{
          display: 'flex',
          padding: '10px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        url={isWindow ? window.location.href : runtimeConfig.RAZZLE_BASE_URL}
        quote={'Share to twitter'}
      >
        <TwitterIcon size={32} round style={{ marginRight: '6px' }} />
        Share to twitter
      </TwitterShareButton>
    </Box>
  </Box>
)

const useStylesSharedBox = makeStyles((theme) => ({

  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

  },
  phone: {
    textDecoration: 'none',
    color: theme.palette.secondary.dark,
    display: 'flex',
  },
  link: {
    fontSize:isWindow ? window.innerWidth * (14 / 1920) : 14,
    paddingRight: theme.spacing(1),
    textTransform: 'capitalize',
    [theme.breakpoints.down('md')]: {
      fontSize:isWindow ? window.innerWidth * (10 / 768) : 1920 * (10 / 768),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize:isWindow ? window.innerWidth * (12 / 375) : 1920 * (12 / 375),
    },
  },
  linkIcon: {
    fontSize: isWindow ? window.innerWidth * (14 / 1920) : 14,
    [theme.breakpoints.down('md')]: {
      fontSize:isWindow ? window.innerWidth * (14 / 768) : 1920 * (14 / 768),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize:isWindow ? window.innerWidth * (12 / 375) : 1920  * (12 / 375),
    },
    margin: '5px',
  },
}))
