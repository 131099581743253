/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react'
import {Box} from '@material-ui/core'
import ShowCompass from "../Compass/ShowCompass";
import _ from 'lodash'
import {isWindow} from "../../utils/help";

function LayoutHighlight2(props) {
    const {
        image,
        coordinateSelected,
        widthImg,
        heightImg,
        layoutCoordinates,
        hoverAble,
        style,
        listApartments,
        setLayoutPointHover,
        handleOnClickPointHover,
        matchMobile,
        ratio
    } = props
    const [elements, setElements] = React.useState([]) // list point xám
    const [offsetTop, setOffsetTop] = React.useState(0)
    const [offsetLeft, setOffsetLeft] = React.useState(0)
    const [selectedPoint, setSelectedPoint] = React.useState([])
    const [hover, setHover] = React.useState(false)

    React.useEffect(() => {
        if (coordinateSelected?.coordinates?.length > 0) {
            setSelectedPoint([...coordinateSelected?.coordinates])
        } else {
            setSelectedPoint([])
        }
    }, [coordinateSelected])

    React.useEffect(() => {
        if (matchMobile) {
            setElements([])
        } else {
            const canvas = document.getElementById('layout-canvas')
            const bounding = canvas.getBoundingClientRect()
            setOffsetTop(bounding.top)
            setOffsetLeft(bounding.left)
            const listPoints = []
            layoutCoordinates?.forEach(({coordinates}) => {
                coordinates?.forEach((point) => {
                    listPoints.push(point)
                })
            })
            setElements(listPoints)
        }
    }, [matchMobile,layoutCoordinates])

    React.useEffect(() => {
        if (!isWindow || matchMobile) return;
        const canvas = document.getElementById('layout-canvas')
        const ctx = canvas.getContext('2d')
        const bounding = canvas.getBoundingClientRect()
        setOffsetTop(bounding.y)
        setOffsetLeft(bounding.x)
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        //Render item in props
        if (elements?.length > 0) {
            let w = widthImg,
                h = heightImg,
                // cx = w * 0.05,
                // cy = h * 0.5,
                glow = 0,
                dlt = 1,
                max = 40

            ctx.fillStyle = '#fff'
            function anim() {
                ctx.clearRect(0, 0, w, h)
                // draw main circle and glow
                ctx.save()
                elements?.forEach(({id, x1, x2, y1, y2, roughElement}, index) => {
                    ctx.fillStyle = 'gray'
                    // ctx.shadowBlur = glow
                    ctx.shadowColor = '#000'
                    ctx.shadowBlur = 0

                    ctx.beginPath()
                    // ctx.arc(x1 * ratio, y1 * ratio, cx * 0.25, 0, 6.28)
                    ctx.arc(x1 * ratio, y1 * ratio, 11, 0, 6.28)
                    ctx.fill()
                    ctx.restore() // removes the shadow
                    // draw inner circle
                    ctx.globalCompositeOperation = 'destination-out'
                    ctx.beginPath()
                    // ctx.arc(x1 * ratio, y1 * ratio, cx * 0.23, 0, Math.PI * 2, true)
                    // ctx.arc(x1 * ratio, y1 * ratio, cx * 0.23, 0, 6.28)
                    ctx.arc(x1 * ratio, y1 * ratio, 10.8, 0, Math.PI * 2, true)
                    ctx.arc(x1 * ratio, y1 * ratio, 10.8, 0, 6.28)
                    ctx.fill()
                    ctx.globalCompositeOperation = 'source-over'
                    selectedPoint?.forEach((element) => {
                        if (element?.x1 === x1 && element?.y1 === y1 && element?.x2 === x2 && element?.y2 === y2 && element?.id === id) {
                            ctx.fillStyle = '#ddd'
                            ctx.shadowBlur = 0
                            //vẽ chấm tròn
                            ctx.fillStyle = '#006CD9'
                            ctx.shadowColor = '#006CD9'
                            ctx.shadowBlur = glow
                            ctx.beginPath()
                            // ctx.arc(x1 * ratio, y1 * ratio, cx * 0.25, 0, 6.28)
                            ctx.arc(x1 * ratio, y1 * ratio, 11, 0, 6.28)
                            ctx.fill()
                            ctx.restore() // removes the shadow
                            // draw inner circle
                            ctx.globalCompositeOperation = 'destination-out'
                            ctx.beginPath()
                            // ctx.arc(x1 * ratio, y1 * ratio, cx * 0.23, 0, Math.PI * 2, true)
                            // ctx.arc(x1 * ratio, y1 * ratio, cx * 0.23, 0, 6.28)
                            ctx.arc(x1 * ratio, y1 * ratio, 10.8, 0, Math.PI * 2, true)
                            ctx.arc(x1 * ratio, y1 * ratio, 10.8, 0, 6.28)
                            ctx.fill()
                            ctx.globalCompositeOperation = 'source-over'
                        }
                    })
                })
                glow += dlt
                if (glow <= 0 || glow >= max) dlt = -dlt
                requestAnimationFrame(anim)
            }
            anim()
        }
    }, [selectedPoint, hover,elements])

    const handleMouseMove = (event) => {
        const canvas = document.getElementById('layout-canvas')
        canvas.style.cursor = 'pointer'
        let {clientX, clientY} = event
        clientX = (clientX - offsetLeft) / ratio
        clientY = (clientY - offsetTop) / ratio
        const tooltipCanvas = document.getElementById("tooltipCanvas");
        tooltipCanvas.style.top = `${event.pageY + 15}px`
        tooltipCanvas.style.left = `${event.pageX}px`
        if (layoutCoordinates?.length > 0 && hoverAble) {
            const _find = _.find(layoutCoordinates, function ({coordinates, layout}) {
              const _findPoint =  _.find(coordinates, function ({x1, y1, x2, y2}) {
                    return  Number(x2) > Number(clientX - 10) &&
                        Number(clientX + 10) > Number(x1) &&
                        Number(y2) > Number(clientY - 10) &&
                        Number(clientY + 10) > Number(y1)
                })
                if (_findPoint){
                    const _layout = _.find(listApartments, {id: layout?.id})
                    if (_layout){
                        setLayoutPointHover(_layout)
                        setSelectedPoint([_findPoint])
                        setHover(true)
                        tooltipCanvas.style.display = "block"
                        tooltipCanvas.innerHTML = `${_layout?.acreage} ㎡ </br> ${_layout?.layout_type?.name ? _layout?.layout_type?.name  : ""}`
                    }
                }
                return _findPoint
            });
            if (!_find){
                setLayoutPointHover({})
                setSelectedPoint([])
                setHover(false)
                tooltipCanvas.style.display = "none"
            }
        }
    }

    return (
        <div style={{textAlign: "center", position:'relative'}}>
            <canvas
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    filter: 'drop-shadow( 3px 3px 5px rgb(0, 0, 0, 0.5 )',
                    ...style,
                    zIndex: 1
                }}
                id='layout-canvas'
                width={widthImg}
                height={heightImg}
                onMouseMove={handleMouseMove}
                onClick={handleOnClickPointHover}
            />
            {
                matchMobile &&
                <Box>
                    <ShowCompass
                        matchMobile
                        rote={
                            props.floorLink && props.floorLink.Compass
                                ? props.floorLink.Compass
                                : 0
                        }
                    />
                </Box>
            }
            <Box id={"tooltipCanvas"}/>
        </div>
    )
}

export default LayoutHighlight2
