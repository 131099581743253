/** @format */

import React from 'react'
import {
    Grid,
    Box,
    Typography, Popover, ButtonBase,
} from '@material-ui/core'
import {Rating} from '@material-ui/lab'
import useStyles from './DesignSliderStyles'
import Arrow from '../../assets/images/v2/arrow-big.svg'
import AvatarDefault from '../../assets/images/v2/default-avatar.png'
import ImageNotAvailable from '../../assets/images/v2/image-not-avalible.png'
import {DOMAIN_IMAGE, DOMAIN_USER_FLEXHOME} from '../../redux/constants/actionType'
import _ from "lodash"
import {isWindow, substringCustom} from "../../utils/help";


export function SliderItem({item, ratio, setIsShow, matchMobile, currentIndex, index}) {
    const classes = useStyles()
    const inspirations = _.map(item?.inspirations, 'name')
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <Grid container
              component={ButtonBase} direction='row' wrap='wrap'>
            <Box
                className={`${
                    ratio > 1
                        ? ratio > 1.4
                            ? classes.containerSlideItem
                            : classes.containerSlideItemIpad
                        : classes.containerSlideItemMobile
                }  ${currentIndex === index && classes.active}`}
            >
                <Grid item xs={12} style={{height: '100%'}}>
                    <Box
                        className={`${
                            ratio > 1
                                ? ratio > 1.4
                                    ? classes.sliderItem
                                    : classes.sliderItemIpad
                                : classes.sliderItemMobile
                        }`}
                        style={{
                            backgroundImage: `url(${item?.featured_image ? DOMAIN_IMAGE + item?.featured_image?.url : ImageNotAvailable})`,
                        }}
                    >
                        <Box
                            className={`${
                                ratio > 1 ? classes.architectInfo : classes.architectInfoMobile
                            }`}
                        >
                            <Box
                                className={`${
                                    ratio > 1
                                        ? classes.architectFeatured
                                        : classes.architectFeaturedMobile
                                }`}
                            >
                                <a href={item?.user?.designer_profile ? `${DOMAIN_USER_FLEXHOME}/kien-truc-su/${item?.user?.designer_profile?.slug}` : `${DOMAIN_USER_FLEXHOME}/nha-thau/${item?.user?.contractor_profile?.slug}`}
                                   target="_blank">
                                    <img
                                        className={`${
                                            ratio > 1
                                                ? ratio > 1.4
                                                    ? classes.architectImage
                                                    : classes.architectImageIpad
                                                : classes.architectImageMobile
                                        }`}
                                        src={item?.user?.avatar ? DOMAIN_IMAGE + item?.user?.avatar?.url : AvatarDefault} //Kiểm tra error đErê chuyen giua stg va dev
                                        alt=''
                                    />
                                </a>

                            </Box>
                            <Box className={classes.infomation}>

                                <Typography
                                    onClick={() => matchMobile && setIsShow(false)}
                                    style={{lineHeight: '1.25'}}
                                    className={`${
                                        ratio > 1
                                            ? ratio > 1.4
                                                ? classes.designModelName
                                                : classes.designModelNameIpad
                                            : classes.designModelNameMobile
                                    }`}
                                >
                                    {substringCustom(item?.title, 25)}

                                </Typography>

                                {
                                    item?.user?.name &&
                                    <Typography
                                        style={{lineHeight: '1.25'}}
                                        className={`${
                                            ratio > 1
                                                ? classes.architectName
                                                : classes.architectNameMobile
                                        }`}
                                    >
                                        <a style={{color: 'inherit', textDecoration: 'unset'}}
                                           href={item?.user?.designer_profile ? `${DOMAIN_USER_FLEXHOME}/kien-truc-su/${item?.user?.designer_profile?.slug}` : `${DOMAIN_USER_FLEXHOME}/nha-thau/${item?.user?.contractor_profile?.slug}`}
                                           target="_blank">

                                            {item?.user?.designer_profile ?
                                                "Kiến Trúc Sư: " + item?.user?.name :
                                                "Nhà thầu: " + item?.user?.name
                                            }
                                        </a>
                                    </Typography>
                                }

                                <Typography
                                    style={
                                        ratio > 1
                                            ? {lineHeight: '1.25'}
                                            : {lineHeight: '1.25', display: 'block'}
                                    }
                                    className={`${ratio > 1 ? classes.text : classes.textMobile}`}
                                >
                                    {inspirations &&
                                        _.slice(inspirations, 0, 2).map((item, index) => (
                                            <span className={classes.style} key={index}>{item}</span>
                                        ))}
                                    {inspirations.length - 2 > 0 &&
                                        <>
                                            <span
                                                className={classes.plusMore}

                                                onClick={handleClick}
                                            >
                                                 {`+${inspirations.length - 2}`}
                                            </span>
                                            <Popover
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                className={classes.popoverInspirations}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                {inspirations.map((item, index) => (
                                                    <span className={classes.style} key={index}>{item}</span>
                                                ))}
                                            </Popover>
                                        </>
                                    }{' '}
                                </Typography>
                                <Typography style={{lineHeight: 1}}>
                                    <Rating
                                        readOnly
                                        name={index.toString()}
                                        value={5}
                                        className={`${
                                            ratio > 1 ? classes.rating : classes.ratingMobile
                                        }`}
                                    />
                                </Typography>
                                {/* <Typography className={classes.text}> {item.architectInfo.numDesign} thiết kế </Typography> */}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Box>
        </Grid>
    )
}

export function SliderPrevArrow(props) {
    // className, style in props for default setting
    const {onClick} = props
    const classes = useStyles()
    return (
        <Box className={classes.buttonSlidePrev}>
            <Box
                onClick={onClick}
                className={`${classes.sliderArrow} prev`}
                component={ButtonBase}
                style={{backgroundImage: `url(${Arrow})`}}
            />
        </Box>
    )
}

export function SliderNextArrow(props) {
    // className, style in props for default setting
    const {onClick} = props
    const classes = useStyles()
    return (
        <Box className={classes.buttonSlideNext}>
            <Box
                onClick={onClick}
                className={`${classes.sliderArrow} next`}
                component={ButtonBase}
                style={{backgroundImage: `url(${Arrow})`}}
            />
        </Box>
    )
}
