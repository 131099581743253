/** @format */

import {makeStyles} from '@material-ui/core'
import {isWindow} from "../../utils/help";

export default makeStyles((theme) => {
    return {
        // ApartmentSlider
        slideWrapper: {
            paddingTop: 26,
            paddingBottom: 20,
            paddingLeft: 10,
            paddingRight: 10,
            width: '100%',
            backgroundColor: '#00030e7a',
            '&.row': {

                [theme.breakpoints.down('1281')]: {
                    color: "red",
                    height: (isWindow ? window.screen.width : 1281)  * (220 / 1280),
                },
                [theme.breakpoints.down('1101')]: {
                    height: (isWindow ? window.screen.width : 1101)  * (190 / 1280),
                },
                [theme.breakpoints.down('961')]: {
                    height: (isWindow ? window.screen.width : 961)  * (250 / 1280),
                },
                position: 'absolute',
                bottom: '0',
                animation: `$upEffect 300ms`,
                '& .slick-slider ': {
                    // display: 'flex',
                    // alignItems: 'center',
                    '& .slick-list': {
                        width: 'calc(100% - 190px)',
                        margin: 'auto',
                    },
                    '& button.down': {
                        position: 'absolute',
                        right: 38,
                        top: '40%',
                    },
                    '& button.up': {
                        position: 'absolute',
                        left: 38,
                        top: '40%'
                    }
                },
            },
            '&.column': {
                width: (isWindow ? window.screen.width : 1920)  * (250 / 1280),
                height: '100vh',
                // overflowY: 'hidden',
                '& .slick-slider': {
                    top: '50%',
                    transform: 'translateY(-50%)',
                },
                '& .slick-track': {
                    height: 'unset !important'
                },
                position: 'relative',
                bottom: '0',
                order: -1,
                // animation: '$slideRightEffect 300ms',
                [theme.breakpoints.between('1250', '1251')]: {
                    // top: '-545px',
                    // left: ' 82%',
                    // animation: '$slideRightIframeEffect 2000ms',
                },
                "& $titleSlide": {
                    paddingLeft: 15
                },
                "& $sliderItem": {
                    width: "86%",
                    margin: "auto"
                },
                "& .slick-vertical": {
                    // marginTop: "10rem"
                }
            },
        },
        '@keyframes upEffect': {
            '0%': {
                opacity: 0.8,
                bottom: '-100%',
            },
            '100%': {
                opacity: 1,
                bottom: '0%',
            },
        },
        // '@keyframes slideRightIframeEffect': {
        //     '0%': {
        //         opacity: 0.8,
        //         left: '100%',
        //     },
        //     '100%': {
        //         opacity: 1,
        //         left: '82%',
        //     },
        // },
        '@keyframes slideRightEffect': {
            '0%': {
                opacity: 0.8,
                right: '100%',
            },
            '100%': {
                opacity: 1,
                right: '0%',
            },
        },
        sidebarToggle: {
            position: 'absolute',
            backgroundColor: '#ebebeb9e',
            zIndex: 100,
            top: '40%',
            left: (isWindow ? window.screen.width : 1920)  * (250 / 1280),
            transform: `translateY(30%)`,
            height: 96,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            '& .MuiSvgIcon-root': {
                transform: `rotate(180deg)`,
            },
            // clipPath: `polygon(0 0, 100% 20%, 100% 80%, 0 100%)`,
            // [theme.breakpoints.down('1025')]: {
            //   left: '50vw',
            // },
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        slickPortfolios:{},
        wrapper: {
            backgroundColor: "#FDFDFD",
            paddingTop: 16,
            // paddingBottom: 35,
            "& .slick-track": {
                maxHeight: 718
            }
        },
        wrapperMobile: {},
        skeleton: {
          padding: 30
        },

        activePointHover:{
            '&$sliderItem':{
                backgroundColor: '#80bfffc7',
            }
        },
        sliderItem: {
            padding: "20px 16px",
            border: `1px solid #eee`,
            borderRadius: 10,
            transition: `300ms`,
            backgroundColor: "#fdfdfdcf",
            '&:hover': {
                backgroundColor: '#80bfffc7',
            },
            "& .MuiGrid-item:first-child": {
                // padding: 10,
                backgroundColor: "#ffffff",
                borderRadius: 10,
                opacity: 1
            }
        },
        titleSlide: {
            color: "#ffffff",
            paddingLeft: "6.7rem",
            fontSize: "18px",
            fontWeight: 700
        },
        itemImage: {
            maxWidth: '40%',
            flexBasis: '40%',

        },
        sliderItemHover: {
            backgroundColor: 'rgba(0,0,0,.5)',
        },
        apartmentName: {
            fontWeight: 700,
            color: '#00030E',
            textAlign: 'right',
            marginBottom: 5,
            letterSpacing: 0.3,
            // maxWidth: '30px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: 24,
            [theme.breakpoints.down('xl')]: {
                fontSize: 24,
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: 16,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 17,
            },
        },
        apartmentInfo: {
            color: '#6A6A6A',
            textAlign: 'right',
            fontWeight: 400,
            fontSize: theme.fontSizing.f14,
            marginBottom: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('lg')]: {
                fontSize: 13,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: 13,
            },
        },
        sliderImage: {
            borderRadius: 10,
            width: '100%',
            paddingBottom: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            // boxShadow: theme.shadows[3],
            // borderRadius: 10,
        },

        // Arrow
        sliderArrow: {
            zIndex: 10,
            width: 40,
            height: 40,
            backgroundColor: 'transparent',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            '&:hover': {
                // background: 'unset',
            },
            '&.next': {
                right: '-45%',
                transform: 'rotate(-90deg) translateX(-50%)',
                top: -15,
            },
            '&.prev': {
                marginBottom: '10px',
                left: '45%',
                bottom: -25,
                transform: 'rotate(90deg) translateX(-50%)',
                [theme.breakpoints.down('1441')]: {
                    left: '40%',
                },
                [theme.breakpoints.down('1281')]: {
                    left: '0%',
                },
                [theme.breakpoints.down('1101')]: {
                    height: (isWindow ? window.screen.width : 1920)  * (190 / 1280),
                },
                [theme.breakpoints.down('961')]: {
                    height: (isWindow ? window.screen.width : 1920)  * (250 / 1280),
                },
            },
            '&.up': {
                paddingBottom: '5px',
                // transform: 'rotate(90deg)',
            },
            '&.down': {
                paddingBottom: '5px',
                transform: 'rotate(180deg)',
            },
        },
    }
})
