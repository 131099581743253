import * as actionType from '../constants/actionType'

const DEFAULT_STATE = {
    user: {},
    isFetching: false,
    error: false,
    message: ''
}

const userReducer = (state = DEFAULT_STATE, action) => {
    let { type, payload } = action
    switch (type) {
        case actionType.LOGIN.REQUEST:
            return {
                ...state,
                isFetching: true,
                error: false,
                message: ''
            }
        case actionType.LOGIN.SUCCESS:
            return {
                ...state,
                isFetching: false,
                user: payload.user
            }
        case actionType.LOGIN.FAILURE:
            return {
                ...state,
                user: {},
                isFetching: false,
                error: true,
                message: payload.message
            }
        default:
            return state;
    }
}

export default userReducer;