/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import {Box,  Button, TextField} from '@material-ui/core'
import React, { useState} from 'react'
import useStyles from './ViewProjectMobileStyles'
import {DOMAIN_IMAGE} from '../../../../redux/constants/actionType'
import FloorSidebar from '../../../../components/Sidebar/FloorSidebar'
import SwiperCore, {Navigation} from 'swiper'
import * as action from "../../../../redux/actions";
import {connect} from "react-redux";
import {ProjectInfo, SharedBox2} from "../../../../components/Sidebar/ProjectSidebarFragments";
import {isNotEmptyObject} from "../../../../utils/help";
import {Autocomplete} from "@material-ui/lab";
import FloorFigureSidebar from "../../../../components/Sidebar/FloorFigureSidebar";

SwiperCore.use([Navigation])

function ViewProjectMobile(props) {
    const {
        project,
        buildingSlug,
        listBuildings,
        floorLink,
        selectedBuilding,
        setSelectedBuilding,
        projectSlug,
        floorSlug,
        selectedFloor,
        listFloor,
        setSelectedFloor,
        setShowModal
    } = props
    const classes = useStyles()
    const [showFloorSidebar, setShowFloorSidebar] = useState(false)
    return floorSlug ? (
        <FloorFigureSidebar
            setShowModal={setShowModal}
            projectName={project?.name}
            buildingName={selectedBuilding?.name}
            projectSlug={projectSlug}
            buildingSlug={buildingSlug}
            floorSlug={floorSlug}
            selectedFloor={selectedFloor}
            floorLink={floorLink}
            listFloor={listFloor}
            setSelectedFloor={setSelectedFloor}
            setShowFloorSidebar={setShowFloorSidebar}
        />
    ) : (
        <Box>
            <Box
                className={classes.rootViewProject}
                style={{
                    backgroundImage: `url(${
                        DOMAIN_IMAGE + project?.backgroundSitePlanMobile?.url
                    })`,
                }}
            >
                <Box className={classes.overlay}/>

                <ProjectInfo
                    matchMobile={true}
                    name={project?.name || ''}
                             investor={
                                 (project && project.investor && project?.investor?.name) || ''
                             }
                             address={`${project?.address} ${ Boolean(project?.ward && project?.ward?.path_with_type) ? ` , ${project?.ward?.path_with_type}` : ""}`}/>
                <Box className={classes.chooseBuilding}>
                    <div className={classes.shadowText}>Chọn toà nhà</div>
                    {isNotEmptyObject(selectedBuilding) &&
                    <Autocomplete
                        className={classes.chooseBuildingInput}
                        style={{width: '100%'}}
                        disableClearable
                        onChange={(event, newValue) => {
                            setSelectedBuilding(newValue);
                        }}
                        options={listBuildings}
                        getOptionLabel={(option) => option?.name || ''}
                        value={selectedBuilding}
                        getOptionDisabled={option => !isNotEmptyObject(option?.list_floors)}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined"/>
                        )}
                    />
                    }
                    <Button
                        variant='outlined'
                        className={classes.btnSelectFloor}
                        onClick={() => setShowFloorSidebar(!showFloorSidebar)}
                    >
                        Tiếp tục
                    </Button>
                </Box>
            </Box>

            <Box className={`${classes.floorSidebar}`}>
                <FloorSidebar
                    showFloorSidebar={showFloorSidebar}
                    setShowFloorSidebar={setShowFloorSidebar}
                    buildingName={selectedBuilding?.name}
                    projectName={project?.name}
                    floorLink={floorLink}
                    buildingId={selectedBuilding?.id}
                    isFetching={props.isFetching}
                    projectSlug={projectSlug}
                    buildingSlug={buildingSlug}
                />
            </Box>
        </Box>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedBuilding: (data) => {
            dispatch(action.setSelectedBuilding.success(data))
        },
        setSelectedFloor: (data) => {
            dispatch(action.setSelectedFloor.success(data))
        },
    }
}
const mapStateToProps = (state) => {
    return {
        project: state.project.selectedProject,
        listBuildings: state.building.listBuildings,
        isFetching: state.building.isFetching,
        selectedBuilding: state.building.selectedBuilding,
        floorLink: state.floor.selectedFloor?.list_floor_link,
        selectedFloor: state.floor.selectedFloor,
        listFloor: state.floor.listFloor,

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewProjectMobile)
