/** @format */

import {takeLatest, put, select} from '@redux-saga/core/effects'
import * as actionType from '../constants/actionType'
import * as actions from '../actions'
import {
    getApartmentLayoutAll,
    porfoliosApi,
    deleteApartmentLayoutApi,
    updateStatusApartmentLayoutApi,
    addApartmentLayoutApi,
    getFloorLink,
    generateSlugApi,
    getLayoutByBuildingApi,
    updateApartmentLayoutApi,
    updateLayoutDrawingAPi,
    layoutDrawingDetail,
    addLayoutDrawings,
    getPortFoliosByLayoutDrawings
} from '../../api/apartmentLayoutApi'
import * as floorLinkApi from '../../api/floorLinkApi';
// import {removeVietnameseTones} from '../../utils/validateSlug'

function* getApartmentLayoutList({payload}) {
    try {
        const res = yield getApartmentLayoutAll(
            null,
            {},
            // { "_sort": 'updatedAt:DESC', "page": payload.page, "pageSize": payload.limit },
            {_sort: 'name:ASC', page: payload.page, pageSize: 5},
        )
        let data = res.results.map((item, idx) => {
            return {
                index: payload.page * 5 - 5 + idx + 1,
                id: item?.id,
                name: item?.name,
                buildingId: item?.building?.id,
                building: item?.building?.name,
                project: item?.project?.name,
                floors: item?.floors,
                styleApartment: item?.layout_type?.name,
                size: item?.acreage,
                status: item?.published_at ? 'publish' : 'unpublish',
            }
        })
        yield put(
            actions.getApartmentLayout.success({
                listLayouts: data,
                layoutData: res.results,
                totalPage: res.pagination.pageCount,
                page: payload.page,
                limit: payload.limit,
            }),
        )
    } catch (error) {
        yield put(actions.getApartmentLayout.failure({message: error.message}))
    }
}

function* getLayoutByBuildingList({payload}) {
    try {
        const res = yield getLayoutByBuildingApi(
            null,
            {},
            {
                building: `${payload.id}`,
                page: payload.page,
                pageSize: payload.limit ? payload.limit : 5,
                _sort: 'name:ASC',
            },
        )
        let data = res.results.map((item, idx) => {
            return {
                index: payload.page * 5 - 5 + idx + 1,
                id: item?.id,
                name: item?.name,
                buildingId: item?.building?.id,
                building: item?.building?.name,
                project: item?.project?.name,
                floors: item?.floors,
                styleApartment: item?.layout_type?.name,
                size: item?.acreage,
                status: item?.published_at ? 'publish' : 'unpublish',
            }
        })
        yield put(
            actions.getLayoutByBuilding.success({
                LayoutBuilding: data,
                layoutData: res.results,
                totalPage: res.pagination.pageCount,
                page: payload.page,
            }),
        )
    } catch (error) {
        yield put(actions.getLayoutByBuilding.failure({message: error.message}))
    }
}

function* getApartmentDetail({payload}) {
    try {
        var res = yield getApartmentLayoutAll(null, {id: payload?.id})
        if (res?.list_floor_link) {
            var listFloors = yield getFloorLink(
                {listFloorLinkId: res?.list_floor_link?.id},
                null,
                null,
            )
        }
        if (res?.layout_drawing) {
            var portfolios = yield porfoliosApi(
                null,
                {id: res?.layout_drawing?.id},
                null,
            )
        }
        yield put(
            actions.getApartmentLayoutDetail.success({
                layoutDetail: res,
                listFloors: listFloors,
                portfolios: portfolios,
            }),
        )
        yield put(actions.getApartmentLayoutDetail.success({layoutDetail: res}))
    } catch (error) {
        yield put(
            actions.getApartmentLayoutDetail.failure({message: error.message}),
        )
    }
}

function* addApartmentLayout({payload}) {
    // const name = payload?.data?.name
    // const validate = yield removeVietnameseTones(name)
    try {
        const code = 'layout_all'
        const name = payload?.data?.name
        const project = payload?.data?.project?.id
        const building = payload?.data?.building?.id
        const acreage = Number(payload?.data?.acreage)
        const layout_type = payload?.data?.layoutType?.id
        const layoutDrawing = payload.data?.layoutDrawing?.id
        // const idImage = payload.feautured_image?.map((item) => item.id)
        const idImage = payload.feautured_image?.id
        const porfolios = payload.data?.portfolios?.map((item) => item.id)
        const res = yield generateSlugApi(
            // {
            //   contentTypeUID: 'application::layouts.layouts',
            //   value: `${validate.replace(/\s/g, '')}`,
            //   field: 'slug',
            // },
            {
                contentTypeUID: 'application::layouts.layouts',
                data: {name: `${name}`},
                field: 'slug',
            },
            null,
            null,
        )
        const slug = res?.data
        const layoutData = yield addApartmentLayoutApi(
            {name, project, building, slug, acreage, layout_type, code},
            null,
            null,
        )
        const layouts = yield layoutDrawingDetail(null, {layoutDrawing}, null)
        const LayoutId = layouts?.layouts.map((item) => item._id)
        LayoutId.push(layoutData._id)
        const dataDrawing = yield updateLayoutDrawingAPi(
            {
                feautured_image: `${idImage}`,
                id: layoutDrawing,
                layouts: LayoutId,
                portfolios: porfolios,
            },
            {layoutDrawing},
            {},
        )
        // const selStore = yield select((state) => {
        //   return {
        //     page: state.apartmentLayout.page,
        //     limit: state.apartmentLayout.limit,
        //   };
        // })
        // console.log("🚀 ~ file: apartmentLayoutSaga.js ~ line 179 ~ selStore ~ selStore", selStore)
        if (dataDrawing.error || layoutData.error || res.error) {
            yield put(
                actions.addApartmentLayout.failure({
                    message: dataDrawing || layoutData || res.message,
                }),
            )
        } else {
            yield put(actions.addApartmentLayout.success({message: 'add success'}))
            // if (payload?.data?.idBuilding) {
            //   yield put(
            //     actions.getLayoutByBuilding.request({ building: payload?.data?.idBuilding, page: selStore.page, pageSize: selStore.limit,_sort: 'updatedAt:DESC' }),
            //   );
            // }
        }
        //   else {
        //     yield put(actions.getApartmentLayout.request({page: selStore.page, pageSize: selStore.limit,_sort: 'updatedAt:DESC'}));
        //   }
        // }
    } catch (error) {
        yield put(actions.addApartmentLayout.failure({message: error.message}))
    }
}

function* updateApartmentLayout({payload}) {
    try {
        const id = payload.id
        const code = 'layout_all'
        const name = payload?.data?.name
        const project = payload?.data?.project?.id
        const building = payload?.data?.building?.id
        const acreage = Number(payload?.data?.acreage)
        const layout_type = payload?.data?.layoutType?.id
        const layoutDrawing = payload.data?.layoutDrawing?.id
        const idImage = payload.feautured_image?.id
        const porfolios = payload.data?.portfolios?.map((item) => item.id)
        const slug = payload.slug
        const layouts = yield layoutDrawingDetail(null, {layoutDrawing}, null)
        const LayoutId = layouts?.layouts.map((item) => item._id)
        LayoutId.push(id)
        const layoutData = yield updateApartmentLayoutApi(
            {name, project, building, slug, acreage, layout_type, code},
            {id},
            null,
        )
        const dataDrawing = yield updateLayoutDrawingAPi(
            {
                feautured_image: `${idImage}`,
                id: layoutDrawing,
                layouts: LayoutId,
                portfolios: porfolios,
            },
            {layoutDrawing},
            null,
        )
        if (dataDrawing.error || layoutData.error) {
            yield put(
                actions.updateApartmentLayout.failure({
                    message: dataDrawing || layoutData,
                }),
            )
        } else {
            yield put(
                actions.updateApartmentLayout.success({message: 'update success'}),
            )
            if (payload?.data?.idBuilding) {
                yield put(actions.getApartmentLayoutDetail.request({id: id}))
                // yield put(
                //   actions.getLayoutByBuilding.request(payload?.data?.idBuilding),
                // );
            } else {
                yield put(actions.getApartmentLayoutDetail.request({id: id}))
                // yield put(actions.getApartmentLayout.request());
            }
        }
    } catch (error) {
        yield put(actions.updateApartmentLayout.failure({message: error.message}))
    }
}

function* deleteApartmentLayout({payload}) {
    try {
        const res = yield deleteApartmentLayoutApi({ids: payload.ids}, {}, {})
        if (res.error) {
            yield put(actions.deleteApartmentLayout.failure({message: res.message}))
        } else {
            yield put(
                actions.deleteApartmentLayout.success({message: 'xóa thành công'}),
            )
            var selStore = yield select((state) => {
                return {
                    page: state.apartmentLayout.page,
                    textSearchApartment: state.apartmentLayout.textSearchApartment,
                }
            })
            if (selStore?.textSearchApartment) {
                // if (payload?.idGet) {
                //   yield put(actions.getLayoutByBuilding.request(null, {}, { id: payload.idGet, page: selStore.page, pageSize: selStore.limitPage }));
                // } else if (payload?.idsLayout) {
                yield put(
                    actions.searchFilterApartmentLayout.request({
                        name: selStore?.textSearchApartment?.name,
                        nameBuilding: selStore?.textSearchApartment?.building?.name,
                        project: selStore?.textSearchApartment?.project?.name,
                        nameStyleApartment:
                        selStore?.textSearchApartment?.styleApartment?.name,
                        page: selStore?.textSearchApartment.page,
                        // pageSize: selStore.limitPage
                    }),
                )
                // }
            } else {
                if (payload?.idGet) {
                    yield put(
                        actions.getLayoutByBuilding.request({
                            id: payload.idGet,
                            page: selStore.page,
                            pageSize: selStore.limitPage,
                        }),
                    )
                } else if (payload?.idsLayout) {
                    yield put(
                        actions.getApartmentLayout.request({
                            id: payload?.idsLayout,
                            page: selStore.page,
                            pageSize: selStore.limitPage,
                        }),
                    )
                }
                //remove layout in list_floor_link by updating list_floor_link
                const arrListFloorLink = yield floorLinkApi.getFloorLink(
                    {
                        buildingId: payload?.idGet,
                    },
                    null,
                    null)
                arrListFloorLink.forEach(async element => {
                    const newLayoutCoordinates = element.layoutCoordinates.filter(layout => {
                        return layout.layouts.length > 0
                    });
                    element.layoutCoordinates = newLayoutCoordinates
                    await floorLinkApi.updateFloorLink(
                        {
                            ...element,
                            buildingId: payload?.idGet,
                        },
                        {id: element.id},
                        null,
                    )
                });
            }
        }
    } catch (error) {
        yield put(actions.deleteApartmentLayout.failure({message: error.message}))
    }
}

function* updateStatusApartmentLayout({payload}) {
    let token =
        'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJVc2VybmFtZSI6IkZsZXhob21lQWRtaW4iLCJleHAiOjE2MzM1NzIyMjYsImlhdCI6MTYzMzU3MjIyNn0.Vp1g8LcrJg1yr9vt08yrXYn2R4q4jiPJ0Lfz4h1woU4'
    try {
        const res = yield updateStatusApartmentLayoutApi(
            {token: `${token}`, ids: payload.ids, status: payload.status},
            null,
            null,
        )
        if (res.error) {
            yield put(actions.updateApartmentLayout.failure({message: res.message}))
        } else {
            yield put(
                actions.statusApartmentLayout.success({
                    message: 'update status success',
                }),
            )
            var selStore = yield select((state) => {
                return {
                    page: state.apartmentLayout.page,
                    textSearchApartment: state.apartmentLayout.textSearchApartment,
                }
            })
            if (selStore?.textSearchApartment) {
                yield put(
                    actions.searchFilterApartmentLayout.request({
                        name: selStore?.textSearchApartment?.name,
                        nameBuilding: selStore?.textSearchApartment?.building?.name,
                        project: selStore?.textSearchApartment?.project?.name,
                        nameStyleApartment:
                        selStore?.textSearchApartment?.styleApartment?.name,
                        page: selStore?.textSearchApartment.page,
                    }),
                )
            } else {
                if (payload?.idGetDetail) {
                    yield put(
                        actions.getApartmentLayoutDetail.request({
                            id: payload.idGetDetail,
                        }),
                    )
                }
                if (payload?.idGet) {
                    yield put(
                        actions.getLayoutByBuilding.request({
                            id: payload.idGet,
                            page: selStore.page,
                        }),
                    )
                }
                if (payload?.idGetApart) {
                    yield put(
                        actions.getApartmentLayoutDetail.request({
                            id: payload.idGetApart,
                        }),
                    )
                } else if (payload?.idsLayout) {
                    yield put(
                        actions.getApartmentLayout.request({
                            page: selStore.page,
                            pageSize: selStore.limit,
                        }),
                    )
                }
            }
        }
    } catch (error) {
        yield put(actions.updateApartmentLayout.failure({message: error.message}))
    }
}

function* handleGetPortFoliosByLayoutDrawings({payload}) {
    try {
        const res = yield getPortFoliosByLayoutDrawings(null, payload, null)
        if (res.error) throw res
        yield put(
            actions.getPortFoliosByLayoutDrawings.success({
                portfolios: res,
            }),
        )
    } catch (error) {
        yield put(
            actions.getPortFoliosByLayoutDrawings.failure({message: error.message}),
        )
    }
}

function* addListLayoutDrawing({payload}) {
    // console.log(payload, 'log dữ leieuj detaillll layout Drawings');
    try {
        const layoutDrawing = yield addLayoutDrawings(
            {feautured_image: payload?.url, search_name: payload?.name},
            null,
            null,
        )
        if (layoutDrawing?.error) {
            yield put(
                actions.addLayoutDrawing.failure({message: layoutDrawing?.error}),
            )
        } else {
            yield put(actions.addLayoutDrawing.success({message: 'add success'}))
        }
    } catch (error) {
        yield put(actions.addLayoutDrawing.failure({message: error.message}))
    }
}

const apartmentLayoutSaga = [
    takeLatest(actionType.GET_APARTMENTLAYOUT.REQUEST, getApartmentLayoutList),
    takeLatest(actionType.GET_APARTMENTDETAIL.REQUEST, getApartmentDetail),
    takeLatest(actionType.ADD_APARTMENTLAYOUT.REQUEST, addApartmentLayout),
    takeLatest(actionType.UPDATE_APARTMENTLAYOUT.REQUEST, updateApartmentLayout),
    takeLatest(actionType.DELETE_APARTMENTLAYOUT.REQUEST, deleteApartmentLayout),
    takeLatest(actionType.ADD_LAYOUTDRAWING.REQUEST, addListLayoutDrawing),
    takeLatest(
        actionType.GET_LAYOUT_BY_BUILDING.REQUEST,
        getLayoutByBuildingList,
    ),
    takeLatest(
        actionType.STATUS_APARTMENTLAYOUT.REQUEST,
        updateStatusApartmentLayout,
    ),
    takeLatest(
        actionType.GET_PORTFOLIOS_BY_LAYOUTDRAWING.REQUEST,
        handleGetPortFoliosByLayoutDrawings,
    ),
]
export default apartmentLayoutSaga
