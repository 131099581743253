/** @format */

import * as actionType from '../constants/actionType';

const DEFAULT_STATE = {
  listFloorLink: [],
  isLoading: false,
  error: false,
  message: '',
  errMsg: '',
  ssMsg: '',
};

const floorLinkReducer = (state = DEFAULT_STATE, action) => {
  let { type, payload } = action;
  switch (type) {
    case actionType.GET_FLOOR_LINK.REQUEST:
    case actionType.UPDATE_FLOOR_LINK.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: '',
      };

    case actionType.UPDATE_FLOOR_LINK.SUCCESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: '',
        errMsg: '',
        ssMsg: 'Update success !',
      };
    case actionType.GET_FLOOR_LINK.SUCCESS:
      return {
        ...state,
        isFetching: false,
        listFloorLink: payload.res,
      };

    case actionType.GET_FLOOR_LINK.FAILURE:
    case actionType.UPDATE_FLOOR_LINK.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        ssMsg: '',
        errMsg: payload.message,
        message: payload.message,
      };

    default:
      return state;
  }
};

export default floorLinkReducer;
