/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Box, Paper} from '@material-ui/core'
import DesignSlider from '../../../../components/Slider/DesignSlider'
import {
    ApartmentHeader,
} from './ViewApartmentFragments.jsx'
import ApartmentPreview from '../../../../components/Preview/ApartmentPreview'
import useStyles from './ViewApartmentStyles'
import {getPortFoliosByLayoutDrawings} from '../../../../redux/actions'
import {handleGetPortFoliosByLayoutDrawings, handleGetPortFoliosCurrent} from "../../../../utils/featchDataInitServer";
import {useHistory} from "react-router-dom";
import {isWindow} from "../../../../utils/help";

function ViewApartment(props) {
    const {
        getPortFoliosByLayoutDrawings,
        portfolios,
        isLoading,
        listApartments,
        projectSlug,
        buildingSlug,
        floorSlug,
        apartmentSlug,
        portfolioSlug,
        setShowModal,
        matchMobile,
        domainAvail
    } = props
    const classes = useStyles()
    const [portfolioCurrent, setPortfolioCurrent] = useState({})
    const [apartmentCurrent, setApartmentCurrent] = useState({})
    const [currentIndex, setCurrentIndex] = useState(0);

    const history = useHistory()

    useEffect(() => {
            handleGetPortFoliosByLayoutDrawings(apartmentSlug, listApartments)
                .then(res => {
                    if (res?.setApartmentCurrent) setApartmentCurrent(res.setApartmentCurrent)
                    if (res?.portfolios) getPortFoliosByLayoutDrawings(res.portfolios)
                })
    }, [apartmentSlug, listApartments])

    useEffect(() => {
        handleGetPortFoliosCurrent(portfolioSlug, portfolios).then(res => {
            if (res?.history && isWindow) {
                history.push(`${window.location.pathname}/${res.history}`)
            }
            if (res?.setPortfolioCurrent) setPortfolioCurrent(res.setPortfolioCurrent)
        })
    }, [portfolios])

    useEffect(() => {
        if (portfolioCurrent?.slug) {
            history.push(`/view/${projectSlug}/${buildingSlug}/${floorSlug}/${apartmentSlug}/${portfolioCurrent?.slug}`)
            const index = portfolios?.findIndex(({id}) => id === portfolioCurrent?.id)
            if (index >= 0) setCurrentIndex(index)
        }

    }, [portfolioCurrent])

    return (
        <Paper>
            <Box className={classes.container}>
                {(
                    <Box style={{
                        display: "flex",
                    }}>
                        <Box
                            className={classes.leftItem}
                        >
                            <ApartmentHeader
                                projectSlug={projectSlug}
                                buildingSlug={buildingSlug}
                                floorSlug={floorSlug}
                                apartmentSlug={apartmentSlug}
                                listApartment={listApartments}
                                apartmentCurrent={apartmentCurrent}
                                setApartmentCurrent={setApartmentCurrent}
                                buildingName={props.selectedBuilding?.name}
                                projectName={props.project?.name}
                                floorName={props.selectedFloor.label}
                            />
                            <DesignSlider
                                matchMobile={matchMobile}
                                apartmentCurrent={apartmentCurrent}
                                setPortfolioCurrent={setPortfolioCurrent}
                                currentIndex={currentIndex}
                                setCurrentIndex={setCurrentIndex}
                                portfolios={portfolios}
                            />
                        </Box>
                        <Box className={classes.rightItem}>
                            <ApartmentPreview
                                domainAvail={domainAvail}
                                matchMobile={matchMobile}
                                apartmentSlug={apartmentSlug}
                                setShowModal={setShowModal}
                                apartmentCurrent={apartmentCurrent}
                                portfolioCurrent={portfolioCurrent}
                            />
                        </Box>
                    </Box>
                )}
            </Box>

        </Paper>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPortFoliosByLayoutDrawings: (data) => {
            dispatch(getPortFoliosByLayoutDrawings.success(data))
        },
    }
}
const mapStateToProps = (state) => {
    return {
        portfolios: state.apartmentLayoutClient.portfolios,
        isLoading: state.apartmentLayoutClient.isLoading,
        resetState: state.apartmentLayoutClient.resetState,
        listApartments: state.floor.listApartments,
        selectedBuilding: state.building.selectedBuilding,
        project: state.project.selectedProject,
        selectedFloor: state.floor.selectedFloor,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewApartment)

