import {isEmpty} from "lodash"

export const substringCustom = (text, MAX_LENGTH) => {
    return (
        text?.length > MAX_LENGTH ?
            `${text.substring(0, MAX_LENGTH)}...`
            :
            text
    )
}
export const isNotEmptyObject = (obj) => {
    if (typeof obj === "undefined") {
        return false
    } else if (typeof obj === "object" && isEmpty(obj)) {
        return false
    } else if (obj === null || obj === "") {
        return false
    } else return !(typeof obj === "boolean" && !obj);
};

export const isWindow = Boolean(typeof window !== "undefined");
export const isDocument = Boolean(typeof document !== "undefined");