/** @format */

import { DOMAIN } from '../redux/constants/actionType'
import configApi from '../utils/configApi'
import LocalWorker from './../utils/localWorker'
const localWorker = LocalWorker()
const token = localWorker.getToken()

export const getGroupDetailByBuilding = configApi({
  method: 'GET',
  baseUrl: DOMAIN,
  path: 'list-groups',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
})

export const addGroup = configApi({
  method: 'POST',
  baseUrl: DOMAIN,
  path: 'list-groups',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
})

export const updateGroup = configApi({
  method: 'POST',
  baseUrl: DOMAIN,
  // path: 'list-groups',
  path: 'list-floors/updateAuto',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
})
export const updateGroupFloor = configApi({
  method: 'PUT',
  baseUrl: DOMAIN,
  path: 'list-groups',
  // path: 'list-floors',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
})

export const deleteGroup = configApi({
  method: 'POST',
  baseUrl: DOMAIN,
  path: 'list-groups/custom-remove',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
})

export const removeGroupFloor = configApi({
  method: 'POST',
  baseUrl: DOMAIN,
  path: 'list-floors/removeFromGroupAuto',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
})
