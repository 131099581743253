/** @format */

import React from 'react'
import {
    Box,
    makeStyles,
    Button,
    Typography,
    TextField, Divider,
} from '@material-ui/core'
import {Autocomplete} from '@material-ui/lab'
// import useStyles from './ViewApartmentMobileHeaderStyles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {useHistory} from "react-router-dom";
import {isNotEmptyObject, substringCustom} from "../../../../utils/help";
// import { KeyboardBackspace } from '@material-ui/icons';
const ViewApartmentMobilePreview = (props) => {
    const {
        buildingName,
        projectName,
        setApartmentCurrent,
        apartmentCurrent,
        listApartment,
        selectedFloor,
        projectSlug,
        buildingSlug,
        floorSlug
    } = props
    const classes = useStyles()
    const history = useHistory()
    const MAX_LENGTH = 10
    return (
        <Box className={classes.container}>
            <Box className={classes.navBar}>
                <div
                    className={classes.backBtn}
                    onClick={() => history.push(`/view/${projectSlug}/${buildingSlug}/${floorSlug}`)
                    }
                >
                    <ArrowBackIcon/>
                </div>
                <div className={classes.title}>Căn hộ</div>
                <Box className={classes.titleTop}>
                    <Autocomplete
                        className={classes.autocomplete}
                        value={apartmentCurrent}
                        options={listApartment}
                        renderInput={(params) => (
                            <TextField
                                style={{padding: '3px 0 0px !important'}}
                                className={classes.inputRoot}
                                variant="outlined"
                                {...params}
                            />
                        )}
                        getOptionLabel={(option) => option?.name}
                        size='small'
                        closeIcon=''
                        autoHighlight
                        onChange={(e, item) => {
                            setApartmentCurrent(item)
                            history.push(item?.slug)
                        }}
                    />
                </Box>
            </Box>
            <Box className={classes.titleBottom}>
                <Typography className={classes.titleText} variant='caption'>
                   Tầng {substringCustom(selectedFloor?.label, MAX_LENGTH)}
                </Typography>
                <Divider
                    orientation='vertical'
                    flexItem
                    className={classes.divideTitle}
                />
                <Typography className={classes.titleText} variant='caption'>
                   Tòa {substringCustom(buildingName, MAX_LENGTH)}
                </Typography>
                <Divider
                    orientation='vertical'
                    flexItem
                    className={classes.divideTitle}
                />
                <Typography className={classes.titleText} variant='caption'>
                   Dự án {substringCustom(projectName, MAX_LENGTH)}
                </Typography>
            </Box>
        </Box>

    )
}

export default ViewApartmentMobilePreview

const useStyles = makeStyles((theme) => {
    return {
        titleTop: {
            width: "60%",
        },
        container: {
            paddingTop: 56,
            paddingBottom: 24,
        },
        divideTitle: {
            backgroundColor: '#C1C1C1',
            color: "#C1C1C1",
            margin: `0 5px`,
            height: 12,
            alignSelf: "center"

        },
        titleText: {
            fontWeight: 600,
            color: '#6A6A6A',
            fontSize: 10,
        },
        navBar: {
            display: 'flex',
            justifyContent: "center",
            alignItems: "center"
        },
        backBtn: {
            '&.MuiButton-root': {
                backgroundColor: 'white',
                borderRadius: '8px',
                borderColor: theme.palette.secondary.dark,
            },
        },
        title: {
            padding: "0 8px",
            fontSize: 14,
            fontWeight: 600,
            color: "#000000"

            // marginLeft: theme.spacing(2),
            // display: 'flex',
            // flexDirection: 'column',
            // justifyContent: 'space-between',
        },
        titleBottom: {
            display: 'flex',
            marginTop: 8,
            justifyContent: "center"
        },
        autocomplete: {
            background: "#FDFDFD",
            // border: "1px solid #C1C1C1",
            // boxSizing: "border-box",
            // borderRadius: 5,

            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                padding: "4px 16px",
                width: "100%",
                fontSize: 16,
                fontWeight: 600,
                color: "#00030E"
            }
        },
        inputRoot: {},

    }
})
