/** @format */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Box, makeStyles, useMediaQuery, useTheme} from '@material-ui/core'
import ViewProject from './Destop/Project/ViewProject'
import ViewApartment from './Destop/Apartment/ViewApartment'
import {isDocument, isNotEmptyObject, isWindow} from "../../utils/help";
import * as action from "../../redux/actions";
import {useHistory, useParams} from "react-router-dom";
import ViewProjectMobile from "./Mobile/Project/ViewProjectMobile";
import ViewApartmentMobile from "./Mobile/Apartment/ViewApartmentMobile";
import {fetchStaffBookingApi, getDomainApi} from "../../api/bookingApi";
import ModalRegister from "../../components/common/Modal/ModalRegister";
import {SharedBox2} from "../../components/Sidebar/ProjectSidebarFragments";
import {handleGetInit, handleGetListFloorByBuildingId, handleGetListLayout} from "../../utils/featchDataInitServer";

const PARENT_URL = isWindow ? (new URL((window.location != window.parent.location)
    ? document.referrer
    : document.location.href))?.origin : undefined;

function ViewBase(props) {
    const {
        project,
        selectedBuilding,
        setSelectedBuilding,
        selectedFloor,
        floorLink,
        getListLayouts,
        setSelectedFloor,
        getListLayoutsFail
    } = props
    const classes = useStyles()
    const history = useHistory()
    const {projectSlug, buildingSlug, floorSlug, apartmentSlug, portfolioSlug} = useParams()
    const theme = useTheme()
    const matchMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [showModal, setShowModal] = useState(false)
    const [domainAvail, setDomainAvail] = useState(true)
    const [staffs, setStaffs] = useState([])
    const [staffCurrent, setStaffCurrent] = useState({})

    const handleShowModalRegister = async (value) => {
        setStaffCurrent(staffs[Math.floor(Math.random() * staffs.length)])
        setShowModal(value)
    }
    const handleGetStaffs = async () => {
        try {
            const _staffs = await fetchStaffBookingApi()
            if (_staffs)
                setStaffCurrent(_staffs[Math.floor(Math.random() * _staffs.length)])
            setStaffs(_staffs)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (isDocument) {
            document.title = `Flexhome | Dự án ${project?.name || ''} `
        }
    }, [project])

    /* const handleGetListFloorByBuildingId = async (building) => {
         try {
             const listFloor = await floorApi.getFloorListByBuilding(null, null, {
                 building: building?.id,
             })
             if (listFloor.error) throw listFloor

             listFloor.sort((a, b) => {
                 if (+a.label.split(' ')[1] > +b.label.split(' ')[1] || +a.label > +b.label) {
                     return 1
                 }
                 if (+a.label.split(' ')[1] < +b.label.split(' ')[1] || +a.label < +b.label) {
                     return -1
                 }
                 return 0
             })
             if (listFloor?.length > 0) {
                 await props.getListFloorByBuildingSuccess({listFloor})
                 if (floorSlug) {
                     const index = listFloor.findIndex(
                         ({floorID, published_at}) => floorID === Number(floorSlug) && isNotEmptyObject(published_at),
                     )
                     if (index >= 0) {
                         setSelectedFloor(listFloor[index])
                     }
                 } else {
                     const index = listFloor.findIndex(
                         ({
                              list_floor_link,
                              published_at
                          }) => isNotEmptyObject(published_at) && isNotEmptyObject(list_floor_link?.layoutCoordinates),
                     )
                     if (index >= 0) {
                         setSelectedFloor(listFloor[index])
                         if (matchMobile) {
                             history.push(`/view/${projectSlug}/${building?.slug}`)
                         } else {
                             history.push(`/view/${projectSlug}/${building?.slug}/${listFloor[index]?.floorID}`)
                         }
                     }
                 }
             } else {
                 alert("toa nha Khong co tang")

             }
         } catch (error) {
             props.getListFloorByBuildingFailure(error)
         }
     }

     const handleGetInit = async () => {
         if (projectSlug) {
             try {
                 const res = await fetchProjectDetailByClient(
                     {
                         slug: projectSlug,
                     },
                     null,
                     null,
                 )
                 let data = {}
                 if (res?.published_at === null) {
                     data = null
                     alert('Project not found!!')
                 } else {
                     data = res
                     data.buildings = data?.buildings?.filter((item, idx) => {
                         return item?.published_at !== null
                     })
                 }
                 props.setProjectDetail({project: data})
                 const _listBuildings = data?.buildings
                 if (_listBuildings?.length > 0) {
                     if (buildingSlug) {
                         const index = _listBuildings.findIndex(
                             ({slug, published_at}) => isNotEmptyObject(published_at) && slug === buildingSlug,
                         )
                         if (index >= 0) {
                             setSelectedBuilding(_listBuildings[index])
                         }
                     } else {
                         const index = _listBuildings.findIndex(
                             ({
                                  slug,
                                  published_at,
                                  list_floors
                              }) => isNotEmptyObject(published_at) && isNotEmptyObject(slug) && isNotEmptyObject(list_floors),
                         )
                         if (index >= 0) {
                             history.push(`${isWindow && window.location.pathname}/${_listBuildings[index]?.slug}`)
                             setSelectedBuilding(_listBuildings[index])
                         }
                     }
                 }
             } catch (error) {
                 props.setProjectDetailFail({message: error.message})
             }
         } else {
             history.push(`${isWindow && window.location.pathname}/notFound`)
         }
     }

     const handleGetListLayout = async (queries) => {
         // fetchApi
         try {

             // fetchApi
             let res = await getFloorDetailByClient(
                 null,
                 null,
                 queries,
             )
             if (res.error) throw res
             getListLayouts({
                 listApartments: res.filter((items) => items?.published_at)
             })
         } catch (error) {
             getListLayoutsFail(error)
         }
     }*/

    const handleCheckDomain = async () => {
        const check = await getDomainApi(null, null, {name_contains: PARENT_URL})
        if (isNotEmptyObject(check)) {
            setDomainAvail(check[0]?.is_publish)
        } else {
            const domainDefault = await getDomainApi(null, null, {name: "Mặc định"})
            if (isNotEmptyObject(domainDefault)) {
                setDomainAvail(domainDefault[0]?.is_publish)
            } else {
                setDomainAvail(false)
            }
        }
    }

    useEffect(() => {
        handleGetStaffs()
        if (projectSlug ) {
            handleGetInit(projectSlug, buildingSlug)
                .then(res => {
                    if (res?.setProjectDetail) props.setProjectDetail(res?.setProjectDetail)
                    if (res?.setSelectedBuilding) setSelectedBuilding(res.setSelectedBuilding)
                    if (res?.setProjectDetailFail) props.setProjectDetailFail(res.setProjectDetailFail)
                    // if (res?.history) history.push(res.history)
                })
        }
        handleCheckDomain()
    }, [])

    useEffect(() => {
        if (isNotEmptyObject(selectedBuilding)  ) {
            handleGetListFloorByBuildingId(selectedBuilding, projectSlug, floorSlug, matchMobile)
                .then(res => {
                    if (res?.getListFloorByBuildingSuccess) props.getListFloorByBuildingSuccess(res.getListFloorByBuildingSuccess)
                    if (res?.setSelectedFloor) setSelectedFloor(res.setSelectedFloor)
                    if (res?.history) history.push(res.history)
                })
        }
    }, [selectedBuilding])

    useEffect(() => {
        if (isNotEmptyObject(selectedFloor?.list_floor_link?.layoutCoordinates) ) {
            handleGetListLayout({id_in: selectedFloor.list_floor_link.layoutCoordinates.map(({layout}) => layout?.id)})
                .then(res => {
                    if (res?.getListLayouts) getListLayouts(res.getListLayouts)
                    if (res?.getListLayoutsFail) getListLayoutsFail(res.getListLayoutsFail)
                })
        }
    }, [selectedFloor])

    return (
        <Box>
            <Box className={classes.containerAnimation}>
                <Box className={classes.viewBase}>
                    {matchMobile
                        ? (
                            <div>
                                {apartmentSlug
                                    ? <ViewApartmentMobile
                                        projectName={project?.name}
                                        buildingName={selectedBuilding?.name}
                                        projectSlug={projectSlug}
                                        buildingSlug={buildingSlug}
                                        floorSlug={floorSlug}
                                        apartmentSlug={apartmentSlug}
                                        floorLink={floorLink}
                                        setShowModal={handleShowModalRegister}
                                    />
                                    : <ViewProjectMobile
                                        setShowModal={handleShowModalRegister}
                                        projectSlug={projectSlug}
                                        buildingSlug={buildingSlug}
                                        floorSlug={floorSlug}
                                    />}
                                <Box>
                                    <SharedBox2
                                        domainAvail={domainAvail}
                                        matchMobile={matchMobile}
                                        apartmentSlug={apartmentSlug}
                                        floorSlug={floorSlug}
                                        setShowModal={setShowModal}
                                        data={project}
                                        layout={{}}
                                    />
                                </Box>
                            </div>
                        )
                        : (<Box className={"d-none-mobile"}>
                            {apartmentSlug
                                ? <ViewApartment
                                    domainAvail={domainAvail}
                                    projectSlug={projectSlug}
                                    buildingSlug={buildingSlug}
                                    floorSlug={floorSlug}
                                    apartmentSlug={apartmentSlug}
                                    portfolioSlug={portfolioSlug}
                                    setShowModal={setShowModal}
                                    matchMobile={matchMobile}
                                />
                                : <ViewProject
                                    domainAvail={domainAvail}
                                    showModal={showModal}
                                    setShowModal={setShowModal}
                                    staffCurrent={staffCurrent}
                                    handleShowModalRegister={handleShowModalRegister}
                                    projectSlug={projectSlug}
                                    buildingSlug={buildingSlug}
                                    apartmentSlug={apartmentSlug}
                                    // apartmentLoading={apartmentLoading}
                                />
                            }
                        </Box>)
                    }
                    {Boolean(showModal && domainAvail) &&
                        <ModalRegister
                            matchMobile={matchMobile}
                            domain={PARENT_URL}
                            project={project}
                            selectedBuilding={selectedBuilding}
                            apartmentSlug={apartmentSlug}
                            listApartments={props?.listApartments}
                            show={showModal}
                            staff={staffCurrent}
                            handleClose={setShowModal}
                            fullWidth={false}
                        />
                    }
                </Box>
            </Box>
        </Box>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        getListLayouts: (data) => dispatch(action.getFloorDetailByClient.success(data)),
        getListLayoutsFail: (data) => dispatch(action.getFloorDetailByClient.failure(data)),
        setProjectDetail: (data) =>
            dispatch(action.getProjectDetailByClient.success(data)),
        setProjectDetailFail: (data) =>
            dispatch(action.getProjectDetailByClient.failure(data)),
        getListFloorByBuildingSuccess: (data) => {
            dispatch(action.getListFloorByBuilding.success(data))
        },
        getListFloorByBuildingFailure: (data) => {
            dispatch(action.getListFloorByBuilding.failure(data))
        },
        setSelectedBuilding: (data) => {
            dispatch(action.setSelectedBuilding.success(data))
        },
        setSelectedFloor: (data) => {
            dispatch(action.setSelectedFloor.success(data))
        },
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project.selectedProject,
        listBuildings: state.building.listBuildings,
        selectedBuilding: state.building.selectedBuilding,
        selectedFloor: state.floor.selectedFloor,
        floorLink: state.floor.selectedFloor?.list_floor_link,
        listFloor: state.floor.listFloor,
        listApartments: state.floor.listApartments,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewBase)

const useStyles = makeStyles((theme) => ({
    viewBase: {

        "& .MuiPaper-elevation1 ": {
            boxShadow: "unset"
        }
    },
    apartmentViewWrap: {
        [theme.breakpoints.down(426)]: {
            zIndex: 200,
            position: 'fixed',
            top: 0,
            right: 0,
            width: 'calc(100% - 20px)',
            transform: `translateX(calc(100% + 20px))`,
            transition: '300ms',
        },
        zIndex: 200,
        position: 'fixed',
        top: 0,
        right: 0,
        width: 'calc(100% - 100px)',
        transform: `translateX(calc(100% + 100px))`,
        transition: '300ms',
        '&.show': {
            transform: `translateX(0)`,
        },
    },
    toggleView: {
        position: 'absolute',
        backgroundColor: '#ffffff',
        zIndex: 100,
        top: '45vh',
        right: isWindow ? window.innerHeight * (90 / 75) + window.innerWidth * (480 / 1920) : 968 * (90 / 75) + 480,// right: window.innerHeight * (97 / 75) + window.innerWidth * (483 / 1920),
        [theme.breakpoints.down('1350')]: {
            right: isWindow ? window.innerWidth * (274 / 1280) + window.innerHeight * (90 / 75) * 0.945 : 1920 * (274 / 1280) + 968 * (90 / 75) * 0.945
        },

        transform: `translateY(-50%)`,
        height: 100,
        clipPath: `polygon(0 20%, 100% 0, 100% 100%, 0 80%);`,
    },
    toggleViewIpad: {
        position: 'absolute',
        backgroundColor: '#ffffff',
        zIndex: 100,
        top: '45vh',
        right: (isWindow ? window.innerHeight : 968) * (97 / 75),
        transform: `translateY(-50%)`,
        height: 100,
        clipPath: `polygon(0 20%, 100% 0, 100% 100%, 0 80%);`,
    },
    toggleViewMoble: {
        position: 'absolute',
        backgroundColor: '#ffffff',
        zIndex: 100,
        top: '45vh',
        right: (isWindow ? window.innerWidth : 1920) * 0.94,
        transform: `translateY(-50%)`,
        height: 100,
        clipPath: `polygon(0 20%, 100% 0, 100% 100%, 0 80%);`,
    },
    //  animation
    containerAnimation: {},
    headerAnimation: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
    },
    contentAnimationProject: {
        position: 'relative',
        zIndex: 10,
        textTransform: 'uppercase',
        letterSpacing: '1pt',
        fontSize: '4vh',
        color: '#f2f2f2',
        textAlign: 'center',
        padding: '10px 0',
        fontWeight: '700',
        maxWidth: '80vw',
    },
    contentAnimationCDT: {
        position: 'relative',
        zIndex: 10,
        textTransform: 'uppercase',
        letterSpacing: '1pt',
        fontSize: '3vh',
        fontWeight: 500,
        color: '#f2f2f2',
        textAlign: 'center',
        padding: '10px 0',
        maxWidth: '80vw',
    },
    contentAnimationAddress: {
        position: 'relative',
        zIndex: 10,
        textTransform: 'uppercase',
        letterSpacing: '1pt',
        fontSize: '1.7vh',
        color: '#f2f2f2',
        textAlign: 'center',
        fontWeight: 300,
        padding: '20px 0',
        fontStyle: 'italic',
        maxWidth: '80vw',
    },
    buttonAnimation: {
        position: 'absolute',
        zIndex: 10,
        pointerEvents: 'auto',
        bottom: '10vh',
    },
    triangleAnimation: {
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        transition: 'all .8s cubic-bezier(0.645, 0.045, 0.355, 1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',
        flexDirection: 'column',
    },
    leftAnimation: {
        clipPath: 'polygon(0 0, 0% 100%, 100% 100%)',
        '&::before': {
            content: '""',
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 1,
            top: '0',
            left: '0',
            backgroundColor: '#00000059',
        },
    },
    rightAnimation: {
        clipPath: 'polygon(100% 0, 0 0, 100% 100%)',
        '&::before': {
            content: '""',
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 1,
            top: '0',
            left: '0',
            backgroundColor: '#00000059',
        },
    },
    leftOpenAnimation: {
        clipPath: 'polygon(0 0, 0 100%, 0 100%)',
    },
    rightOpenAnimation: {
        clipPath: 'polygon(100% 0, 100% 0, 100% 100%)',
    },
}))
