/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect} from 'react'
import {Box, makeStyles, ButtonBase} from '@material-ui/core'
import LayoutHighlight2 from '../Marker/LayoutHighlight2'
import {useTheme, useMediaQuery} from '@material-ui/core'
import {DOMAIN_IMAGE} from '../../redux/constants/actionType'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper'
import FullScreenLoading from '../common/Loading/FullScreenLoading'
import {connect} from "react-redux";
import ImageNotAvailable from '../../assets/images/v2/image-not-avalible.png'
import {useHistory} from "react-router-dom";
import {isNotEmptyObject, isWindow, substringCustom} from "../../utils/help";

const IMAGE_FRAME_DEFAULT_BE = {
    w: 700,
    h: 500,
}

function FloorFigure({
                         coordinates,
                         selectedLayout,
                         layoutSrc,
                         setSelectedLayout,
                         layoutCoordinates,
                         setLayoutPointHover,
                         handleOnClickPointHover,
                         setCoordinates,
                         listApartments,
                         projectSlug,
                         buildingSlug,
                         floorSlug,
                         apartmentSlug,
                         ...rest
                     }) {
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()
    const matchMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const matchTablet = useMediaQuery(theme.breakpoints.down('769'))
    const matchIpadPro = useMediaQuery(theme.breakpoints.down('1025'))
    const matchSmallScreen = useMediaQuery(theme.breakpoints.down('1100'))
    const matchMediumScreen = useMediaQuery(theme.breakpoints.down('1441'))
    const matchLargeScreen = useMediaQuery(theme.breakpoints.up('1441'))
    const [imageDimensions, setImageDimensions] = useState({})
    const [framesImageDefault, setFramesImageDefault] = useState({
        w: rest?.toggle ? 1230 : 1232,
        h: rest?.toggle ? 584 : 840,
    })
    const [imgSize, setImgSize] = useState({
        "w": 979.2682926829268,
        "h": 584,
        "ratio": 1.3989547038327526
    })
    const handleLoadImage = async () => {
        const img = new Image();
        img.src = await (DOMAIN_IMAGE + layoutSrc);
        img.onload = () => {
            if (img?.height && img?.width) {
                const ratio = (IMAGE_FRAME_DEFAULT_BE.w / img.width > IMAGE_FRAME_DEFAULT_BE.h / img.height) ? IMAGE_FRAME_DEFAULT_BE.h / img.height : IMAGE_FRAME_DEFAULT_BE.w / img.width
                setImageDimensions({
                    w: img.width,
                    h: img.height,
                    ratio
                });
            }
        };
        img.onerror = (err) => {
            console.log("img error");
            console.error(err);
        };
    };

    const handleResizeImage = () => {
        const _ratio = ((framesImageDefault?.w / imageDimensions?.w) > (framesImageDefault?.h / imageDimensions?.h)) ? (framesImageDefault?.h / imageDimensions?.h) : (framesImageDefault?.w / imageDimensions?.w)
        setImgSize({
            w: imageDimensions.w * _ratio,
            h: imageDimensions.h * _ratio,
            ratio: _ratio / imageDimensions?.ratio
        })
    }
    // 1: lấy kích thước frame mặc định cho từng màn hình

    useEffect(() => {
        if (matchLargeScreen) {
            setFramesImageDefault({
                w: rest?.toggle ? 1230 : 1232,
                h: rest?.toggle ? 584 : 840
            })
        }if (matchMediumScreen) {
            setFramesImageDefault({
                w: !rest?.toggle ? 1000 : 810,
                h: !rest?.toggle ? 1000 * 0.5714285715 : 810 * 0.5714285715
            })
        }  if (matchSmallScreen) {
            setFramesImageDefault({
                w: !rest?.toggle ? 1000 : 800,
                h: !rest?.toggle ? 1000 * 0.5714285715 : 800 * 0.5714285715,
            })
        } if (matchIpadPro || matchTablet || matchIpadPro) {
            setFramesImageDefault({
                w: 1000,
                h: 450,
            })
        } if (matchMobile) {
            setFramesImageDefault({
                w: 343,
                h: 227,
            })
        }

    }, [
        matchLargeScreen,
        matchTablet,
        matchMediumScreen,
        matchIpadPro,
        matchSmallScreen,
        matchMobile,
        rest?.toggle,
      isWindow && window?.screen?.width
    ])
    // 2: lấy kích thước ảnh mặc định và tỉ lệ ratio Của BE
    useEffect(() => {
        if (isNotEmptyObject(layoutSrc)) {
            handleLoadImage();
        }
    }, [layoutSrc]);

    // 3: gán kích thước và tỉ lệ mới cho view BE

    useEffect(() => {
        if (imageDimensions?.w && framesImageDefault?.w) {
            handleResizeImage();
        }
    }, [imageDimensions, framesImageDefault]);

    return (
        <Box
            className={`${classes.containerTop} ${!rest?.toggle && classes.containerTopFull}`}
            sx={{
                margin: 'auto',
                marginTop: matchMobile
                    ? 0
                    : matchTablet
                        ? (isWindow ? window.screen.width : 769)  * (150 / 768)
                        : matchIpadPro
                            ? (isWindow ? window.screen.width : 1025)  * (150 / 1024)
                            : matchSmallScreen
                                ? (isWindow ? window.screen.width : 1100)  * (100 / 1280)
                                : (isWindow ? window.screen.width : 1920)  * (55 / 1280),

            }}
        >
            <Box className={classes.figureImage}>
                <Box className={classes.imageContent}>
                    <LayoutHighlight2
                        matchMobile={matchMobile}
                        hoverAble={!(matchIpadPro || matchTablet)}
                        layoutCoordinates={layoutCoordinates}
                        setLayoutPointHover={setLayoutPointHover}
                        handleOnClickPointHover={handleOnClickPointHover}
                        image={DOMAIN_IMAGE + layoutSrc}
                        widthImg={imgSize.w}
                        heightImg={imgSize.h}
                        ratio={imgSize?.ratio}
                        readonly={true}
                        name={''}
                        selectedLayout={selectedLayout}
                        coordinateSelected={{coordinates: coordinates || []}}
                        setSelectedLayout={setSelectedLayout}
                        setCoordinates={setCoordinates}
                        listApartments={listApartments}
                        projectSlug={rest?.projectSlug}
                        floorLink={rest?.floorLink}
                    />
                </Box>

                {matchMobile
                    // && !openApartment
                    && (!rest?.apartmentLoading ? (
                        <Box>
                            <Box className={classes.container}>
                                <div className={classes.titleTop}>
                                    Xem thiết kế mẫu của loại căn hộ
                                </div>
                                <Swiper
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    spaceBetween={20}
                                    slidesPerView={1.6}
                                    slidesPerColumn={2}
                                    slidesPerColumnFill={listApartments?.length < 3 ? 'column' : 'row'}
                                >
                                    {listApartments?.length > 0 &&
                                        listApartments?.map((item, idx) => (
                                            <SwiperSlide key={idx}>
                                                <Box
                                                    className={classes.boxContent}
                                                    component={ButtonBase}
                                                    onClick={() => {
                                                        history.push(`${isWindow && window.location.pathname}/${item?.slug}`)
                                                    }}
                                                >
                                                    <Box className={classes.imageApartment}>
                                                        <img
                                                            className={classes.imageApartment}
                                                            src={
                                                                (item?.layout_drawing?.feautured_image?.url) ? DOMAIN_IMAGE + item?.layout_drawing?.feautured_image?.url : ImageNotAvailable
                                                            }
                                                            alt=''
                                                        />
                                                    </Box>
                                                    <Box className={classes.infoApartment}>
                                                        <Box className={classes.title}>
                                                            {substringCustom(item.name,10)}
                                                        </Box>
                                                        <Box className={classes.containerRight}>
                                                            {
                                                                item?.acreage &&
                                                                <div
                                                                    className={classes.itemInfo}>{item?.acreage} m2</div>
                                                            }
                                                            {
                                                                item?.layout_type?.name &&
                                                                <div className={classes.itemInfo}>
                                                                    {substringCustom(item?.layout_type?.name,18)}
                                                                </div>
                                                            }
                                                            {
                                                                item?.layout_drawing?.portfolios &&
                                                                <div className={classes.itemInfo}>
                                                                    {item?.layout_drawing?.portfolios.length + " Mẫu thiết Kế"} {' '}
                                                                </div>
                                                            }
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </SwiperSlide>
                                        ))}
                                </Swiper>
                            </Box>
                        </Box>
                    ) : (
                        <FullScreenLoading/>
                    ))}
            </Box>

        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        project: state.project.selectedProject,
        listApartments: state.floor.listApartments,
        isLoading: state.project.isLoading,
        isFetching: state.building.isFetching,
        apartmentLoading: state.floor.isLoading,
        floorLink: state.floor.selectedFloor?.list_floor_link,
    }
}

export default connect(mapStateToProps)(FloorFigure)
const useStyles = makeStyles((theme) => ({

    figureImage: {

    },
    containerTop: {
        margin: 'auto',
        marginTop: `clamp(48px, 3%, 5%)`,
        '&$containerTopFull': {
            margin: 'auto'
        }
    },
    containerTopFull: {},
    imageContent: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: "#6a6a6a87",
            padding: "16px",
            // paddingBottom: 77

        },
    },
    image: {
        maxWidth: 600,
        objectFit: 'contain',
        [theme.breakpoints.between('md', '1400')]: {
            maxWidth: 400,
        },
        [theme.breakpoints.between('1400', 'lg')]: {
            maxWidth: 600,
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: 500,
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 300,
        },
    },
    titleTop: {
        color: '#6A6A6A',
        fontSize: 14,
        fontWeight: 600,
        paddingTop: 32,
        paddingLeft: 20
    },
    container: {
        // width: '90vw',
        // margin: '20px auto',
        // position: 'relative',
        // borderRadius: 10,
        '& .swiper-wrapper': {
            padding: "16px 20px 32px 20px",
            '& .swiper-slide': {
                // padding: "15px 12px",
                //     opacity: 0.8,
                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                backgroundColor: '#FDFDFD',
                borderRadius: 10,
                //
            }
        },

    },
    boxContent: {
        width: '88%',
        padding: "15px 12px",
        // height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'

    },
    imageApartment: {
        width: 90,
        height: 90,
        borderRadius: 10,
        textAlign: 'right'
        // flex: 1,
    },
    infoApartment: {
        // flex: 2,
    },
    containerRight: {
        textAlign: "right",
        borderRadius: 10,
        justifyContent: "end",

    },
    itemInfo: {
        marginTop: 8,
        fontSize: 9,
        fontWeight: 400,
        color: "#6A6A6A"

    },
    itemLeft: {
        flex: 3,
    },
    itemRight: {
        flex: 4,
    },
    textIcon: {
        fontSize: '1.5vh',
        padding: '1px 0',
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
    },
    textIconTop: {
        fontSize: 9,
        // padding: '1px 0',
        // lineHeight: 1,
        // display: 'flex',
        // alignItems: 'center',
        // marginBottom: 5,
        color: "#6A6A6A",
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    icon: {
        fontSize: '2.5vh',
        marginRight: 8,
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            fontWeight: 600,
            paddingLeft: 10,
            textAlign: "right",

        }
    },
    apartmentName: {
        fontSize: '20px',
        fontWeight: 700,
        textAlign: 'left',
        paddingLeft: 10,
    },
    apartmentIcon: {
        fontSize: '2vh',
        fontWeight: 500,
        marginLeft: 5,
    },
    hideApartment: {
        top: '100vh',
        right: '100vw',
        position: 'relative',
        transition: '300ms',
        transform: `translateX(0)`,
    },
    showApartment: {
        top: 0,
        left: '0',
        position: 'absolute',
        transition: '300ms',
        width: '100vw',
        height: '100vh',
        transform: `translateX(0)`,
    },
}))
