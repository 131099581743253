/** @format */

import { DOMAIN } from '../redux/constants/actionType';
import configApi from '../utils/configApi';
import uselocalWorker from '../utils/localWorker';
const localWorker = uselocalWorker();
const token = localWorker.getToken();
export const fetchProjectApi = configApi({
  baseUrl: DOMAIN,
  path: 'content-manager/collection-types/application::projects.projects',
  method: 'GET',
  headers: { Authorization: `Bearer ${token}` },
});
export const fetchSlugProjectApi = configApi({
  baseUrl: DOMAIN,
  path: 'content-manager/uid/generate',
  method: 'POST',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});
export const fetchProjectDetailApi = configApi({
  baseUrl: DOMAIN,
  path: 'content-manager/collection-types/application::projects.projects',
  method: 'GET',
  headers: { Authorization: `Bearer ${token}` },
});
export const fetchDeleteProjectApi = configApi({
  baseUrl: DOMAIN,
  path: 'content-manager/collection-types/application::projects.projects/actions/bulkDelete',
  method: 'POST',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});
export const fetchUpdateStatusProjectApi = configApi({
  baseUrl: DOMAIN,
  path: 'projects/changeStatus',
  method: 'POST',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});
export const fetchCreateProjectApi = configApi({
  baseUrl: DOMAIN,
  path: 'content-manager/collection-types/application::projects.projects',
  method: 'POST',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});
export const fetchUpdateProjectApi = configApi({
  baseUrl: DOMAIN,
  path: 'content-manager/collection-types/application::projects.projects',
  method: 'PUT',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});

export const fetchProjectDetailByClient = configApi({
  baseUrl: DOMAIN,
  path: 'projects/findBySlug',
  method: 'POST',
});

export const fetchProject = configApi({
  baseUrl: DOMAIN,
  path: 'projects',
  method: 'get',
});

export const fetchBuildingsByProject = configApi({
  baseUrl: DOMAIN,
  path: 'buildings',
  method: 'GET',
});
