/** @format */

import {makeStyles} from '@material-ui/core';
import {isWindow} from "../../utils/help";

export default makeStyles((theme) => {
    return {
        floorSidebar: {
            backgroundColor: '#FAFCFF',
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            transform: `translateX(calc(100% + 100px))`,
            transition: '300ms',
            zIndex: 1,
            '&.show': {
                transform: `translateX(0)`,
            },
        },
        navBar: {
            display: 'flex',
            padding: "64px 35px",
            paddingBottom: 24
        },
        backBtn: {

        },
        titleText: {
            fontWeight: 400,
            color: '#00030E',
            fontSize: 14,
        },
        title: {
            marginLeft: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        titleBottom: {
            fontWeight: 'bold',
        },
        titleTop: {
            display: 'flex',
            marginLeft: "-2.2rem",
            marginTop: 8
        },
        divideTitle: {
            backgroundColor: '#C1C1C1',
            color: "#C1C1C1",
            margin: `0 5px`,
            height: 12,
            alignSelf: "center"
        },
        list: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyItems: 'flex-start',
            marginLeft: '-5px',
            [theme.breakpoints.down(500)]: {
                maxHeight:"70vh",
                overflow: "hidden",
                overflowY: "scroll",
            },
        },
        listItem: {
            flexShrink: 0,
            flexGrow: 0,
            justifyContent: 'center',
            width: '20%',

        },
        itemBtn: {
            height: ((isWindow ? window.innerWidth : 1920) * 60) / 475,
            flex: `0 0 ${((isWindow ? window.innerWidth : 1920) * 60) / 475}px`,
            fontSize: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: "#C1C1C1",
            color: "#6A6A6A",
            background: "#FDFDFD",
            fontWeight: 400,

            '&.Mui-disabled': {
                background: "#C1C1C1",
            },
            '&.active': {
                backgroundColor: theme.palette.secondary.dark,
                color: theme.palette.secondary.contrastText,
            },

        },
        floorLabel: {
            height: ((isWindow ? window.innerWidth : 1920) * 70) / 475,
            flex: `0 0 ${((isWindow ? window.innerWidth : 1920) * 70) / 475}px`,
            fontSize: ((isWindow ? window.innerWidth : 1920) * 14) / 475,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.secondary.dark,
            textTransform: 'uppercase',
            '&.active': {
                backgroundColor: theme.palette.secondary.dark,
                color: theme.palette.secondary.contrastText,
            },
        },
        listWrapper: {
            padding: "0px 35px",
            height: '90vh',
            overflowY: 'scroll',
        },
    };
});
