import {fetchProjectDetailByClient} from "../api/projectApi";
import {isNotEmptyObject, isWindow} from "./help";
import {runtimeConfig} from "../config";
import {getFloorDetailByClient, getFloorListByBuilding} from "../api/floorApi";
import {getPortFoliosByLayoutDrawings} from "../api/apartmentLayoutApi";

export const handleGetInit = async (projectSlug, buildingSlug) => {
    const response = {}
    if (projectSlug) {
        try {
            const res = await fetchProjectDetailByClient(
                {
                    slug: projectSlug,
                },
                null,
                null,
            )
            let data = {}
            if (!res?.published_at) {
                data = null
                alert('Project not found!!')
            } else {
                data = res
                data.buildings = data?.buildings?.filter((item, idx) => {
                    return item?.published_at !== null
                })
            }
            response.setProjectDetail = {project: data}
            const _listBuildings = data?.buildings
            if (_listBuildings?.length > 0) {
                if (buildingSlug) {
                    const index = _listBuildings.findIndex(
                        ({slug, published_at}) => isNotEmptyObject(published_at) && slug === buildingSlug,
                    )
                    if (index >= 0) {
                        response.setSelectedBuilding = _listBuildings[index]
                    }
                } else {
                    const index = _listBuildings.findIndex(
                        ({
                             slug,
                             published_at,
                             list_floors
                         }) => isNotEmptyObject(published_at) && isNotEmptyObject(slug) && isNotEmptyObject(list_floors),
                    )
                    if (index >= 0) {
                        response.history = `${isWindow && window.location.pathname}/${_listBuildings[index]?.slug}`
                        response.setSelectedBuilding = _listBuildings[index]
                    }
                }
            }
        } catch (error) {
            response.setProjectDetailFail = {message: error.message}
        }
    } else {
        response.history = `${runtimeConfig.RAZZLE_BASE_URL}/notFound`
    }
    return response
}

export const handleGetListFloorByBuildingId = async (building, projectSlug, floorSlug, matchMobile = undefined) => {
    const response = {}
    try {
        const listFloor = await getFloorListByBuilding(null, null, {
            building: building?.id,
        })
        if (listFloor.error) throw listFloor

        listFloor.sort((a, b) => {
            if (+a.label.split(' ')[1] > +b.label.split(' ')[1] || +a.label > +b.label) {
                return 1
            }
            if (+a.label.split(' ')[1] < +b.label.split(' ')[1] || +a.label < +b.label) {
                return -1
            }
            return 0
        })
        if (listFloor?.length > 0) {
            response.getListFloorByBuildingSuccess = {listFloor}
            if (floorSlug) {
                const index = listFloor.findIndex(
                    ({floorID, published_at}) => floorID === Number(floorSlug) && isNotEmptyObject(published_at),
                )
                if (index >= 0) {
                    response.setSelectedFloor = listFloor[index]
                }
            } else {
                const index = listFloor.findIndex(
                    ({
                         list_floor_link,
                         published_at
                     }) => isNotEmptyObject(published_at) && isNotEmptyObject(list_floor_link?.layoutCoordinates),
                )
                if (index >= 0) {
                    response.setSelectedFloor = listFloor[index]
                    if (matchMobile) {
                        response.history = `/view/${projectSlug}/${building?.slug}`
                    } else {
                        response.history = `/view/${projectSlug}/${building?.slug}/${listFloor[index]?.floorID}`
                    }
                }
            }
        } else {
            alert("toa nha Khong co tang")

        }
    } catch (error) {
        response.getListFloorByBuildingFailure = error
    }
    return response
}

export const handleGetListLayout = async (queries) => {
    const response = {}
    try {
        // fetchApi
        let res = await getFloorDetailByClient(
            null,
            null,
            queries,
        )
        if (res.error) throw res
        response.getListLayouts = {
            listApartments: res.filter((items) => items?.published_at)
        }
    } catch (error) {
        response.getListLayoutsFail = error
    }
    return response
}

export const handleGetPortFoliosByLayoutDrawings = async (apartmentSlug, listApartments) => {
    const response = {}
    try {
        if (apartmentSlug && listApartments?.length > 0) {
            const index = listApartments.findIndex(
                ({slug}) => slug === apartmentSlug,
            )
            if (index >= 0 && listApartments[index]?.layout_drawing?.id) {
                response.setApartmentCurrent = listApartments[index]
                const res = await getPortFoliosByLayoutDrawings(null, {id: listApartments[index]?.layout_drawing?.id}, null)
                if (res.error) throw res
                response.portfolios = {
                    portfolios: res,
                }
            }
        }else{
            response.setApartmentCurrent = {}
            response.portfolios = {
                portfolios: [],
            }
        }
    } catch (error) {
        console.log("err",error)
        response.getPortFoliosByLayoutDrawingsFail = {message: error.message}
    }
    return response
}

export const handleGetPortFoliosCurrent = async (portfolioSlug,portfolios) => {
    const response = {}
    if (portfolios && portfolios.length > 0) {
        if (portfolioSlug){
            const index = portfolios.findIndex(
                ({
                     slug,
                     published_at
                 }) => isNotEmptyObject(published_at) && isNotEmptyObject(slug) && slug === portfolioSlug,
            )
            if (index >= 0) {
                response.setPortfolioCurrent = portfolios[index]
            }
        }else{
            response.setPortfolioCurrent =  portfolios[0]
            response.history = portfolios[0]?.slug

        }
    }else{
        response.setPortfolioCurrent = {}
    }
    return response
}

