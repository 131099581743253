import React from 'react'
import { Box } from '@material-ui/core'

export function createComponent(Component, enhancement = {}) {
    if (!Component) return ({ children, ...rest }) => (
        <Box {...rest}>{children}</Box>
    )
    return ({ children, ...rest }) => (
        <Component {...enhancement} {...rest}>{children}</Component>
    )
}