/** @format */

import * as actionType from '../constants/actionType'

export const DEFAULT_STATE_BUILDING = {
  listBuildings: [],
  listBuildingsTable: [],
  selectedBuilding:{},
  listFloorLink: [],
  listLayoutLink: [],
  listBuildingByProject: [],
  isFetching: false,
  loading: true,
  dataFetched: false,
  error: false,
  message: '',
  isCaseSuccess: null,
  isState: '',
  isStateAdd: '',
  totalPage: 0,
  buildingSearch: '',
  page: 1,
}

const buildingReducer = (state = DEFAULT_STATE_BUILDING, action) => {
  let { type, payload } = action
  switch (type) {
    case actionType.GET_PROJECT_DETAIL_BY_CLIENT.REQUEST:
    case actionType.GET_LAYOUT_LINK.REQUEST:
    case actionType.ADD_LAYOUT_LINK.REQUEST:
    case actionType.UPDATE_LAYOUT_LINK.REQUEST:
    case actionType.DELETE_LAYOUT_LINK.REQUEST:
    case actionType.UPDATE_BUILDING.REQUEST:
    case actionType.DELETE_BUILDING.REQUEST:
    case actionType.PUBLISH_BUILDING.REQUEST:
    case actionType.UNPUBLISH_BUILDING.REQUEST:
    case actionType.GET_BUILDING_DETAIL_BY_CLIENT.REQUEST:
    case actionType.GET_BUILDING_BY_PROJECT.REQUEST:
    case actionType.GET_DETAIL_BUILDING.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        message: '',
        isStateAdd: '',
        // buildingSearch: '',
      }

    case actionType.BUILDING_FILTER.SUCCESS:
      return {
        ...state,
        isFetchingBuildingFilter: false,
        error: false,
        message: '',
        listBuildings: action.payload.listBuildings,
        buildingSearch: action.payload.textSearch,
        totalPage: action.payload.totalPage,
        page: action.payload.page,
      }
    case actionType.GET_BUILDING.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        message: '',
      }
    case actionType.CREATE_BUILDING.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        message: '',
        isStateAdd: false,
      }
    case actionType.ADD_LAYOUT_LINK.SUCCESS:
    case actionType.UPDATE_LAYOUT_LINK.SUCCESS:
    case actionType.DELETE_LAYOUT_LINK.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: '',
        isCaseSuccess: true,
      }
    case actionType.UPDATE_BUILDING.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: '',
        isCaseSuccess: true,
        isState: 'Dữ liệu đã được cập nhật !',
      }
    case actionType.CREATE_BUILDING.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: '',
        isCaseSuccess: true,
        isState: 'Dữ liệu đã được thêm !',
        isStateAdd: 'true',
      }
    case actionType.DELETE_BUILDING.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: '',
        isCaseSuccess: true,
        isState: 'xóa thành công !',
      }
    case actionType.PUBLISH_BUILDING.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: '',
        isCaseSuccess: true,
        isState: 'cập nhật trạng thái thành công !',
      }
    case actionType.UNPUBLISH_BUILDING.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: '',
        isCaseSuccess: true,
        isState: 'cập nhật trạng thái thành công !',
      }
    case actionType.GET_BUILDING.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: '',
        listBuildingsTable: action.payload.listBuildings,
        totalPage: action.payload.totalPage,
        page: action.payload.page,
      }
    case actionType.GET_PROJECT_DETAIL_BY_CLIENT.SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        error: false,
        message: '',
        listBuildings: action.payload.project.buildings,
      }
    case actionType.GET_BUILDING_BY_PROJECT.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: '',
        listBuildingByProject: action.payload.listBuildingByProject,
        totalPage: action.payload.totalPage,
        page: action.payload.page,
      }
    case actionType.GET_DETAIL_BUILDING.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: '',
        buildingDetail: action.payload.buildingDetail,
        isStateAdd: 'true',
      }
    case actionType.GET_FLOOR_LINK.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: '',
        listFloorLink: payload,
      }
    case actionType.GET_BUILDING_DETAIL_BY_CLIENT.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: '',
        buildingDetail: action.payload.buildingDetail,
      }

    case actionType.GET_BUILDING.FAILURE:
    case actionType.GET_DETAIL_BUILDING.FAILURE:
    case actionType.GET_PROJECT_DETAIL_BY_CLIENT.FAILURE:
    case actionType.GET_LAYOUT_LINK.FAILURE:
    case actionType.ADD_LAYOUT_LINK.FAILURE:
    case actionType.UPDATE_LAYOUT_LINK.FAILURE:
    case actionType.DELETE_LAYOUT_LINK.FAILURE:
    case actionType.CREATE_BUILDING.FAILURE:
    case actionType.UPDATE_BUILDING.FAILURE:
    case actionType.DELETE_BUILDING.FAILURE:
    case actionType.PUBLISH_BUILDING.FAILURE:
    case actionType.UNPUBLISH_BUILDING.FAILURE:
    case actionType.GET_BUILDING_DETAIL_BY_CLIENT.FAILURE:
    case actionType.GET_BUILDING_BY_PROJECT.FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        error: true,
        message: action.payload.message,
        isCaseSuccess: false,
      }

    case actionType.SET_BUILDING_DEFAULT.SUCCESS:
      return {
        ...state,
        selectedBuilding: payload
      }

    case 'RESET_MESSAGE_STATE':
      return {
        ...state,
        isCaseSuccess: null,
      }
    case 'RESET_BUILDING_STATE':
      return DEFAULT_STATE_BUILDING
    default:
      return state
  }
}

export default buildingReducer
