/** @format */

import React, {useEffect, useState} from 'react'
import {
    Box,
    Button,
    Divider,
    Typography,
    // List,
    TextField,
} from '@material-ui/core'
import useStyles from './FloorFigureSidebarStyles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import FloorFigure from '../Figure/FloorFigure'
import {Autocomplete} from '@material-ui/lab'
import {useHistory} from "react-router-dom";
import {SharedBox, SharedBox2} from "./ProjectSidebarFragments";
import {isNotEmptyObject, substringCustom} from "../../utils/help";

function FloorFigureSidebar({
                                floorLink,
                                projectName,
                                buildingName,
                                listApartments,
                                selectedFloor,
                                setSelectedFloor,
                                floorSlug,
                                projectSlug,
                                buildingSlug,
                                listFloor,
                                setShowFloorSidebar,
                                setShowModal
                            }) {
    const classes = useStyles()
    const history = useHistory()
    const MAX_LENGTH = 10
    const [selectedLayout, setSelectedLayout] = useState()
    const [coordinates, setCoordinates] = useState([])
    useEffect(() => {
        if (floorLink?.layoutCoordinates?.length > 0) {
            const index = floorLink?.layoutCoordinates?.findIndex(
                ({layout}) => layout?.id === selectedLayout?.id,
            )
            setCoordinates(floorLink?.layoutCoordinates[index]?.coordinates)
        } else {
            setCoordinates([])
        }
    }, [selectedLayout])
    const handleChangeFloor = (event, value,reason) => {
        if(reason === 'select-option'){
            setSelectedFloor(value)
            history.push(String(value?.floorID))
        }
    }
    return (
        <Box className={`${classes.floorFigureSidebar}`}>
            <Box className={classes.navBar}>
                <div
                    className={classes.backBtn}
                    onClick={() => {
                        setShowFloorSidebar(false)
                        history.push(`/view/${projectSlug}/${buildingSlug}`)
                    }
                    }
                >
                    <ArrowBackIcon/>
                </div>
                <Box className={classes.title}>
                    <Box className={classes.titleBottom}>
                        {listFloor?.length > 0 && selectedFloor?.label && (
                            <Autocomplete
                                className={classes.autocomplete}
                                defaultValue={selectedFloor}
                                options={listFloor}
                                renderInput={(params) => (
                                    <TextField
                                        style={{padding: '3px 0 0px !important'}}
                                        className={classes.inputRoot}
                                        variant="outlined"
                                        {...params}
                                    />
                                )}
                                getOptionLabel={(option) => option?.label || ''}
                                getOptionDisabled={option => !option?.list_floor_link || option?.list_floor_link?.layoutCoordinates.length <= 0}
                                size='small'
                                closeIcon=''
                                autoHighlight
                                onChange={handleChangeFloor}
                            />
                        )}
                    </Box>

                    <Box className={classes.titleTop}>
                        <Typography className={classes.titleText} variant='caption'>
                           Tòa {substringCustom(buildingName,MAX_LENGTH)}

                        </Typography>
                        <Divider
                            orientation='vertical'
                            flexItem
                            className={classes.divideTitle}
                        />
                        <Typography className={classes.titleText} variant='caption'>
                           Dự án {substringCustom(projectName,MAX_LENGTH)}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box>
                <FloorFigure
                    layoutCoordinates={floorLink?.layoutCoordinates || []}
                    layoutSrc={floorLink?.imageFloorPlan?.url}
                    selectedLayout={selectedLayout}
                    setSelectedLayout={setSelectedLayout}
                    setCoordinates={setCoordinates}
                    coordinates={coordinates}
                    listApartments={listApartments}
                    projectSlug={projectSlug}
                    buildingSlug={buildingSlug}
                    floorSlug={floorSlug}
                    floorLink={floorLink}
                />

            </Box>
        </Box>
    )
}

export default FloorFigureSidebar
