/** @format */

import configApi from '../utils/configApi';
import { DOMAIN } from '../redux/constants/actionType';

import localWorker from '../utils/localWorker';
const localstorage = localWorker();
export const getListBuildings = configApi({
  baseUrl: DOMAIN + '/content-manager/collection-types/application::buildings.buildings',
  method: 'GET',
  headers: {
    // 'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localstorage.getToken(),
  },
});
// export const getListBuildingAll = configApi({
//   baseUrl: DOMAIN + '/buildings',
//   method: 'GET',
//   headers: {
//     // 'Content-Type': 'application/json',
//     Authorization: 'Bearer ' + localstorage.getToken(),
//   },
// });
export const getBuildingDetail = configApi({
  baseUrl: DOMAIN + '/buildings',
  method: 'GET',
  headers: {
    // 'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localstorage.getToken(),
  },
});

export const createBuildingApi = configApi({
  baseUrl: DOMAIN + '/buildings',
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localstorage.getToken(),
  },
});

export const createProjectByBuiding = configApi({
  baseUrl: DOMAIN + '/projects',
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localstorage.getToken(),
  },
});
export const deleteBuildingApi = configApi({
  baseUrl: DOMAIN + '/content-manager/collection-types/application::buildings.buildings/actions/bulkDelete',
  method: 'POST',
  headers: {
    Authorization: 'Bearer ' + localstorage.getToken(),
    'Content-Type': 'application/json'
  },
});
export const updateBuildingApi = configApi({
  baseUrl: DOMAIN + `/content-manager/collection-types/application::buildings.buildings`,
  method: 'PUT',
  headers: {
    Authorization: 'Bearer ' + localstorage.getToken(),
    'Content-Type': 'application/json',
  },
});
export const updateStatusBuilding = configApi({
  baseUrl: DOMAIN + `/buildings/changeStatus`,
  method: 'POST',
  headers: {
    Authorization: 'Bearer ' + localstorage.getToken(),
    'Content-Type': 'application/json',
  },
});

export const getBuildingDetailByClient = configApi({
  baseUrl: DOMAIN,
  path: 'buildings'
});

export const generateSlugBuildingApi = configApi({
  baseUrl: DOMAIN,
  path: `content-manager/uid/generate`,
  method: 'POST',
  headers: {
    Authorization: 'Bearer ' + localstorage.getToken(),
    'Content-Type': 'application/json',
  }
})