/** @format */

import React from 'react'
import {Box, makeStyles, Popover, Typography} from '@material-ui/core'
import {Rating} from '@material-ui/lab'
import {
    HotelOutlined,
    AspectRatioOutlined,
    ColorLensOutlined,
} from '@material-ui/icons'
import {DOMAIN_IMAGE} from '../../../../redux/constants/actionType'
import iconClose from "../../../../assets/images/v2/closeButton.svg";
import _ from "lodash";
import CloseIcon from "@material-ui/icons/Close";

const Viewapartmentmobileinfo = (props) => {
    const {apartmentCurrent, portfolioCurrent} = props
    const inspirations = _.map(portfolioCurrent?.inspirations, 'name')
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const classes = useStyles()
    return (
        <Box className={classes.container}>
            <div className={classes.title}>
                <div>Loại căn hộ:</div>
                <div style={{
                    color: "#F37021",
                    fontWeight: 600,
                    fontSize: 14

                }}>{apartmentCurrent?.layout_type?.name} - {apartmentCurrent?.acreage} m2
                </div>
            </div>
            <div className={classes.title}>
                <div>Kiến trúc sư:</div>
                <div style={{
                    color: "#6A6A6A",
                    fontWeight: 600,
                    fontSize: 14

                }}>{portfolioCurrent?.user?.name}
                </div>
            </div>
            <div className={classes.title}>
                <div>Phong cách:</div>
                <div className={classes.itemStyle}>
                    {inspirations &&
                        _.slice(inspirations, 0, 2).map((item, index) => (
                            <span className={classes.style} key={index}>{item}</span>
                        ))}
                    {inspirations.length - 2 > 0 &&
                        <>
                                              <span
                                                  style={{
                                                      color: "#6A6A6A",
                                                      padding: 0,
                                                      minWidth: "auto"
                                                  }}
                                                  onClick={handleClick}
                                              >
                                                   {`+${inspirations.length - 2}`}
                                              </span>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                className={classes.popoverInspirations}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <CloseIcon className={classes.closeIcon} onClick={handleClose}/>
                                <p className={classes.titlePc}>Phong cách</p>
                                <Box className={classes.popItems}>
                                    {inspirations.map((item, index) => (
                                        <span className={classes.style} key={index}>{item}</span>
                                    ))}
                                </Box>

                            </Popover>
                        </>
                    }
                </div>
            </div>
            <div className={classes.title}>
                <div>Đánh giá:</div>
                <Rating value={5} className={classes.textRating} readOnly={true}/>
            </div>
        </Box>
    )
}

export default Viewapartmentmobileinfo
const useStyles = makeStyles((themes) => {
    return {
        container: {
            margin: "0px 20px",
            padding: 10,
            border: "1px solid #C1C1C1",
            boxSizing: "border-box",
            borderRadius: 10,
            background: "#FDFDFD",
            marginBottom: 100
            // padding: '10px 5px 0',
            // display: 'flex',
            // justifyContent: 'space-between',
        },
        textRating: {
            fontSize: 16,
            padding: '1px 0',
            lineHeight: 1,
        },
        itemStyle: {

        },
        title: {
            display: "flex",
            justifyContent: "space-between",
            marginTop: 16,
            "& div:first-child": {
                color: "#00030E",
                fontWeight: 400,
                fontSize: 16,
                minWidth: 120
            }
        },

        closeIcon: {
            position: 'absolute',
            top: -10,
            right: -10,
            color: 'gray',
            backgroundColor: '#fff',
            borderRadius: '50%',
        },
        titlePc:{
            paddingLeft:10,
            color:"#000",
            fontSize: 8,
            fontWeight:600
        },
        popItems: {
            width: 150,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'start',
            '& span': {
                marginBottom: 8
            }
        },
        popoverInspirations: {
            "& .MuiPopover-paper": {
                // position:'relative'
                overflow: 'visible',
                borderRadius: 5,

                // display: "flex",
                // maxWidth: 100,
                // // flexDirection: "column",
                // justifyContent: "space-between",
                padding: 8,
                // paddingTop: 0,
                // "& span": {
                //     marginTop: 8
                // }
            }
        },
        style: {
            padding: "4px 8px",
            background: "#E6E6E6",
            borderRadius: 20,
            color: "#6A6A6A",
            fontWeight: 600,
            fontSize: 10,
            marginRight: 8
        },
        item: {
            height: '8vh',
            width: '100%',
            maxWidth: '48vw',
            backgroundColor: '#fff',
            margin: '0 5px',
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        containerRight: {
            height: '8vh',
            width: '100%',
            backgroundColor: '#fff',
            margin: '0  10px 0 0',
            padding: '5px 10px',
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
        boxImg: {
            height: '8vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
        },
        designerImg: {
            height: '6vh',
            width: '6vh',
            borderRadius: '50%',
        },
        info: {
            flex: 2,
        },
        text: {
            fontSize: '1.5vh',
            padding: '1px 0',
            lineHeight: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '27vw',
        },
        textIcon: {
            fontSize: '1.5vh',
            padding: '1px 0',
            lineHeight: 1,
            display: 'flex',
            alignItems: 'center',
        },
        textIconTop: {
            fontSize: '1.5vh',
            padding: '1px 0',
            lineHeight: 1,
            display: 'flex',
            alignItems: 'center',
            // marginBottom: 5
        },
        icon: {
            fontSize: '2.5vh',
            color: '#0054a6',
            marginRight: 8,
        },
        itemLeft: {
            // flex: 3,
            width: '100%',
            // display: 'flex',
            // marginLeft: '3vh'
        },
        itemRight: {
            flex: 4,
            display: 'flex',
            width: '100%',
            // justifyContent: 'space-evenly',
        },
    }
})
