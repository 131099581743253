/** @format */

import {Box, Button, Typography as Typo, Popover, Typography, Modal, Tooltip} from '@material-ui/core'
import React from 'react'
import {
    useStylesProjectInfo,
    useStylesSharedBox, useStylesSharedBox2,
} from './ProjectSidebarStyles'
import {createComponent} from '../../utils/createComponent'
import Logo from '../../assets/images/v2/logoFooter.svg'
import {
    FacebookShareButton,
    FacebookIcon,
    PinterestShareButton, PinterestIcon,
} from 'react-share'
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined'

import iconInfo from "../../assets/images/v2/info.svg"
import iconInfo2 from "../../assets/images/v2/info2.svg"
import infoMobile from "../../assets/images/v2/infoMobile.svg"
import iconShare from "../../assets/images/v2/share.svg"
import iconCall from "../../assets/images/v2/call.svg"
import iconClose from "../../assets/images/v2/closeButton.svg"
import {Rating} from "@material-ui/lab";
import _ from "lodash";
import {isWindow} from "../../utils/help";
import {message} from "antd";

// import index from '/index.html'

export function ProjectInfo({matchMobile, name, investor, address}) {
    const classes = useStylesProjectInfo()
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <Box style={{zIndex: 1}}>
            <div className={classes.projectName}>
                <div className={classes.shadowText}>{name}</div>
                {
                    matchMobile
                        ? <img style={{cursor: 'pointer'}} onMouseEnter={handleClick} onMouseLeave={handleClose}
                               width={30} height={30} src={infoMobile} alt=""/>

                        : <img style={{cursor: 'pointer'}} onMouseEnter={handleClick} onMouseLeave={handleClose}
                               width={30} height={30} src={iconInfo} alt=""/>

                }
            </div>
            <Popover
                className={classes.mouseOverPopoverInfo}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableRestoreFocus
            >
                <Typography>
                    <ProjectName className={classes.popoverInfoProject}>
                        <span>Dự án:</span> {name}
                    </ProjectName>
                    <InvestorName className={classes.popoverInfoProject}>
                        <span>Chủ đầu tư:</span> {investor}
                    </InvestorName>
                    <ProjectAddress className={classes.popoverInfoProject}>
                        <span>Địa chỉ:</span> {address}
                    </ProjectAddress>
                </Typography>
            </Popover>

        </Box>
    )
}

const ProjectName = createComponent(Typo, {variant: 'body2'})
const InvestorName = createComponent(Typo, {variant: 'body2'})
const ProjectAddress = createComponent(Typo, {variant: 'body2'})

export function SharedBox({project, setShowModal, domainAvail}) {
    const classes = useStylesSharedBox()
    const [anchorEl, setAnchorEl] = React.useState(null)
    /*
        React.useEffect(() => {
            //change meta tag to share to facebook
            if (project) {
                let meta = document.querySelector('meta[property="og:url"]')
                meta.setAttribute('content', isWindow ? window.location.href : runtimeConfig.RAZZLE_BASE_URL)
                meta = document.querySelector('meta[property="og:title"]')
                meta.setAttribute('content', 'Dự án ' + project?.name)
                meta = document.querySelector('meta[property="og:description"]')
                meta.setAttribute('content', project?.description)
                meta = document.querySelector('meta[name="description"]')
                meta.setAttribute('content', project?.description)
                meta = document.querySelector('meta[property="og:image"]')
                meta.setAttribute('content', project?.featured_image?.url)
            }
        }, [project])
    */

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    function fallbackCopyTextToClipboard(text) {
        handleClose()
        const textArea = document.createElement("textarea");
        textArea.value = text;
        // Avoid scrolling to bottom
        textArea.style.top = "10px";
        textArea.style.zIndex = "999999999";
        textArea.style.left = "10px";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            const successful = document.execCommand('copy');
            successful &&  message.success("Sao chép thành công!")
            const msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
    }

    function copyTextToClipboard(text) {
        handleClose()
        setTimeout(() => {
            fallbackCopyTextToClipboard(text)
        }, 100)

        // return document.execCommand('copy', true, text)
        // if ('clipboard' in navigator) {
        //     return await navigator.clipboard.writeText(text)
        // } else {
        //     fallbackCopyTextToClipboard(text)
        // }
    }

    const open = Boolean(anchorEl)

    const SocialShareBox = (
        <Box className={classes.socialShareBox}>
            <Box>
                <FacebookShareButton
                    className={classes.shareButton}
                    url={`${isWindow && window.location.href}`}
                    quote={'Share to facebook'}
                >
                    <FacebookIcon size={16} round style={{marginRight: '6px'}}/>
                    Chia sẻ lên Facebook
                </FacebookShareButton>
            </Box>
            <Box>
                <PinterestShareButton
                    className={classes.shareButton}
                    // media={`${String(isWindow ? window.location.href : runtimeConfig.RAZZLE_BASE_URL)}/`}
                    url={`${isWindow && window.location.href}`}
                    quote={'Share to Pinterest'}
                >
                    <PinterestIcon size={16} round style={{marginRight: '6px'}}/>
                    Chia sẻ lên Pinterest
                </PinterestShareButton>
            </Box>
            <Box>
                <div
                    className={classes.shareButtonCopy}
                    onClick={() => {
                        copyTextToClipboard(`${isWindow && window.location.href}`)
                    }}
                >
                    <LinkOutlinedIcon size={16} round style={{marginRight: '6px', width: 16, height: 16}}/> Đường dẫn
                    rút gọn
                </div>

            </Box>
        </Box>
    )

    return (
        <Box className={classes.root}>
            <Box className={classes.footerTop}>

                <Button
                    variant='text'
                    size='small'
                    className={classes.link}
                    onClick={handleClick}
                >
                    Chia sẻ
                </Button>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={classes.popoverShare}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    {SocialShareBox}
                </Popover>
                {
                    domainAvail &&
                    <Button variant='text' size='small' className={classes.phone} onClick={() => setShowModal(true)}>
                        <span>Liên hệ tư vấn</span>
                    </Button>
                }

            </Box>
            <Box className={classes.footerLogo}>
                <img
                    className={classes.logo}
                    width={100}
                    src={Logo}
                    alt='flex-home-logo'
                />
            </Box>
        </Box>
    )
}

export function SharedBox2({data, layout, setShowModal, matchMobile, apartmentSlug, floorSlug, domainAvail}) {
    const classes = useStylesSharedBox2()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const inspirations = _.map(data?.inspirations, 'name')
    /*React.useEffect(() => {
        //change meta tag to share to facebook
        if (data) {
            let meta = document.querySelector('meta[property="og:url"]')
            meta.setAttribute('content',isWindow && window.location.href)
            meta = document.querySelector('meta[property="og:title"]')
            meta.setAttribute('content', data?.seo_title ? data.seo_title : data?.title ? data.title : '')
            meta = document.querySelector('meta[property="og:description"]')
            meta.setAttribute('content', data?.seo_description ? data.seo_description : data?.introduce ? data?.introduce : '')
            meta = document.querySelector('meta[name="description"]')
            meta.setAttribute('content', data?.seo_description)
            meta = document.querySelector('meta[property="og:image"]')
            meta.setAttribute('content', data?.featured_image?.url)
        }
    }, [data])*/

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }


    const handleClose = () => {
        setAnchorEl(null)
    }

    function fallbackCopyTextToClipboard(text) {
        handleClose()
        const textArea = document.createElement("textarea");
        textArea.value = text;
        // Avoid scrolling to bottom
        textArea.style.top = "10px";
        textArea.style.zIndex = "999999999";
        textArea.style.left = "10px";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            const successful = document.execCommand('copy');
            successful &&  message.success("Sao chép thành công!")
            const msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
    }

    function copyTextToClipboard(text) {
        handleClose()
        setTimeout(() => {
            fallbackCopyTextToClipboard(text)
        }, 100)

        // return document.execCommand('copy', true, text)
        // if ('clipboard' in navigator) {
        //     return await navigator.clipboard.writeText(text)
        // } else {
        //     fallbackCopyTextToClipboard(text)
        // }
    }



    const open = Boolean(anchorEl)

    const SocialShareBox = (
        <Box className={classes.socialShareBox}>
            <Box>
                <FacebookShareButton
                    className={classes.shareButton}
                    url={`${isWindow && window.location.href}`}
                    quote={'Share to facebook'}
                >
                    <FacebookIcon size={16} round style={{marginRight: '6px'}}/>
                    Chia sẻ lên Facebook
                </FacebookShareButton>
            </Box>

            <Box>
                <PinterestShareButton
                    className={classes.shareButton}
                    // media={`${String(isWindow && window.location)}/`}
                    url={`${isWindow && window.location.href}`}
                    quote={'Share to Pinterest'}
                >
                    <PinterestIcon size={16} round style={{marginRight: '6px'}}/>
                    Chia sẻ lên Pinterest
                </PinterestShareButton>
            </Box>
            <Box>
                <div
                    className={classes.shareButtonCopy}

                    onClick={() => {
                        copyTextToClipboard(`${isWindow && window.location.href}`)
                    }}
                >
                    <LinkOutlinedIcon size={16} round style={{marginRight: '6px', width: 16, height: 16}}/> Đường dẫn
                    rút gọn
                </div>

            </Box>
        </Box>
    )
    return (
        <Box
            className={`${classes.root} ${!matchMobile && classes.matchPC} ${apartmentSlug && classes.rotApartment} ${!floorSlug && classes.unFloorSlug}`}>
            <Box>
                <Tooltip className={classes.info} title="Xem thông tin công trình" placement="top">
                    <img style={{cursor: "pointer"}} onClick={handleOpenModal} width={60} height={60} src={iconInfo2}
                         alt="Xem Thông tin công trình"/>
                </Tooltip>
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        border: "1px solid #FFB88C",
                        boxSizing: "order-box",
                        borderRadius: 10,
                        bgcolor: '#FFF5EF',
                        padding: "18px 16px"

                    }}>
                        <img className={classes.close} onClick={handleCloseModal}
                             src={iconClose} style={{}} alt="Đóng"/>
                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                            <div className={`${classes.title}`}>
                                <div>Loại căn hộ:</div>
                                <div style={{
                                    color: "#F37021",
                                    fontWeight: 600,
                                    fontSize: 14

                                }}>{layout?.layout_type?.name} - {layout?.acreage} m2
                                </div>
                            </div>
                            <div className={classes.title}>
                                <div>Kiến trúc sư:</div>
                                <div style={{
                                    color: "#6A6A6A",
                                    fontWeight: 600,
                                    fontSize: 14

                                }}>{data?.user?.name}
                                </div>
                            </div>
                            <div className={`${classes.title}`}>
                                <div>Phong cách:</div>
                                <div className={`${classes.inspirations}`}>
                                    {inspirations &&
                                        inspirations.map((item, index) => (
                                            <span className={classes.style} key={index}>{item}</span>
                                        ))}
                                </div>
                            </div>
                            <div className={classes.title}>
                                <div>Đánh giá:</div>
                                <Rating value={5} className={classes.textRating} readOnly={true}/>
                            </div>
                        </Typography>
                    </Box>
                </Modal>
            </Box>
            <Box>
                <Tooltip title="Chia sẻ" placement="top">
                    <img style={{cursor: "pointer"}} width={matchMobile ? 80 : 60} height={matchMobile ? 80 : 60}
                         onClick={handleClick} src={iconShare}
                         alt="Chia sẻ"/>
                </Tooltip>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={classes.popoverShare}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    {SocialShareBox}
                </Popover>
            </Box>
            {
                domainAvail &&
                <div style={{cursor: "pointer"}} onClick={() => setShowModal(true)}>
                    <Tooltip title="Liên hệ tư vấn" placement="top">
                        <img width={matchMobile ? 80 : 60} height={matchMobile ? 80 : 60} src={iconCall}
                             alt="Liên hệ tư vấn"/>
                    </Tooltip>
                </div>
            }
        </Box>
    )
}


