/** @format */

import {makeStyles} from '@material-ui/core';
import {isWindow} from "../../../../utils/help";

export default makeStyles((theme) => {
    return {
        container: {},
        leftItem: {

            width: isWindow ? window.innerWidth * (496 / 1920) : 496,

        },
        leftItemIpad: {
            position: 'absolute',
            width: isWindow ? window.innerHeight * (97 / 75) : 968 * (97 / 75),
            height: isWindow ? window.innerHeight * (968 / 1050) : 968 * (968 / 1050),
            backgroundColor: '#fff',
            top: isWindow ? window.innerHeight * (41 / 1050) : 968 * (41 / 1050),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            right: 0,
        },
        leftMobile: {
            position: 'absolute',
            width: isWindow ? window.innerWidth * 0.95 : 1920 * 0.95,
            height: isWindow ? window.innerHeight * 0.9 : 968 * 0.9,
            backgroundColor: '#fff',
            top: isWindow ? window.innerHeight * 0.05 : 968 * 0.05,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            right: 0,
        },
        leftContent: {
            margin: '0 auto',
            height: '100%',
        },
        leftContentMobile: {
            margin: '0 auto',
            width: isWindow ? window.innerWidth * 0.85 : 1920 * 0.85,
            height: '100%',
        },
        preview: {
            height: isWindow ? window.innerHeight * (763 / 1050) : 968 * (763 / 1050),
            widows: '100%',
            border: `1px solid #b9b9b9`,
            borderRadius: 5,
        },
        previewMobile: {
            height: isWindow ? window.innerHeight * 0.7 : 968 * 0.7,
            widows: '100%',
            border: `1px solid #b9b9b9`,
            borderRadius: 5,
        },
        footer: {
            height: isWindow ? window.innerHeight * (102 / 1050) : 968 * (102 / 1050),
            width: '100%',
        },
        footerMobile: {
            height: isWindow ? window.innerHeight * 0.1 : 968 * 0.1,
            width: '100%',
        },
        header: {
            height: isWindow ? window.innerHeight * (102 / 1050) : 968 * (102 / 1050),
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        headerMobile: {
            height: isWindow ? window.innerHeight * 0.1 : 968 * 0.1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        rightItem: {
            // position: 'absolute',
            // height: '100vh',
            width: isWindow ? window.innerWidth * (1424 / 1920) : 1424,
            overflow: "hidden"
            // right: 0,
            // backgroundColor: '#fff',
            // boxShadow: theme.shadows[4],
            // transition: '300ms',
            // zIndex: 201,
            // padding: '0 10px',
            // // [theme.breakpoints.down(1400)]: {
            // //   padding: '0 10px',
            // // },
            // // [theme.breakpoints.down(1200)]: {
            // //   padding: '4% 10px',
            // // },
            // ['@media (max-height: 720px)']: {
            //   width: '27%',
            // },
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'center'
        },
        rightItemIpad: {
            position: 'absolute',
            height: '100vh',
            width: isWindow ? window.innerWidth * (483 / 1920) : 483,
            right: isWindow ? -(window.innerWidth * (483 / 1920)) : -483,
            backgroundColor: '#fff',
            padding: '0 10px',
            boxShadow: theme.shadows[4],
            transition: '300ms',
            zIndex: 201,
        },
        sidebarToggleMobile: {
            position: 'fixed',
            top: '7vh',
            right: isWindow ? window.innerWidth * 0.05 : 1920 * 0.05,
            zIndex: 2,
        },
        showDesignerIpad: {
            position: 'absolute',
            height: '100vh',
            width: isWindow ? window.innerWidth * 0.5 : 1920 * 0.5,
            right: 0,
            backgroundColor: '#fff',
            padding: '0 10px',
            boxShadow: theme.shadows[4],
            transition: '300ms',
            zIndex: 10,
            [theme.breakpoints.down(450)]: {
                width: isWindow ? window.innerWidth * 0.95 : 1920  * 0.95,
            },
        },
    };
});
