/** @format */

import {takeLatest, put, select} from '@redux-saga/core/effects'
import * as actionType from '../constants/actionType'
import * as action from '../actions'
import {
    // fetchProjectDetailByClient,
    fetchProjectApi,
    fetchProjectDetailApi,
    fetchDeleteProjectApi,
    fetchUpdateStatusProjectApi,
    fetchCreateProjectApi,
    fetchUpdateProjectApi,
    fetchSlugProjectApi,
} from '../../api/projectApi'

function* getListProject({payload}) {
    try {
        const projects = yield fetchProjectApi(
            null,
            {},
            {
                page: payload.page,
                pageSize: payload.limit,
                _sort: 'createdAt:DESC',
            },
        )
        let data = projects.results.map((item, idx) => {
            return {
                id: item?._id,
                index: payload.page * payload.limit - payload.limit + idx + 1,
                name: item?.name,
                investor: item?.investor?.name,
                province: item?.province?.name,
                address: item?.address,
                featuredImage: item?.featured_image?.url,
                status: item?.published_at ? 'publish' : 'unpublish',
            }
        })
        yield put(
            action.getListProjects.success({
                listProject: data,
                totalPage: projects.pagination.pageCount,
                page: payload?.page,
            }),
        )
    } catch (error) {
        yield put(action.getListProjects.failure({message: error.message}))
    }
}

function* getDetailProject({payload}) {
    const idDetail = payload.id
    try {
        const detailProject = yield fetchProjectDetailApi(null, {idDetail}, null)
        const dataDetail = {
            name: detailProject?.name,
            investor: {
                id: detailProject?.investor?._id,
                name: detailProject?.investor?.name,
            },
            slug: detailProject?.slug,
            seo_title: detailProject?.seo_title,
            seo_description: detailProject?.seo_description,
            iframe_url: detailProject?.iframe_url,
            description: detailProject?.description,
            featured_image: {
                id: detailProject?.featured_image?._id,
                url: detailProject?.featured_image?.url,
            },
            overview_image: {
                id: detailProject?.overview_image?._id,
                url: detailProject?.overview_image?.url,
            },
            projectBackground: detailProject?.projectBackground,
            status_logo: detailProject?.status_logo,
            gallery_images: detailProject?.gallery_images,
            province: {
                id: detailProject?.province?._id,
                name: detailProject?.province?.name,
            },
            district: {
                id: detailProject?.district?._id,
                name: detailProject?.district?.name_with_type,
            },
            ward: {
                id: detailProject?.ward?._id,
                name: detailProject?.ward?.name_with_type,
            },
            address: detailProject?.address,
            latitude: detailProject?.latitude,
            longitude: detailProject?.longitude,
            status: detailProject?.published_at ? 'publish' : 'unpublish',
        }
        yield put(action.getProjectsDetail.success({projectDetail: dataDetail}))
    } catch (error) {
        yield put(action.getProjectsDetail.failure({message: error.message}))
    }
}

function* deleteProject({payload}) {
    try {
        let dataPagination = yield select((state) => {
            return {
                page: state.project.page,
                textSearch: state.project.textSearch,
            }
        })
        yield fetchDeleteProjectApi({ids: payload}, null, null)
        yield put(action.deleteProject.success({}))
        if (dataPagination.textSearch) {
            yield put(
                action.searchProjectByFilter.request({
                    page: dataPagination.page,
                    limit: 5,
                    ...dataPagination.textSearch,
                }),
            )
        } else {
            yield put(
                action.getListProjects.request({
                    page: dataPagination.page,
                    limit: 5,
                }),
            )
        }
    } catch (error) {
        yield put(action.deleteProject.failure({message: error.message}))
    }
}

// function* getProjectDetailByClient({payload}) {
//     try {
//         const res = yield fetchProjectDetailByClient(
//             {
//                 slug: payload.projectSlug,
//             },
//             null,
//             null,
//         )
//         console.log(res)
//         let data = {}
//         if (res[0]?.published_at === null) {
//             data = null
//             alert('Project not found!!')
//         } else {
//             data = res[0]
//             data.buildings = data?.buildings?.filter((item, idx) => {
//                 return item?.published_at !== null
//             })
//         }
//         if (res.error) throw res
//
//         yield put(
//             action.getProjectDetailByClient.success({
//                 project: data,
//             }),
//         )
//     } catch (error) {
//         yield put(
//             action.getProjectDetailByClient.failure({message: error.message}),
//         )
//     }
// }

function* createProject({payload}) {
    try {
        const slugProject = yield fetchSlugProjectApi({
            contentTypeUID: 'application::projects.projects',
            data: {name: payload.data.name},
            field: 'slug',
        })
        const dataCreate = {
            ...payload.data,
            slug: slugProject.data,
            code: slugProject.data,
        }
        const response = yield fetchCreateProjectApi({...dataCreate}, null, null)
        if (response.error) {
            yield put(action.createProjects.failure({message: response.message}))
        } else {
            yield put(
                action.createProjects.success({
                    idResponseId: response._id,
                    idResponseName: response.name,
                }),
            )
        }
    } catch (error) {
        yield put(action.createProjects.failure({message: error.message}))
    }
}

function* updateProject({payload}) {
    const idEdit = payload.data.id
    try {
        let slugProject
        if (payload.data.isChangeSlug === true) {
            slugProject = yield fetchSlugProjectApi({
                contentTypeUID: 'application::projects.projects',
                data: {name: payload.data.name},
                field: 'slug',
            })
        }
        const dataUpdate = {
            ...payload.data,
            slug:
                payload.data.isChangeSlug === true
                    ? slugProject.data
                    : payload.data.slug,
            code:
                payload.data.isChangeSlug === true
                    ? slugProject.data
                    : payload.data.code,
        }

        yield fetchUpdateProjectApi({...dataUpdate}, {idEdit}, null)
        yield put(action.updateProject.success({}))
        if (!payload.isOpenModal) {
            yield put(action.getProjectsDetail.request({id: idEdit}))
        }
    } catch (error) {
        yield put(action.updateProject.failure({message: error.message}))
    }
}

function* updateStatusProject({payload}) {
    const Token =
        'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJVc2VybmFtZSI6IkZsZXhob21lQWRtaW4iLCJleHAiOjE2MzM1NzIyMjYsImlhdCI6MTYzMzU3MjIyNn0.Vp1g8LcrJg1yr9vt08yrXYn2R4q4jiPJ0Lfz4h1woU4'
    const {id, status, isDetail} = payload
    try {
        let dataPagination = yield select((state) => {
            return {
                page: state.project.page,
                textSearch: state.project.textSearch,
            }
        })
        yield fetchUpdateStatusProjectApi(
            {token: `${Token}`, ids: id, status: status},
            null,
            null,
        )
        yield put(action.updateStatusProject.success())

        if (isDetail) {
            yield put(action.getProjectsDetail.request({id: id[0]}))
        } else {
            if (dataPagination.textSearch) {
                yield put(
                    action.searchProjectByFilter.request({
                        page: dataPagination.page,
                        limit: 5,
                        ...dataPagination.textSearch,
                    }),
                )
            } else {
                yield put(
                    action.getListProjects.request({
                        page: dataPagination.page,
                        limit: 5,
                    }),
                )
            }
        }
    } catch (error) {
        yield put(action.updateStatusProject.failure({message: error.message}))
    }
}

const projectSaga = [
    takeLatest(actionType.GET_LIST_PROJECT.REQUEST, getListProject),
    takeLatest(actionType.GET_PROJECT_DETAIL.REQUEST, getDetailProject),
    takeLatest(actionType.DELETE_PROJECT.REQUEST, deleteProject),
    takeLatest(actionType.CREATE_PROJECT.REQUEST, createProject),
    takeLatest(actionType.UPDATE_PROJECT.REQUEST, updateProject),
    takeLatest(actionType.UPDATE_STATUS_PROJECT.REQUEST, updateStatusProject),
    // takeLatest(actionType.GET_PROJECT_DETAIL_BY_CLIENT.REQUEST, getProjectDetailByClient),
]

export default projectSaga
