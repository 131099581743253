/** @format */

import {makeStyles} from '@material-ui/core'
import {isWindow} from "../../utils/help";

export default makeStyles((theme) => ({
    // ApartmentSlider
    wrapper: {
        // overflow: 'hidden',
        width: (isWindow ? window.innerWidth : 1920) * (483 / 1920),
        height: '100%',
        padding: '0 10px',
    },
    wrapperMobile: {
        // overflow: 'hidden',
        width: (isWindow ? window.innerWidth : 1920) * (483 / 1920),
        height: '100%',
        padding: '0 10px',
        [theme.breakpoints.down(450)]: {
            width: (isWindow ? window.innerWidth : 1920) * 0.8,
        },
    },
    // fragments
    // SliderItem
    sliderItem: {
        width: (isWindow ? window.innerWidth : 1920) * (360 / 1920),
        height: (isWindow ? window.innerWidth : 1920) * (200 / 1920),
        backgroundSize: 'cover',
        // position: 'relative',
        borderRadius: 15,
        // padding: 10,
        overflow: "hidden"
    },
    sliderItemIpad: {
        width: (isWindow ? window.innerWidth : 1920) * 0.4,
        height: (isWindow ? window.innerWidth : 1920) * 0.19,
        backgroundSize: 'cover',
        // position: 'relative',
        borderRadius: 5,
        padding: 10,
    },
    sliderItemMobile: {
        width: "100%",
        height: (isWindow ? window.innerHeight : 968) * 0.22,
        backgroundSize: 'cover',
        overflow: 'hidden',
        borderRadius: 15,
        position: 'relative'
    },
    sliderImage: {
        width: (isWindow ? window.innerWidth : 1920) * (407 / 1920),
        height: (isWindow ? window.innerWidth : 1920) * (230 / 1920),
        paddingBottom: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },

    // Arrow
    sliderArrow: {
        zIndex: 10,
        width: 100,
        height: 26,
        backgroundColor: 'transparent',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        '&:hover': {
            // background: 'unset',
        },
        '&.next': {
            right: 0,
            transform: 'rotate(180deg)',

        },
        '&.prev': {
            left: 0,
            // transform: 'rotate(90deg)',
        },
        margin: '0 auto',
    },
    buttonSlidePrev: {
        width: '100%',
        textAlign: 'center',
        margin: '13px 0 13px',
        // '&::before': {
        //   content: '""',
        //   width: '70%',
        //   height: '1px',
        //   position: 'absolute',
        //   zIndex: 1,
        //   top: '5%',
        //   left: '15%',
        //   backgroundColor: '#9a9a9aa1',
        // },
        // '&::after': {
        //   content: '"Danh sách thiết kế mẫu"',
        //   position: 'absolute',
        //   width: '55%',
        //   zIndex: 1,
        //   top: '4%',
        //   left: '22.5%',
        //   backgroundColor: '#ffffff',
        //   fontSize: 12,
        //   fontWeight: 500,
        //   lineHeight: 1,
        // },
    },
    buttonSlideNext: {
        width: '100%',
        textAlign: 'center',
        margin: '13px 0 13px',
        // '&::before': {
        //     content: '""',
        //     width: '70%',
        //     height: '1px',
        //     position: 'absolute',
        //     zIndex: 1,
        //     top: '95%',
        //     left: '15%',
        //     backgroundColor: '#9a9a9aa1',
        // },
    },
    //infomation  architect
    '&MuiTypography- body1': {
        lineHeight: 1,
    },
    architectInfo: {
        width: '100%',
        borderRadius: 5,
        height: '100%',
        position: 'relative',
        backgroundSize: 'cover',
        color: '#fff',
        display: 'flex',
        transition: `300ms`,
        top: 0,
        left: 0,
        backgroundColor: '#00000040',
        alignItems: "end",
        paddingBottom: 15,
        background: "linear-gradient(0deg, rgba(0,0,0,0.5998774509803921) 3%, rgba(255,255,255,0) 100%, rgba(0,0,0,0.5) 100%)",
        '&:hover':{
            background: "linear-gradient(0deg, rgba(161,159,159,0.5214460784313726) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
            '& $infomation':{
                color:'#000000'
            },
            '& $plusMore':{
                color:'#6A6A6A'
            }
        }

    },
    plusMore:{
        color:"#FFFFFF",
        padding:0,
        minWidth:"auto",
        fontSize: 12
    },
    architectInfoMobile: {
        width: '100%',
        bottom: 0,
        position: 'absolute',
        top: '51%',
        left: 0,
        backgroundSize: 'cover',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        transition: `300ms`,
        // [theme.breakpoints.down(450)]: {
        //     width: window.innerWidth * 0.8,
        //     height: '55%',
        //     top: '52%',
        // },
        background: "linear-gradient(0deg, rgba(0,0,0,0.5998774509803921) 3%, rgba(255,255,255,0) 100%, rgba(0,0,0,0.5) 100%)"
    },
    architectImage: {
        width: (isWindow ? window.innerWidth : 1920) * (230 / 1920) * 0.4,
        height:  (isWindow ? window.innerWidth : 1920) * (230 / 1920) * 0.4,
        borderRadius: '50%',
        position: 'relative',
        top: - (isWindow ? window.innerWidth : 1920) * (230 / 1920) * 0.05,
        left:  (isWindow ? window.innerWidth : 1920) * (230 / 1920) * 0.05,
        transition: `300ms`,
        objectFit: 'cover',
        boxShadow: theme.shadows[3],
    },
    architectImageIpad: {
        width:  (isWindow ? window.innerWidth : 1920) * (230 / 1920) * 0.6,
        height:  (isWindow ? window.innerWidth : 1920) * (230 / 1920) * 0.6,
        borderRadius: '50%',
        position: 'relative',
        top: - (isWindow ? window.innerWidth : 1920) * (230 / 1920) * 0.05,
        left:  (isWindow ? window.innerWidth : 1920) * (230 / 1920) * 0.05,
        transition: `300ms`,
        objectFit: 'cover',
        boxShadow: theme.shadows[3],
    },
    architectImageMobile: {
        position: 'relative',
        width:  (isWindow ? window.innerWidth : 1920) * 0.20 * 0.4,
        height:  (isWindow ? window.innerWidth : 1920) * 0.20 * 0.4,
        top: - (isWindow ? window.innerWidth : 1920) * 0.225 * 0.05,
        left:  (isWindow ? window.innerWidth : 1920) * 0.225 * 0.05,
        borderRadius: '50%',
        transition: `300ms`,
        objectFit: 'cover',
        boxShadow: theme.shadows[3],
    },
    architectFeatured: {
        width: '33%',
    },
    architectFeaturedMobile: {
        width: '33%',
        [theme.breakpoints.down(450)]: {
            width: '30%',
        },
    },
    infomation: {
        textAlign: 'start',
    },
    text: {
        fontSize: '10px',
        fontWeight: 600,

    },
    textMobile: {
        fontSize: 6,
        fontWeight: 600,

        padding: '0 0 2px',
        marginTop: 4,
        marginBottom: 4
    },
    containerSlideItem: {
        width:  (isWindow ? window.innerWidth : 1920) * (360 / 1920) + 16,
        height:  (isWindow ? window.innerWidth : 1920) * (200 / 1920) + 16,
        padding: 8,
        borderRadius: 5,
        transition: `300ms`,

    },
    containerSlideItemIpad: {
        width:  (isWindow ? window.innerWidth : 1920) * 0.4 + 20,
        height:  (isWindow ? window.innerWidth : 1920) * 0.19 + 20,
        padding: 8,
        borderRadius: 5,
        transition: `300ms`,
        [theme.breakpoints.up(769)]: {
            '&:hover': {
                '& $architectInfo': {
                    height: '100%',
                    top: 0,
                    left: 0,
                    backgroundColor: '#00000040',
                },
                '& $text': {
                    display: 'block',
                    fontSize: '12px',
                },
                '& $architectImage': {
                    width:  (isWindow ? window.innerWidth : 1920) * (230 / 1920) * 0.4,
                    height:  (isWindow ? window.innerWidth : 1920) * (230 / 1920) * 0.4,
                    borderRadius: '50%',
                    position: 'relative',
                    top: 0,
                    left:  (isWindow ? window.innerWidth : 1920) * (230 / 1920) * 0.05,
                    objectFit: 'cover',
                    boxShadow: theme.shadows[3],
                },
                '& $designModelName': {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    transition: `300ms`,
                },
                '& $architectFeatured': {
                    width: '33%',
                },
            },
        },
    },
    containerSlideItemMobile: {
        width: "100%",
        height:  (isWindow ? window.innerWidth : 968) * 0.22 + 20,
        padding: 8,
        borderRadius: 15,
        transition: `300ms`,

    },
    title: {
        position: 'absolute',
        color: '#3B3B3B',
    },
    active: {

        "& $sliderItem, $sliderItemMobile": {
            border: "3px solid #006CD9",
            boxSizing: "border-box",
        }
    },
    activeMobile: {
        boxShadow: theme.shadows[3],
        borderRadius: 5,
    },
    designModelName: {
        fontSize: '14px',
        fontWeight: 600,
        transition: `300ms`,
        // textShadow: '0px 2px 2px #00000029',
        width: '220px',
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: 'ellipsis',
        marginBottom: 5

    },
    designModelNameIpad: {
        fontSize: '16px',
        fontWeight: 'bold',
        transition: `300ms`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textShadow: '0px 2px 2px #00000029',
        width: '250px',
    },
    designModelNameMobile: {
        fontSize: 10,
        fontWeight: 600,
        transition: `300ms`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

    },
    rating: {
        fontSize: '14px',
        lineHeight: 1,
        float: "right",
    },
    ratingMobile: {
        fontSize: 8,
        lineHeight: 1,
    },
    architectName: {
        fontSize: 10,
        fontWeight: 600,
        padding: '2px 0',
        // textShadow: '0px 2px 2px #00000029',
        width: '220px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: "#80BFFF",
        marginBottom: 5
    },
    architectNameMobile: {
        fontSize: 8,
        // fontStyle: 'italic',
        fontWeight: 600,
        padding: '2px 0',
        // textShadow: '0px 2px 2px #00000029',
        color: '#80BFFF',
    },
    style: {
        padding: "4px 8px",
        background: "#E6E6E6",
        borderRadius: 20,
        color: "#6A6A6A",
        fontWeight: 600,
        fontSize: 10,
        marginRight: 8
    },
    popoverInspirations: {
        zIndex: "9999 !important",

        "& .MuiPopover-paper": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 8,
            paddingTop: 0,
            "& span": {
                marginTop: 8
            }
        }
    }
}))
