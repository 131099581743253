/** @format */

import React, {useEffect, useRef, useState} from 'react';
import Slider from 'react-slick';
import {Box, useTheme, Typography} from '@material-ui/core';
import {
    SliderItem,
    SliderNextArrow,
    SliderPrevArrow,
} from './DesignSliderFragments';
import useStyles from './ApartmentSliderStyles';
import useWindowSize from '../../utils/useWindowSize';
import {Skeleton} from "antd";
import {isNotEmptyObject, isWindow} from "../../utils/help";

export default function DesignSlider(props) {

    const {
        portfolios,
        setPortfolioCurrent,
        currentIndex,
        setCurrentIndex,
        matchMobile
    } = props;

    const ref = useRef();
    const classes = useStyles();
    const theme = useTheme();
    const windowSize = useWindowSize();
    const [ratio] = useState(isWindow ? window.innerWidth / window.innerHeight : 1920 / 968);
    const [isWaiting, setIsWaiting] = useState(true) // do slick chua load css kip
    const [settings, setSettings] = useState({
        arrows: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        beforeChange: function (currentSlide, nextSlide) {
        },
        afterChange: function (currentSlide) {
        },
        nextArrow: <SliderNextArrow/>,
        prevArrow: <SliderPrevArrow/>,
    });

    const handleResize = () => {
        if (windowSize.width > theme.breakpoints.values['md']) {
            // > md
            return setSettings((prevSet) => ({
                ...prevSet,
                vertical: true,
                verticalSwiping: true,
                slidesToShow: 3,
                slidesToScroll: 1,
            }));
        }
        if (windowSize.width < theme.breakpoints.values['sm']) {
            // < sm
            return setSettings((prevSet) => ({
                ...prevSet,
                vertical: false,
                verticalSwiping: false,
                slidesToShow: 1,
                slidesToScroll: 1,
            }));
        }
        // between sm - md
        else
            return setSettings((prevSet) => ({
                ...prevSet,
                vertical: true,
                verticalSwiping: true,
                slidesToShow: 3,
                slidesToScroll: 1,
            }));
    };

    useEffect(() => {
        handleResize();
        // eslint-disable-next-line
    }, [isWindow && windowSize.width]);

    useEffect(() => {
        let slickListDiv2 = document.getElementsByClassName(classes.slickPortfolios)[0];
        slickListDiv2?.addEventListener('wheel', event => {
            event.preventDefault()
            event.deltaY > 0 ? ref.current.slickNext() : ref.current.slickPrev()
        })
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setIsWaiting(false)
        }, 1)
    }, []);

    return (
        <div className={`${ratio > 1 ? classes.wrapper : classes.wrapperMobile}`}>
            <Typography
                style={{
                    textAlign: 'left',
                    paddingLeft: 32,
                    fontWeight: 600,
                    fontSize: 16
                }}
            >
                Danh Sách Thiết Kế Mẫu ({portfolios.length} thiết kế)
            </Typography>
            {
                <Slider className={classes.slickPortfolios} ref={ref} {...settings}>
                    {Boolean(!isNotEmptyObject(portfolios) || isWaiting) ? (
                        <Skeleton avatar paragraph={{rows: 3}} className={classes.skeleton}/>
                    ) : portfolios.map((item, index) => (
                        <Box
                            onClick={() => {
                                setCurrentIndex(index);
                                setPortfolioCurrent(item);
                            }}
                            key={index}
                        >
                            <SliderItem
                                index={index}
                                matchMobile={matchMobile}
                                item={item}
                                ratio={ratio}
                                currentIndex={currentIndex}
                            />
                        </Box>
                    ))}
                </Slider>
            }
        </div>
    );
}
