/** @format */

import React, {useEffect, useState} from 'react'
import DesignGallerySlider from '../Slider/DesignGallerySlider/DesignGallerySlider'
import {Box, makeStyles} from '@material-ui/core'
import {DOMAIN_IMAGE} from '../../redux/constants/actionType'
import {SharedBox2} from "../Sidebar/ProjectSidebarFragments";
import {isNotEmptyObject, isWindow} from "../../utils/help";
import {Skeleton} from "antd";
import BlankImage from '../../assets/images/blank-image.jpeg'

const ApartmentPreview = ({
                              portfolioCurrent,
                              apartmentSlug,
                              apartmentCurrent,
                              setShowModal,
                              matchMobile,
                              domainAvail
                          }) => {
    const classes = useStyles()
    const [ratio] = useState(isWindow ? window.innerWidth / window.innerHeight : 1920 / 968)
    const [srcImage, setSrcImage] = useState({})
    const [slide, setSlide] = useState([])
    useEffect(() => {
        if (isNotEmptyObject(portfolioCurrent) && portfolioCurrent?.gallery_images) {
            if (portfolioCurrent.vr_url) {
                const itemVR = {vr_url: portfolioCurrent?.vr_url, url: portfolioCurrent?.featured_image?.url}
                setSrcImage(itemVR)
                setSlide([itemVR].concat(portfolioCurrent.gallery_images))
            } else {
                setSrcImage(portfolioCurrent?.gallery_images[0])
                setSlide(portfolioCurrent?.gallery_images)
            }
        }
    }, [portfolioCurrent])
    return (
        <>
            <Box className={classes.container}>
                {
                    !isNotEmptyObject(portfolioCurrent) ? <img src={BlankImage} width={
                            ratio > 1
                                ? isWindow ? window.innerHeight * (229 / 210) : 968 * (229 / 210)
                                : isWindow ? window.innerWidth * 0.95 : 1920 * 0.95
                        } className={classes.VRPreview}/> :
                        <Box className={classes.preview}>
                            <div
                                className={`${srcImage?.vr_url ? classes.show : classes.hide} ${
                                    ratio > 1 ? classes.vrContainer : classes.vrContainerMobile
                                }`}
                            >
                                <iframe
                                    onMouseEnter={() => {
                                        if (isWindow) {
                                            const { body, documentElement } = document;
                                            const scrollTop = documentElement.scrollTop;
                                            body.style.top = `-${scrollTop}px`;
                                            body.classList.add(`${classes.scrollDisabled}`);
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        if (isWindow) {
                                            const { body, documentElement } = document;
                                            const scrollTop = documentElement.scrollTop;

                                            body.classList.remove(`${classes.scrollDisabled}`);
                                            documentElement.style.scrollBehavior = "auto";
                                            documentElement.scrollTop = scrollTop;
                                            documentElement.style.removeProperty("scroll-behavior");
                                            body.style.removeProperty("top");
                                        }
                                    }}
                                    className={classes.VRPreview}
                                    src={portfolioCurrent?.vr_url}
                                    title='1'
                                    allowFullScreen
                                    frameBorder='0'
                                    width={
                                        ratio > 1
                                            ? isWindow ? window.innerHeight * (229 / 210) : 968 * (229 / 210)
                                            : isWindow ? window.innerWidth * 0.95 : 1920 * 0.95
                                    }
                                />
                            </div>
                            <Box
                                className={`${srcImage?.vr_url ? classes.hide : classes.show} ${
                                    ratio > 1 ? classes.imagePreview : classes.imagePreviewMobile
                                }`}
                                style={{backgroundImage: `url(${DOMAIN_IMAGE + srcImage?.url})`}}
                            />

                            <SharedBox2 domainAvail={domainAvail} matchMobile={matchMobile}
                                        apartmentSlug={apartmentSlug}
                                        setShowModal={setShowModal} className={classes.InfoSocial}
                                        data={portfolioCurrent}
                                        layout={apartmentCurrent}/>
                        </Box>
                }
                {
                    !isNotEmptyObject(portfolioCurrent) ? <Skeleton/> :
                        <Box className={classes.designGallerySlider}>
                            <DesignGallerySlider
                                slide={slide}
                                portfolioCurrent={portfolioCurrent}
                                setSrcImage={setSrcImage}
                            />
                        </Box>
                }
            </Box>
        </>
    )
}

export default ApartmentPreview

const useStyles = makeStyles((theme) => {
    return {
        scrollDisabled: {
            position: 'fixed',
            width: '100%',
            overflowY: 'scroll',
        },

        container: {
            borderRadius: 5,
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        skeletonImage: {
            width: isWindow ? window.innerHeight * (229 / 210) : 968  * (229 / 210),
            height:isWindow ? window.innerHeight * (570 / 1050) : 968 * (570 / 1050)
        },
        InfoSocial: {},
        imagePreview: {
            width: '100% !important',
            height: isWindow ? window.innerHeight * (800 / 936) : 968 * (800 / 936),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            objectFit: 'cover',
        },
        imagePreviewMobile: {
            width:isWindow ? window.innerWidth * 0.95 : 1920 * 0.95,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: isWindow ? window.innerHeight * (570 / 1050) : 968 * (570 / 1050),
            objectFit: 'cover',
        },
        preview: {
            // height: '79%'
            position: "relative",
            height: '100%',
        },
        VRPreview: {
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            // height:isWindow ? window.innerHeight * (800 / 936) : 968  * (800 / 936),
            height: '100%',
            objectFit: 'cover',
        },
        iframe: {
            width:isWindow ? window.innerHeight * (229 / 210) : 968  * (229 / 210),
            height:isWindow ? window.innerHeight * (570 / 1050) : 968  * (570 / 1050),
        },
        vrContainer: {
            // height:isWindow ? window.innerHeight * (800 / 936) : 968 * (800 / 936),
            height: '100%',
            margin: 'auto',
            '&::before': {
                content: '""',
                width: '100%',
                height: '100%',
                position: 'relative',
                zIndex: 1,
                top: '0',
                left: '0',
                backgroundColor: '#000',
            },
        },
        vrContainerMobile: {
            width:isWindow ? window.innerWidth * 0.95 : 1920 * 0.95,
            height: '100%',

            '&::before': {
                content: '""',
                width: '100%',
                height: '100%',
                position: 'relative',
                zIndex: 1,
                top: '0',
                left: '0',
                backgroundColor: '#000',
            },
        },
        show: {
            display: "inherit"
        },
        hide: {
            display: 'none'
        },

        designGallerySlider: {

        },
    }
})
