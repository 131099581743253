/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useState} from 'react'
import {Box, makeStyles} from '@material-ui/core'
import ViewApartmentMobileHeader from './ViewApartmentMobileHeader'
import ViewApartmentMobileInfo from './ViewApartmentMobileInfo'
import ViewApartmentMobilePreview from './ViewApartmentMobilePreview'
import ViewApartmentMobileDesigner from './ViewApartmentMobileDesigner'
// import useWindowOrientation from '../../../utils/useWindowOrientation';
import {getPortFoliosByLayoutDrawings} from '../../../../redux/actions'
// import { useParams, useRouteMatch } from 'react-router';

import {connect} from 'react-redux'
import {isNotEmptyObject, isWindow} from "../../../../utils/help";
import {SharedBox2} from "../../../../components/Sidebar/ProjectSidebarFragments";
import {handleGetPortFoliosByLayoutDrawings, handleGetPortFoliosCurrent} from "../../../../utils/featchDataInitServer";
import {useHistory} from "react-router-dom";

const Viewapartmentmobile = (props) => {
    // const { projectSlug, buildingSlug, apartmentSlug, portfolioId } = useParams();
    const {
        getPortFoliosByLayoutDrawings,
        portfolios,
        isLoading,
        resetState,
        listApartments,
        selectedBuilding,
        project,
        selectedFloor,
        apartmentSlug,
        buildingName,
        projectName,
        projectSlug,
        buildingSlug,
        floorSlug,
        portfolioSlug,
        setShowModal
    } = props
    const classes = useStyles()
    const [apartmentCurrent, setApartmentCurrent] = useState({})
    const [portfolioCurrent, setPortfolioCurrent] = useState({})
    const [isShow, setIsShow] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0);

    const history = useHistory()
    useEffect(() => {
        handleGetPortFoliosByLayoutDrawings(apartmentSlug, listApartments)
            .then(res => {
                if (res?.setApartmentCurrent) setApartmentCurrent(res.setApartmentCurrent)
                if (res?.portfolios) getPortFoliosByLayoutDrawings(res.portfolios)
            })
    }, [apartmentSlug, listApartments])
    useEffect(() => {
        handleGetPortFoliosCurrent(portfolioSlug, portfolios).then(res => {
            if (res?.history && isWindow) {
                history.push(`${window.location.pathname}/${res.history}`)
            }
            if (res?.setPortfolioCurrent) setPortfolioCurrent(res.setPortfolioCurrent)
        })
    }, [portfolios])

    useEffect(() => {
        if (portfolioCurrent?.slug) {
            history.push(`/view/${projectSlug}/${buildingSlug}/${floorSlug}/${apartmentSlug}/${portfolioCurrent?.slug}`)
            const index = portfolios?.findIndex(({id}) => id === portfolioCurrent?.id)
            if (index >= 0) setCurrentIndex(index)
        }

    }, [portfolioCurrent])

    return (
        <Box>
            <Box className={`${classes.container} ${isShow && classes.scrollFixed}`}>
                <Box className={classes.containerItem}>
                    <Box>
                        <ViewApartmentMobileHeader
                            buildingName={buildingName}
                            projectName={projectName}
                            apartmentCurrent={apartmentCurrent}
                            listApartment={listApartments}
                            selectedFloor={selectedFloor}
                            setApartmentCurrent={setApartmentCurrent}
                            projectSlug={projectSlug}
                            buildingSlug={buildingSlug}
                            floorSlug={floorSlug}
                        />
                    </Box>
                    <Box className={classes.titlePortfolio}>
                        {portfolioCurrent?.title}
                    </Box>
                    <Box>
                        <ViewApartmentMobilePreview
                            portfolioCurrent={portfolioCurrent}
                            apartmentCurrent={apartmentCurrent}
                        />
                    </Box>
                    <Box className={classes.button} onClick={() => setIsShow(!isShow)}>
                        Xem thiết kế mẫu khác
                    </Box>
                    <Box>
                        <ViewApartmentMobileInfo
                            apartmentCurrent={apartmentCurrent}
                            portfolioCurrent={portfolioCurrent}
                        />
                    </Box>
                    <Box className={classes.spaceMore}/>

                </Box>
            </Box>
            {
                isShow &&
                <ViewApartmentMobileDesigner
                    isShow={isShow}
                    setIsShow={setIsShow}
                    portfolios={portfolios}
                    portfolioCurrent={portfolioCurrent}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    setPortfolioCurrent={setPortfolioCurrent}
                    apartmentCurrent={apartmentCurrent}
                />
            }
        </Box>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPortFoliosByLayoutDrawings: (data) => {
            dispatch(getPortFoliosByLayoutDrawings.success(data))
        },
    }
}


const mapStateToProps = (state) => {
    return {
        portfolios: state.apartmentLayoutClient.portfolios,
        isLoading: state.apartmentLayoutClient.isLoading,
        resetState: state.apartmentLayoutClient.resetState,
        listApartments: state.floor.listApartments,
        selectedBuilding: state.building.selectedBuilding,
        project: state.project.selectedProject,
        selectedFloor: state.floor.selectedFloor,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Viewapartmentmobile)

const useStyles = makeStyles((theme) => {
    return {
        container: {
            // height: '100vh',
            backgroundColor: '#FAFCFF',
        },
        scrollFixed:{
            height: '100vh',
            overflowY: "hidden"

        },
        containerItem: {
            // position: 'relative',
            // zIndex: 0,
        },
        titlePortfolio: {
            fontWeight: 600,
            fontSize: 14,
            color: "#00030E",
            paddingLeft: 20
        },
        button: {
            fontSize: 16,
            fontWeight: 600,
            textDecoration: 'underline',
            color: '#006CD9',
            textAlign: "right",
            margin: "0px 20px 20px 0px"
        },
        spaceMore:{
            height: 100
        },
    }
})
