/** @format */

import { takeEvery, put } from '@redux-saga/core/effects'
import * as actionType from '../constants/actionType'
import * as action from '../actions'
import * as groupApi from '../../api/groupApi'
// import * as floorLinkApi from '../../api/floorLinkApi'

function* handleGetGroup({ payload }) {
  try {
    const res = yield groupApi.getGroupDetailByBuilding(null, null, {
      BuildingsId: payload.id,
    })
    yield put(action.getGroup.success({ listGroup: res }))
  } catch (error) {
    yield put(action.getGroup.failure(error))
  }
}

function* handleAddGroup({ payload }) {
  try {
    const res = yield groupApi.addGroup(payload.group, null, null)
    yield put(action.addGroup.success(res))
    yield put(action.getGroup.request({ id: payload.buildingId }))
  } catch (error) {
    yield put(action.addGroup.failure(error))
  }
}

function* handleUpdateGroup({ payload }) {
  try {
    const res = yield groupApi.updateGroup(payload.group, null, null)
    // if (res?.list_floor_link) {
    //   const newFloorLink = yield floorLinkApi.getFloorLinkDefault(
    //     null,
    //     { id: res.list_floor_link },
    //     null,
    //   )
    //   if (!newFloorLink?.layoutSrc) {
    //     const resUpdateFloorLink = yield floorLinkApi.updateFloorLink(
    //       {
    //         ...newFloorLink,
    //         buildingId: payload?.buildingId,
    //         layoutSrc: payload.group.layoutSrc,
    //       },
    //       { id: newFloorLink.id },
    //       null,
    //     )
    //   }
    // }
    yield put(action.updateGroup.success(res))
    yield put(action.getFloor.request({ id: payload.buildingId }))
    yield put(action.getGroup.request({ id: payload.buildingId }))
    //Fetch Api
  } catch (error) {
    yield put(action.updateGroup.failure(error))
  }
}
function* handleUpdateGroupFloor({ payload }) {
  try {
    const res = yield groupApi.updateGroupFloor(
      payload.group,
      { id: payload.group.id },
      null,
    )

    yield put(action.updateGroupFloor.success(res))
    yield put(action.getFloor.request({ id: payload.buildingId }))
    yield put(action.getGroup.request({ id: payload.buildingId }))
    //Fetch Api
  } catch (error) {
    yield put(action.updateGroupFloor.failure(error))
  }
}

function* handleDeleteGroup({ payload }) {
  try {
    //Fetch Api
    const res = yield groupApi.deleteGroup({ id: payload.id }, null, null)

    yield put(action.deleteGroup.success(res))
    yield put(action.getGroup.request({ id: payload.buildingId }))
    yield put(action.getFloor.request({ id: payload.buildingId }))
  } catch (error) {
    yield put(action.deleteGroup.failure(error))
  }
}

function* handleRemoveGroupFloor({ payload }) {
  try {
    const res = yield groupApi.removeGroupFloor(payload.listFloor, null, null)

    yield put(action.removeGroupFloor.success(res))
    // yield put(action.getGroup.request({ id: payload.buildingId }))
    // yield put(action.getFloor.request({ id: payload.buildingId }))
  } catch (error) {
    yield put(action.removeGroupFloor.failure(error))
  }
}

const groupSaga = [
  takeEvery(actionType.GET_GROUP.REQUEST, handleGetGroup),
  takeEvery(actionType.ADD_GROUP.REQUEST, handleAddGroup),
  takeEvery(actionType.UPDATE_GROUP.REQUEST, handleUpdateGroup),
  takeEvery(actionType.UPDATE_GROUP_FLOOR.REQUEST, handleUpdateGroupFloor),
  takeEvery(actionType.DELETE_GROUP.REQUEST, handleDeleteGroup),
  takeEvery(actionType.REMOVE_GROUP_FLOOR.REQUEST, handleRemoveGroupFloor),
]

export default groupSaga
