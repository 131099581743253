/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import {
    Box,
    Button,
    Divider,
    Typography,
    ListItem,
    ButtonBase,
    List,
} from '@material-ui/core'
import React from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import useStyles from './FloorSidebarStyles'
import FullScreenLoading from '../common/Loading/FullScreenLoading'
import * as action from "../../redux/actions";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {isWindow, substringCustom} from "../../utils/help";

function FloorSidebar({
                          projectName,
                          buildingName,
                          listFloor,
                          showFloorSidebar,
                          setShowFloorSidebar,
                          selectedFloor,
                          setSelectedFloor,
                          projectSlug,
                          buildingSlug,
                          ...rest
                      }) {
    const classes = useStyles()
    const history = useHistory()
    const handleSelectFloor = (item) => {
        setSelectedFloor(item)
        history.push(`${isWindow && window.location.pathname}/${item?.floorID}`)
    }
    const MAX_LENGTH = 15

    const renderFloorList = (item, index) => (
        <ListItem className={classes.listItem} key={index}>
            <ButtonBase
                className={`${classes.itemBtn}`}
                onClick={() => handleSelectFloor(item)}
                disabled={
                    !item?.list_floor_link ||
                    item?.list_floor_link?.layoutCoordinates.length <= 0
                }
            >
                {item.floorID}
            </ButtonBase>
        </ListItem>
    )

    return rest.isFetching ? (
        <FullScreenLoading/>
    ) : (
        <Box className={`${classes.floorSidebar} ${showFloorSidebar && 'show'}`}>
            <Box className={classes.navBar}>
                <div
                    className={classes.backBtn}
                    onClick={() => setShowFloorSidebar(false)}
                >
                    <ArrowBackIcon/>
                </div>
                <Box className={classes.title}>
                    <Typography className={classes.titleBottom}>Chọn tầng</Typography>
                    <Box className={classes.titleTop}>
                        <Typography className={classes.titleText} variant='caption'>
                           Tòa {substringCustom(buildingName, MAX_LENGTH)}
                        </Typography>
                        <Divider
                            orientation='vertical'
                            flexItem
                            className={classes.divideTitle}
                        />
                        <Typography className={classes.titleText} variant='caption'>
                           Dự án {substringCustom(projectName, MAX_LENGTH)}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {/*<Divider/>*/}
            <Box>
                <Box className={classes.listWrapper}>
                    <List className={classes.list} id={'listNum'}>
                        {listFloor && listFloor.map(renderFloorList)}
                    </List>
                </Box>
            </Box>
        </Box>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedFloor: (data) => {
            dispatch(action.setSelectedFloor.success(data))
        },
    }
}
const mapStateToProps = (state) => {
    return {
        project: state.project.selectedProject,
        featuredImage: state.building.selectedBuilding?.overview_image,
        listFloor: state.floor.listFloor,
        selectedFloor: state.floor.selectedFloor
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FloorSidebar)
