/** @format */

import {takeEvery, put} from '@redux-saga/core/effects'
import * as actionType from '../constants/actionType'
import * as action from '../actions'
import * as floorApi from '../../api/floorApi'
import {getFloorDetailByClient} from "../../api/floorApi";


function* handleGetFloorDetailByClient({payload}) {
    try {
        const queries = {
            id_in: payload
        }
        // fetchApi
        let res = yield getFloorDetailByClient(
            null,
            null,
            queries,
        )
        if (res.error) throw res
        yield put(
            action.getFloorDetailByClient.success({
                listApartments: res.filter((items) => items?.published_at)
            }),
        )
    } catch (error) {
        yield put(action.getFloorDetailByClient.failure(error))
    }
}

function* handleGetFloor({payload}) {
    try {
        const res = yield floorApi.getFloorDetailByBuilding(null, null, {
            buildingId: payload.id,
        })
        yield put(action.getFloor.success({listFloor: res}))
    } catch (error) {
        yield put(action.getFloor.failure(error))
    }
}

function* handleAddFloor({payload}) {
    try {
        //Fetch Api
        const res = yield floorApi.addFloorList(payload.listFloor, null, null)
        yield put(action.addFloor.success({listFloor: res}))
        yield put(action.getFloor.request({id: payload.buildingId}))
    } catch (error) {
        yield put(action.addFloor.failure(error))
    }
}

function* handleUpdateFloor({payload}) {
    try {
        //Fetch Api
        const res = yield floorApi.updateFloor(
            payload?.floor,
            {id: payload?.floor?.id},
            null,
        )
        yield put(action.updateFloor.success(res))
        yield put(action.getFloor.request({id: payload.buildingId}))
    } catch (error) {
        yield put(action.updateFloor.failure(error))
    }
}

function* handleDeleteFloor({payload}) {
    try {
        //Fetch Api
        const res = yield floorApi.deleteFloor({id: payload?.id}, null, null)

        yield put(action.deleteFloor.success(res))
        yield put(action.getFloor.request({id: payload?.buildingId}))
    } catch (error) {
        yield put(action.deleteFloor.failure(error))
    }
}

function* handleGetLayout({payload}) {
    try {
        //Fetch Api
        const res = yield floorApi.getLayout(null, null, null)

        yield put(action.getLayout.success(res))
    } catch (error) {
        yield put(action.getLayout.failure(error))
    }
}

const floorSaga = [
    takeEvery(
        actionType.GET_FLOOR_DETAIL_BY_CLIENT.REQUEST,
        handleGetFloorDetailByClient,
    ),
    takeEvery(actionType.GET_FLOOR.REQUEST, handleGetFloor),
    takeEvery(actionType.ADD_FLOOR.REQUEST, handleAddFloor),
    takeEvery(actionType.UPDATE_FLOOR.REQUEST, handleUpdateFloor),
    takeEvery(actionType.DELETE_FLOOR.REQUEST, handleDeleteFloor),

    takeEvery(actionType.GET_LAYOUT.REQUEST, handleGetLayout),
    // takeEvery(actionType.GET_LIST_FLOOR_BY_BUILDING.REQUEST, getListFloorByBuilding),
]

export default floorSaga
