import configApi from "../utils/configApi"
import uselocalWorker from './../utils/localWorker';
import { DOMAIN } from '../redux/constants/actionType';
const localWorker = uselocalWorker()
const token = localWorker.getToken()
export const getApartmentLayoutAll = configApi({
    baseUrl: DOMAIN,
    path: "content-manager/collection-types/application::layouts.layouts",
    method: 'GET',
    headers: { "Authorization": `Bearer ${token}` }
})

export const addApartmentLayoutApi = configApi({
    baseUrl: DOMAIN,
    path: `content-manager/collection-types/application::layouts.layouts`,
    method: 'POST',
    headers: {
        "Authorization": `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
})

export const updateApartmentLayoutApi = configApi({
    baseUrl: DOMAIN,
    path: `content-manager/collection-types/application::layouts.layouts`,
    method: 'PUT',
    headers: {
        "Authorization": `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
})

export const deleteApartmentLayoutApi = configApi({
    baseUrl: DOMAIN,
    path: `content-manager/collection-types/application::layouts.layouts/actions/bulkDelete`,
    method: 'POST',
    headers: {
        "Authorization": `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
})

export const updateStatusApartmentLayoutApi = configApi({
    // baseUrl: DOMAIN,
    baseUrl: DOMAIN,
    path: `layouts/changeStatus`,
    method: 'POST',
    headers: {
        "Authorization": `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
})
export const getLayouts = configApi({
    method: 'GET',
    baseUrl: DOMAIN,
    path: 'layouts',
});

export const getPortFoliosByLayoutDrawings = configApi({
    baseUrl: DOMAIN,
    path: `portfolios/layouts_drawings`,
    method: 'GET',
})
export const getFloorLink = configApi({
    baseUrl: DOMAIN,
    path: `list-floors/search `,
    method: 'POST',
    headers: {
        "Authorization": `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
})
export const porfoliosApi = configApi({
    baseUrl: DOMAIN,
    path: `portfolios/layouts_drawings`,
    method: 'GET',
    headers: {
        "Authorization": `Bearer ${token}`,
        // 'Content-Type': 'application/json'
    }
})

export const getPortfolios = configApi({
    baseUrl: DOMAIN,
    path: `portfolios`,
    method: 'GET',
})
export const getLayoutByBuildingApi = configApi({
    baseUrl: DOMAIN,
    path: `content-manager/collection-types/application::layouts.layouts`,
    method: 'GET',
    headers: {
        "Authorization": `Bearer ${token}`,
        // 'Content-Type': 'application/json'
    }
})

export const generateSlugApi = configApi({
    baseUrl: DOMAIN,
    path: `content-manager/uid/generate`,
    method: 'POST',
    headers: {
        "Authorization": `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
})
export const updateLayoutDrawingAPi = configApi({
    baseUrl: DOMAIN,
    path: `content-manager/collection-types/application::layout-drawings.layout-drawings`,
    method: 'PUT',
    headers: {
        "Authorization": `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
})

export const layoutDrawingDetail = configApi({
    baseUrl: DOMAIN,
    path: 'content-manager/collection-types/application::layout-drawings.layout-drawings',
    method: 'GET',
    headers: { "Authorization": `Bearer ${token}` }
})

export const addLayoutDrawings = configApi({
    baseUrl: DOMAIN,
    path: 'content-manager/collection-types/application::layout-drawings.layout-drawings',
    method: 'POST',
    headers: {
        "Authorization": `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
})

