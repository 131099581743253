/** @format */

import {makeStyles} from '@material-ui/core'
import VRIcon from '../../../assets/images/v2/VR-View.svg'
import IconNaviSVG from '../../../assets/images/Image-61.svg'
import {isWindow} from "../../../utils/help";

export default makeStyles((theme) => ({
    // ApartmentSlider
    slideWrapper: {
        position: 'relative',
        backgroundColor: "#F2F2F2",
        paddingTop: 14,
        // paddingBottom: 10,
        paddingLeft:isWindow ? window.innerHeight * (100 / 1050) : 968 * (100 / 1050),
        paddingRight:isWindow ? window.innerHeight * (100 / 1050) : 968 * (100 / 1050),
        // width: '100%',
        // height: "auto",
        // backgroundColor: '#F2F2F2',
        '& .swiper-container': {
            // overflow: "visible",
            //     display: "flex",
            //     justifyContent: "center",
            //     alignItems: "center",
            //     '& .swiper-button-next': {
            //         order: 1
            //     },
            '& .swiper-button-next, .swiper-button-prev': {
                // top:"45%",
                // position: "unset"
            }
        },
        // '& .slick-slider ': {
        //     display: 'flex',
        //     alignItems: 'center',
        // },
        '& .slick-active ': {
            // minWidth: '142px  !important'
        },
        // '& .slick-slide ': {
        //     width: `auto !important`,
        // },

        "& .swiper-slide": {

        },
        "& .swiper-wrapper": {

        }
    },
    swiperButtonPrev: {
        position:'absolute',
        left:isWindow ? window.innerHeight * (50 / 1050) : 968  * (50 / 1050),
        backgroundImage: `url(${IconNaviSVG})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        backgroundPosition: "center",
        transform: "rotate(180deg)",
        "&:::after": {
            display: "none"
        }
    },
    swiperButtonNext: {
        right:isWindow ? window.innerHeight * (50 / 1050) : 968  * (50 / 1050),
        backgroundImage: `url(${IconNaviSVG})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        backgroundPosition: "center",
        "&:::after": {
            display: "none"
        }
    },
    slideWrapperMobile: {
        // overflow: 'hidden',
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom:isWindow ? window.innerHeight * (17 / 1050) : 968  * (17 / 1050),
        width:isWindow ? window.innerWidth * 0.95 :968  * 0.95,
        height:isWindow ? window.innerHeight * (159 / 1050) : 968 * (159 / 1050),
        backgroundColor: 'rgb(256,256,256)',
        '& .slick-slider ': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .slick-active ': {
            // minWidth: '142px  !important'
        },
        '& .slick-slide ': {
            width: `auto !important`,
        },
    },
    sliderItem: {
        width: "100%",
        height: "100%",
        padding: '0 10px',
        borderRadius: 5,
        transition: `300ms`,
        overflow: 'hidden',
        "& $sliderImageActive , $VRActive": {
            height:isWindow ? window.innerHeight * (136 / 1050) : 968  * (136 / 1050),
            borderRadius: 10,
            paddingBottom: 5,
            overflow: 'hidden',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            border: "3px solid #006CD9",
            boxSizing: "border - box",
            // '&::before': {
            //     content: '"Đang xem"',
            //     top: '64%',
            //     left: '8px',
            //     width: ' calc(100% - 16px)',
            //     height: '30%',
            //     zIndex: 10,
            //     position: 'absolute',
            //     backgroundColor: '#272322b3',
            //     borderBottomLeftRadius: '5px',
            //     borderBottomRightRadius: '5px',
            //     color: 'white',
            //     textAlign: 'center',
            //     fontSize: '11px',
            //     paddingTop: '10%',
            //     lineHeight: '10%',
            // },
        },
    },
    sliderImage: {
        height:isWindow ? window.innerHeight * (136 / 1050) : 968 * (136 / 1050),
        width: "100%",
        boxShadow: theme.shadows[3],
        borderRadius: 10,
        paddingBottom: 5,
        overflow: 'hidden',
        backgroundPosition: 'center',
        backgroundSize: 'cover',

    },
    sliderImageActive: {},
    VRImage: {
        height:isWindow ? window.innerHeight * (136 / 1050) : 968 * (136 / 1050),
        boxShadow: theme.shadows[3],
        borderRadius: 5,
        paddingBottom: 5,
        overflow: 'hidden',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        // '&::after': {
        //     top: '18%',
        //     right: '15%',
        //     color: 'white',
        //     width: '20%',
        //     height: '20%',
        //     fontWeight: 800,
        //     textAlign: 'center',
        //     content: '"VR"',
        //     fontStyle: 'italic',
        //     zIndex: 10,
        //     position: 'absolute',
        //     // fontSize: '11px',
        //     backgroundColor: '#66615fb3',
        //     fontSize: '15px',
        //     paddingTop: '1px',
        //     borderRadius: '5px',
        // },
    },
    VRItem: {
        '&::after': {
            top: '18%',
            left: '15%',
            color: 'white',
            width: 40,
            height: 40,
            // fontWeight: 800,
            textAlign: 'center',
            content: '""',
            // fontStyle: 'italic',
            zIndex: 10,
            position: 'absolute',
            //   fontSize: '11px',
            backgroundImage: `url(${VRIcon})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain"
            // fontSize: '15px',
            // borderRadius: '5px',
            // paddingTop: '1px',
        },
    },
    VRActive: {
        height:isWindow ? window.innerHeight * (136 / 1050) : 968 * (136 / 1050),
        boxShadow: theme.shadows[3],
        borderRadius: 5,
        paddingBottom: 5,
        overflow: 'hidden',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        // '&::before': {
        //     content: '"Đang xem"',
        //     top: '64%',
        //     left: '8px',
        //     width: ' calc(100% - 16px)',
        //     height: '30%',
        //     zIndex: 10,
        //     position: 'absolute',
        //     backgroundColor: '#272322b3',
        //     borderBottomLeftRadius: '5px',
        //     borderBottomRightRadius: '5px',
        //     color: 'white',
        //     textAlign: 'center',
        //     fontSize: '11px',
        //     paddingTop: '10%',
        //     lineHeight: '10%',
        // },
        // '&::after': {
        //     top: '18%',
        //     right: '15%',
        //     color: 'white',
        //     width: '20%',
        //     height: '20%',
        //     fontWeight: 800,
        //     textAlign: 'center',
        //     content: '"VR"',
        //     fontStyle: 'italic',
        //     zIndex: 10,
        //     position: 'absolute',
        //     //   fontSize: '11px',
        //     backgroundColor: '#66615fb3',
        //     fontSize: '15px',
        //     borderRadius: '5px',
        //     paddingTop: '1px',
        // },
    },

    // Arrow
    sliderArrow: {
        zIndex: 10,
        width: 90,
        height: 30,
        backgroundColor: 'transparent',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        '&:hover': {
            // background: 'unset',
        },
        '&.next': {
            right: 0,
        },
        '&.prev': {
            left: 0,
            transform: 'rotate(180deg)',
        },
        [theme.breakpoints.down('426')]: {
            width: 140,
            height: 70,
        },
    },
    active: {

    },
    button: {
        width: '100%',
    },
    // 'swiper-container': {
    //     height: '5000px',
    // },
}))
