import {createStore, applyMiddleware, compose} from 'redux'
import createSagaMiddleware from '@redux-saga/core'
import rootReducer from '../reducers/rootReducer'
import rootSaga from '../sagas/rootSaga'
import history from '../../utils/history';
import { routerMiddleware as createRouterMiddleware } from 'react-router-redux'
const sagaMiddleware = createSagaMiddleware();
const routerMiddleware = createRouterMiddleware(history);

const middlewares = [sagaMiddleware, routerMiddleware];

const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

export const configureStore = preloadedState => {

    if (typeof window !== 'undefined' && window.__PRELOADED_STATE__) {
        preloadedState = window.__PRELOADED_STATE__;
    }
    const store = createStore(
        rootReducer,
        preloadedState,
        composeEnhancers(
            applyMiddleware(...middlewares)
        )
    );

    sagaMiddleware.run(rootSaga)

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers/rootReducer', () => {
            const nextRootReducer = require('../reducers/rootReducer').default;
            store.replaceReducer(nextRootReducer);
        });
    }
    return store;
};
