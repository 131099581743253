/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react'
import {Box, TextField} from '@material-ui/core'
import {ProjectInfo, SharedBox} from './ProjectSidebarFragments'
import BuildingFigure from '../Figure/BuildingFigure'
import useStyles from './ProjectSidebarStyles'
import {createComponent} from '../../utils/createComponent'
import * as action from "../../redux/actions";
import {connect} from "react-redux";
import {Autocomplete} from "@material-ui/lab";
import {isNotEmptyObject} from "../../utils/help";
import {useHistory} from "react-router-dom";

function ProjectSidebar({
                            project,
                            handleToggle,
                            listBuildings,
                            projectSlug,
                            imgSize,
                            selectedBuilding,
                            setSelectedBuilding,
                            setShowModal,
                            domainAvail,
                            ...rest
                        }) {
    // hook and props
    const classes = useStyles()
    const history = useHistory()

    return rest?.isFetching ? (
        <></>
        // <FullScreenLoading />
    ) : (
        <Box className={classes.projectSidebar}>
            <Box>
                <ProjectInfo
                    name={project?.name || ''}
                    investor={
                        (project && project.investor && project?.investor?.name) || ''
                    }
                    address={`${project?.address} ,  ${
                        project && project?.ward && project?.ward?.path_with_type
                    }`}
                />
                <BuildingTabs className={classes.buildingTabs}>
                    <TabHeader className={classes.tabHeader}>
                        <div>Chọn toà nhà</div>
                        {isNotEmptyObject(selectedBuilding) &&
                        <Autocomplete
                            style={{width: '60%' ,backgroundColor:"#FDFDFD"}}
                            disableClearable
                            onChange={(event, newValue) => {
                                setSelectedBuilding(newValue);
                                history.push(`/view/${projectSlug}/${newValue?.slug}`)
                            }}
                            options={listBuildings}
                            getOptionLabel={(option) => option?.name || ''}
                            getOptionDisabled={option => !isNotEmptyObject(option?.list_floors)}
                            defaultValue={selectedBuilding}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined"/>
                            )}
                        />
                        }

                    </TabHeader>
                    <TabFigure className={classes.tabFigure}>
                        <BuildingFigure
                            buildingId={selectedBuilding?.id}
                            imgSize={imgSize}
                            handleToggle={handleToggle}
                            apartmentSlug={rest.apartmentSlug}
                        />
                    </TabFigure>
                </BuildingTabs>
            </Box>
            <Box>
                <SharedBox domainAvail={domainAvail} setShowModal={setShowModal} project={project}/>
            </Box>
        </Box>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedBuilding: (data) => {
            dispatch(action.setSelectedBuilding.success(data))
        },

    }
}
const mapStateToProps = (state) => {
    return {
        project: state.project.selectedProject,
        listBuildings: state.building.listBuildings,
        isFetching: state.building.isFetching,
        selectedBuilding: state.building.selectedBuilding
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSidebar)


const BuildingTabs = createComponent()
const TabHeader = createComponent()
const TabFigure = createComponent()
