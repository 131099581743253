/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useState} from 'react'
// import Slider from 'react-slick';
import {Box} from '@material-ui/core'
import {
    SliderItem,
    // SliderNextArrow,
    // SliderPrevArrow,
} from './DesignGallerySliderFragment'
import useStyles from './DesignGallerySliderStyles'
import useWindowSize from '../../../utils/useWindowSize'
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y,Mousewheel} from 'swiper'
import {isWindow} from "../../../utils/help";

// import './customerSliderGalarry.css'

SwiperCore.use([Navigation, Pagination,Mousewheel])
export default function DesignGallerySlider({
                                                slide,
                                                portfolioCurrent,
                                                setSrcImage,
                                            }) {
    const classes = useStyles()
    const windowSize = useWindowSize()
    const [currentIndex, setCurrentIndex] = useState(0)
    const [numSlider, setNumSlider] = useState(5)
    const [ratio, setRatio] = useState(isWindow ? window.innerWidth / window.innerHeight : 1920/968)
    useEffect(() => {
        handleResize()
        // eslint-disable-next-line
    }, [windowSize.width])

    useEffect(() => {
        setCurrentIndex(0)
    }, [portfolioCurrent])

    useEffect(() => {
        setRatio(isWindow ? window.innerWidth / window.innerHeight : 1920/968)
    }, [isWindow && window?.innerWidth,isWindow && window?.innerHeight])

    const handleResize = () => {
        if (windowSize.width / windowSize.height <= 3 / 4) {
            setNumSlider(3)
        }
    }

    return (
        <div
            className={`${
                ratio > 1 ? classes.slideWrapper : classes.slideWrapperMobile
            }`}
        >
            <div className={`${classes.swiperButtonPrev} swiper-button-prev`}/>
            <Swiper
                // modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={(isWindow ? window.innerWidth : 1920) * 16 / 1920}
                slidesPerView={numSlider}
                slidesPerGroup={1}
                navigation={{
                    nextEl: `.${classes.swiperButtonNext}`,
                    prevEl: `.${classes.swiperButtonPrev}`,
                }}
                // pagination={{
                //   clickable: true,
                //   "dynamicBullets": true,
                // }}
                // pagination={colors = 'white'}
                style={{
                    height: isWindow ? window.innerHeight * (162 / 1050) : 968  * (162 / 1050),
                    marginTop:isWindow ? window.innerHeight * (1 / 1050) : 968 * (1 / 1050),
                    // maxWidth: '90%'
                }}
            >
                {slide &&
                slide.map((item, index) => (
                    <SwiperSlide key={`portfolio_${index}`}>
                        <Box
                            onClick={() => {
                                setCurrentIndex(index)
                                setSrcImage(item)
                            }}
                            className={classes.button}
                        >
                            <SliderItem
                                index={index}
                                item={item}
                                currentIndex={currentIndex}
                            />
                        </Box>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className={`${classes.swiperButtonNext} swiper-button-next`}/>
        </div>
    )
}
