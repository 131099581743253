import React from 'react';
import {hydrate} from 'react-dom';
import theme from './theme/theme';
import {ThemeProvider} from '@material-ui/styles';
import cssVariables from "./theme/variables";
import {CssBaseline} from "@material-ui/core";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import {configureStore} from "./redux/store/configureStore";
const store = configureStore( window.__PRELOADED_STATE__);
hydrate(
    <Provider store={store}>
        <ThemeProvider theme={{...theme, ...cssVariables}}>
            <CssBaseline/>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root'),
    () => {
        // [ReHydratation](https://github.com/cssinjs/jss/blob/master/docs/ssr.md)
        const jssStyles = document.getElementById('jss-ssr');
        if (jssStyles && jssStyles.parentNode)
            jssStyles.parentNode.removeChild(jssStyles);
    }
);

if (module.hot) {
    module.hot.accept()
   /* module.hot.accept('./App', () => {
        hydrate(
            <Provider store={store}>
                <ThemeProvider theme={{...theme, ...cssVariables}}>
                    <CssBaseline/>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </ThemeProvider>
            </Provider>,
            document.getElementById('root')
        );
    });*/
}