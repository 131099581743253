/** @format */

import { DOMAIN } from '../redux/constants/actionType';
import configApi from '../utils/configApi';
import LocalWorker from './../utils/localWorker';
const localWorker = LocalWorker();
const token = localWorker.getToken();

export const getFloorLink = configApi({
  method: 'POST',
  baseUrl: DOMAIN,
  path: 'list-floor-links/building',
});
export const getFloorLinkDefault = configApi({
  method: 'GET',
  baseUrl: DOMAIN,
  path: 'list-floor-links',
});

// export const addGroup = configApi({
//   method: 'POST',
//   baseUrl: DOMAIN,
//   path: 'list-groups',
// });

export const updateFloorLink = configApi({
  method: 'PUT',
  baseUrl: DOMAIN,
  path: 'list-floor-links',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});

// export const deleteGroup = configApi({
//   method: 'DELETE',
//   baseUrl: DOMAIN,
//   path: 'list-groups',
// });
