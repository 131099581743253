/** @format */
import axios from 'axios';
import {notification, message} from 'antd';
import qs from 'qs';
import {values} from "lodash";
import {isNotEmptyObject} from "./help";

const service = axios.create({
  timeout: 60000,
  paramsSerializer: (params) => {
    return qs.stringify(params, {arrayFormat: 'repeat'});
  },
});

service.interceptors.request.use(
    (config) => config,
    (error) => {
      // Do something with request error here
      notification.error({
        message: 'Error',
      });
      Promise.reject(error);
    },
);

// API respone interceptor
service.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      let notificationParam = {
        message: '',
      };

      // Remove token and redirect
      if (error.hasOwnProperty('response') && error.response !== undefined && error.response) {
        notification.destroy()
        if (error.response.status === 400) {
          notificationParam.message = 400;
          notificationParam.description = 'Có lỗi xảy ra';
        }
        if (error.response.status === 401) {
          notificationParam.message = 'Xác thực không thành công';
          notificationParam.description = 'Vui lòng đăng nhập lại';
        }

        if (error.response.status === 403) {
          notificationParam.message = `Permission: ${error.response.data.message}`;
        }
        if (error.response.status === 428) {
          // yêu cầu điều kiện
          console.log(error.response)
          message.warning(`Yêu cầu: ${error.response.data.message}`);
          return Promise.reject();

        }

        if (error.response.status === 404) {
          notificationParam.message = 404;
          notificationParam.message = 'Không tìm thấy trang';
        }

        if (error.response.status === 500) {
          notificationParam.message = 'Có lỗi xảy ra ở phía máy chủ';
        }

        if (error.response.status === 508) {
          notificationParam.message = 'Kết lối bị gián đoạn';
        }

        notification.error(notificationParam);
      }

      return Promise.reject(error);
    },
);

const configApi = ({baseUrl, path, method, headers, ...props}) => {
  const url = path ? `${baseUrl}/${path}` : baseUrl
  return function api(data, pathParams, queryParams) {
    const _url = isNotEmptyObject(pathParams) ? `${url}/${values(pathParams)[0]}` : url
    return service({
      url: _url,
      method: method,
      headers,
      data: data,
      params: queryParams
    })
  }
}

export default configApi
