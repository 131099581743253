/** @format */

import { combineReducers } from 'redux';
import userReducer from './userReducer';
import filterReducer from './filterReducer';
import buildingReducer from './buildingReducer';
import projectReducer from './projectReducer';
import apartmentLayoutReducer from './apartmentLayoutReducer';
import floorReducer from './floorReducer';
import apartmentLayoutClientReducer from './apartmentLayoutClientReducer';
import groupReducer from './groupReducer';
import floorLinkReducer from './floorLinkReducer';
export default combineReducers({
  user: userReducer,
  filter: filterReducer,
  building: buildingReducer,
  project: projectReducer,
  apartmentLayout: apartmentLayoutReducer,
  apartmentLayoutClient: apartmentLayoutClientReducer,
  floor: floorReducer,
  group: groupReducer,
  floorLink: floorLinkReducer,
});
