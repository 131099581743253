import React, {useEffect, useState} from 'react'
import {
    makeStyles,
    Paper,
    Box,
    IconButton,
    Grid,
    styled,
    alpha,
    TextField,
    Button
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Overlay from '../Overlay/Overlay'
import ImageBg from "../../../assets/images/v2/bg-lhtv.png"
import {isNotEmptyObject} from "../../../utils/help";
import _ from 'lodash'
import {fetchBookingApi} from "../../../api/bookingApi";
import AvatarErr from '../../../assets/images/v2/image-not-avalible.png'
import {DOMAIN_IMAGE} from "../../../redux/constants/actionType";
import {Alert} from "@material-ui/lab";
import {Form, Input} from "antd";

const {TextArea} = Input;
export default function ModalRegister({
                                          matchMobile,
                                          apartmentSlug,
                                          staff,
                                          show,
                                          handleClose,
                                          fullWidth,
                                          children,
                                          ...props
                                      }) {
    const classes = useStyles()
    const [form] = Form.useForm()
    const [layout, setLayout] = useState(null)
    const [isSubmit, setIsSubmit] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const handleSubmit = async (values) => {
        if (!isSubmit) {
            setIsSubmit(true)
            try {
                const _data = {
                    layout: layout,
                    project: props?.project?.id,
                    building: props?.selectedBuilding?.id,
                    full_name: values?.name,
                    email: values?.email,
                    phone: values?.phone,
                    desire: values?.mes,
                    domain_referrer: props?.domain,
                }
                const _post = await fetchBookingApi(_.pickBy(_data, _.identity),
                    null,
                    null
                )
                if (_post) {
                    setIsSuccess(true)
                    setTimeout(() => form.resetFields(), 500)
                }
            } catch (e) {
                console.log(e)
            }
            setIsSubmit(false)
        }

    }

    useEffect(() => {
        if (apartmentSlug) {
            const _layoutID = props?.listApartments?.filter(({slug}) => slug === apartmentSlug)
            if (isNotEmptyObject(_layoutID))
                setLayout(_layoutID[0]?.id)
        }
    }, [])

    return (

        <Box className={classes.wrapper} style={{display: show ? 'flex' : 'none'}}>
            <Paper className={`${classes.modal} ${fullWidth && !matchMobile && 'fullWidth'}`} elevation={5}>
                <IconButton className={classes.modalIcon} onClick={() => handleClose(!show)}>
                    <CloseIcon/>
                </IconButton>
                <Box className={classes.modalBody}>
                    <Grid container spacing={2}>
                        <Grid className={classes.modalBodyLeft} item xs={12} lg={6} md={6}>
                            <Box className={classes.staffs}>
                                <img width={64} height={64} className={classes.avatar}
                                     src={staff?.avatar ? DOMAIN_IMAGE + staff?.avatar?.url : AvatarErr}
                                     alt={staff?.name}/>
                                <div className={classes.staffsContent}>
                                    <div className={classes.staffsContent1}>Nhân viên tư vấn</div>
                                    <div className={classes.staffsContent2}>{staff?.name}</div>
                                </div>

                            </Box>
                            {isSuccess &&
                                <Alert severity="success">Yêu cầu của quý khách đã được ghi nhận. Chúng tôi sẽ liên
                                    hệ
                                    lại trong thời gian sớm nhất</Alert>
                            }
                            <Form
                                layout="vertical"
                                form={form}
                                initialValues={{}}
                                onFinish={handleSubmit}
                            >
                                <Form.Item name="name" label={"Họ tên của bạn"} rules={[
                                    {
                                        required: true,
                                        message: 'Yêu cầu điền Tên'
                                    },
                                    {
                                        max: 50,
                                        message: "Tối đa 50 ký tự"
                                    },
                                ]}>
                                    <Input placeholder="Nhập tên của bạn"/>
                                </Form.Item>

                                <Form.Item name="email" label={"Email của bạn"} rules={[
                                    {
                                        required: true,
                                        message: 'Yêu cầu điền email'
                                    },
                                    {
                                        max: 50,
                                        message: "Tối đa 50 ký tự"
                                    },
                                    {
                                        pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                        message: "Không đúng định dạng Email"
                                    }
                                ]}>
                                    <Input placeholder="Example@domain.com"/>
                                </Form.Item>

                                <Form.Item name="phone" label={"Số điện thoại của bạn"} rules={[
                                    {
                                        required: true,
                                        message: 'Yêu cầu điền số điện thoại'
                                    },
                                    {
                                        max: 15,
                                        message: "Tối đa 15 ký tự"
                                    },
                                    {
                                        pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
                                        message: "Không đúng định dạng số điện thoại"
                                    }
                                ]}>
                                    <Input placeholder="(+84)"/>
                                </Form.Item>


                                <Form.Item name="mes" label={"Lời nhắn của bạn"} rules={[
                                    {
                                        max: 300,
                                        message: "Tối đa 300 ký tự"
                                    }
                                ]}>
                                    <TextArea rows={4} placeholder="Lời nhắn của bạn" maxLength={300}/>
                                </Form.Item>
                            </Form>
                            <Box style={{textAlign: 'center'}}>
                                <Button disabled={isSubmit} className={classes.submitBooking} onClick={() => form.submit()}
                                        variant="contained">ĐẶT LỊCH TƯ VẤN</Button>
                            </Box>

                        </Grid>
                        {
                            !matchMobile
                            &&
                            <Grid className={classes.modalBodyRight} item xs={0} lg={6} md={6}>

                            </Grid>
                        }
                    </Grid>
                </Box>
            </Paper>
            <Overlay handleClose={() => handleClose(!show)}/>
        </Box>
    )
}
const BootstrapInput = styled(TextField)(({theme}) => ({
    '& .MuiInput-underline': {
        '&:after, &:before': {
            content: 'unset'
        },
        '&.Mui-error': {
            '& .MuiInputBase-input': {
                border: '1px solid #f44336',
            }
        }
    },
    'label + &': {
        marginTop: theme.spacing(3),
        // fontSize: 16,
        // fontWeight: 600,
        // color: '#00030E',
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        backgroundColor: '#FDFDFD',
        border: '1px solid #C1C1C1',
        fontSize: 14,
        fontWeight: 400,
        borderRadius: 10,
        width: '100%',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),

        '&::placeholder': {
            fontSize: 14,
            fontWeight: 400,
            color: '#A8A8A8',
        },
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'none',
        zIndex: 10
    },
    modal: {
        position: 'relative',
        overflow: 'hidden',
        zIndex: 2,
        background: "#F2F2F2",
        borderRadius: 20,
        width: window.innerWidth / 1920 * 700,
        minWidth: 500,
        '&.fullWidth': {
            maxWidth: '100%',
            width: '100%',
            overflowY: 'auto',
            height: '100vh'
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 250,
            maxWidth: '80%',
            overflow: 'unset',
        },

    },
    // modalHeader: {
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'space-between',
    //     backgroundColor: theme.palette.primary.main,
    //     padding: theme.spacing(2),
    // },
    modalBody: {
        overflow: 'hidden'
        // padding: theme.spacing(2),
    },
    modalBodyLeft: {
        padding: "32px 24px 32px 32px !important",
        "& .ant-form-item": {
            marginBottom: 10

        }
    },
    staffs: {
        display: 'flex',
        justifyContent: 'start',
        borderRadius: 10,
        backgroundColor: '#FDFDFD',
        overflow: 'hidden',
        marginBottom: 24
    },
    staffsContent: {},
    avatar: {
        marginRight: 8
    },
    staffsContent1: {
        marginTop: 8,
        fontSize: 14,
        fontWeight: 400,
        color: '#00030E'
    },
    staffsContent2: {
        fontSize: 16,
        fontWeight: 600,
        color: '#00030E'
    },
    modalBodyRight: {
        backgroundImage: `url(${ImageBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
    labelForm: {
        fontSize: 16,
        fontWeight: 600,
        color: '#00030E',
        // marginTop: 16
    },
    // modalTitle: {
    //     color: theme.palette.primary.contrastText
    // },
    rowForm: {
        marginBottom: 16
    },
    submitBooking: {
        backgroundColor: "#006CD9",
        borderRadius: 20,
        padding: "8px 16px",
        color: "#FFFFFF",
        fontWeight: 700,
        fontSize: 16,
        "&:hover": {
            backgroundColor: "#006cd9b3",
        },
        "&:active": {
            backgroundColor: "#0554a5",
        }
    },
    modalIcon: {
        position: "absolute",
        right: 0,
        top: 0,
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.down('sm')]: {
            top: -14,
            right: -12,
            borderRadius: "50%",
            backgroundColor: "#fff",
            color: '#000',
            padding: 3,
        },
    },
}))
