/** @format */

import * as actionType from '../constants/actionType'

const DEFAULT_STATE = {
  listGroup: [],
  isLoading: false,
  error: false,
  message: '',
}

const groupReducer = (state = DEFAULT_STATE, action) => {
  let { type, payload } = action
  switch (type) {
    case actionType.GET_GROUP.REQUEST:
    case actionType.ADD_GROUP.REQUEST:
    case actionType.UPDATE_GROUP.REQUEST:
    case actionType.DELETE_GROUP.REQUEST:
    case actionType.REMOVE_GROUP_FLOOR.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: '',
      }

    case actionType.ADD_GROUP.SUCCESS:
    case actionType.UPDATE_GROUP.SUCCESS:
    case actionType.DELETE_GROUP.SUCCESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: '',
      }

    case actionType.REMOVE_GROUP_FLOOR.SUCCESS:
    case actionType.GET_GROUP.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: '',
        listGroup: payload.listGroup,
      }

    case actionType.REMOVE_GROUP_FLOOR.FAILURE:
    case actionType.GET_GROUP.FAILURE:
    case actionType.ADD_GROUP.FAILURE:
    case actionType.UPDATE_GROUP.FAILURE:
    case actionType.DELETE_GROUP.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: payload.message,
      }

    default:
      return state
  }
}

export default groupReducer
