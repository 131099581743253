/** @format */

import * as actionType from '../constants/actionType'

export const DEFAULT_STATE_PROJECT = {
  listProject: [],
  selectedProject: {},
  listInvestorFilter: [],
  projectDetail: {},
  isLoading: false,
  error: false,
  message: '',
  isCaseSuccess: null,
  isCase: '',
  idRes: '',
  nameRes: '',
  totalPage: 0,
  textSearch: '',
  page: 0
}

const projectReducer = ( state = DEFAULT_STATE_PROJECT, action ) => {
  let { type } = action
  switch ( type ) {
    case actionType.GET_LIST_PROJECT.REQUEST:
    case actionType.GET_PROJECT_DETAIL.REQUEST:
    case actionType.GET_PROJECT_DETAIL_BY_CLIENT.REQUEST:
    case actionType.CREATE_PROJECT.REQUEST:
    case actionType.UPDATE_PROJECT.REQUEST:
    case actionType.UPDATE_STATUS_PROJECT.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: '',
      }
    case actionType.DELETE_PROJECT.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: '',
      }
      case actionType.SEARCH_PROJECT_BY_FILTER.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        message: null,
      }
    case actionType.SEARCH_PROJECT_BY_FILTER.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: '',
        listProject: action.payload.listSearchProjects,
        totalPage: action.payload.totalPage,
        textSearch: action.payload.textSearch
      }

    case actionType.GET_LIST_PROJECT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        listProject: action.payload.listProject,
        totalPage: action.payload.totalPage,
        page: action.payload.page
      }
    case actionType.GET_PROJECT_DETAIL.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        projectDetail: action.payload.projectDetail,
      }
    case actionType.CREATE_PROJECT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        message: '',
        isCaseSuccess: true,
        isCase: 'Bạn đã tạo mới thành công',
        idRes: action.payload.idResponseId,
        nameRes: action.payload.idResponseName,
      }
    case actionType.UPDATE_PROJECT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        message: '',
        isCaseSuccess: true,
        isCase: 'Bạn đã cập nhật dự án thành công',
      }
    case actionType.UPDATE_STATUS_PROJECT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        message: '',
        isCaseSuccess: true,
        isCase: 'Bạn đã cập nhật trạng thái thành công',
      }
    case actionType.DELETE_PROJECT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        message: '',
        isCaseSuccess: true,
        isCase: 'Bạn đã xóa thành công',
      }
    case actionType.GET_PROJECT_DETAIL_BY_CLIENT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: '',
        selectedProject: action.payload.project,
      }

    case actionType.GET_PROJECT_DETAIL.FAILURE:
    case actionType.GET_PROJECT_DETAIL_BY_CLIENT.FAILURE:
    case actionType.DELETE_PROJECT.FAILURE:
    case actionType.CREATE_PROJECT.FAILURE:
    case actionType.UPDATE_PROJECT.FAILURE:
    case actionType.UPDATE_STATUS_PROJECT.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: action.payload.message,
        isCaseSuccess: false,
      }
    case actionType.GET_LIST_PROJECT.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: action.payload.message,
      }
    case 'RESET_PROJECT_STATE':
      return DEFAULT_STATE_PROJECT
    case 'RESET_MESSAGE_STATE':
      return {
        ...state,
        isCaseSuccess: null,
        isCase: '',
      }
    default:
      return state
  }
}

export default projectReducer
