import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

export default function Overlay({ handleClose, zIndex }) {
    const classes = useStyles()
    let zIndexDefault = 1
    return (
        <Box
            className={classes.overlay}
            style={{ zIndex: !zIndex ? zIndexDefault : zIndex }}
            onClick={handleClose}
        />
    )
}

const useStyles = makeStyles(theme => ({
    overlay: {
        backgroundColor: `rgba(0, 0, 0, .86)`,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
    }
}))