/** @format */

import React from 'react';
import Router from './router';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'antd/dist/antd.css';
import './containers/View/Mobile/Apartment/customerSlider.css'
import "./assets/css/components/_global.css";

export default function App() {
    return (<Router/>);
}
