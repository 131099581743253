/** @format */

import * as actionType from '../constants/actionType'

const DEFAULT_STATE = {
  listApartmentLayout: [],
  ApartmentCurrent:{},
  layoutDetail: [],
  listFloors: [],
  portfolios: [],
  LayoutBuilding: [],
  dataFetching: false,
  isFetching: false,
  dataFetched: false,
  error: false,
  message: null,
  isCaseSuccess: null,
  isState: '',
  totalPage: 0,
  page: 1,
  limit: 0,
  textSearchApartment: '',
}
const apartmentLayoutReducer = (state = DEFAULT_STATE, action) => {
  let { type } = action
  switch (type) {
    case actionType.GET_APARTMENTLAYOUT.REQUEST:
    case actionType.GET_LAYOUT_BY_BUILDING.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        message: null,
        textSearchApartment: '',
      }
    case actionType.GET_APARTMENTDETAIL.REQUEST:
    case actionType.STATUS_APARTMENTLAYOUT.REQUEST:
    case actionType.ADD_APARTMENTLAYOUT.REQUEST:
    case actionType.UPDATE_APARTMENTLAYOUT.REQUEST:
    case actionType.ADD_LAYOUTDRAWING.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        message: null,
      }
    case actionType.SEARCH_FILTER_APARTMENTLAYOUT.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        message: null,
        textSearchApartment: '',
      }
    case actionType.DELETE_APARTMENTLAYOUT.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        message: null,
      }
    case actionType.GET_APARTMENTLAYOUT.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: null,
        listApartmentLayout: action.payload.listLayouts,
        totalPage: action.payload.totalPage,
        page: action.payload.page,
        limit: action.payload.limit,
      }
    case actionType.GET_LAYOUT_BY_BUILDING.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: null,
        layoutBuilding: action.payload.LayoutBuilding,
        totalPage: action.payload.totalPage,
        page: action.payload.page,
      }
    case actionType.GET_APARTMENTDETAIL.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: null,
        layoutDetail: action.payload.layoutDetail,
        listFloors: action.payload.listFloors,
        portfolios: action.payload.portfolios,
      }
    case actionType.SEARCH_FILTER_APARTMENTLAYOUT.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: null,
        layoutBuilding: action.payload.listLayouts,
        totalPage: action.payload.totalPage,
        page: action.payload.page,
        limit: action.payload.limit,
        textSearchApartment: action.payload.textSearchApartment,
      }
    case actionType.ADD_APARTMENTLAYOUT.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: null,
        isCaseSuccess: true,
        isState: 'Dữ liệu đã được thêm mới !',
        dataFetching: true,
      }
    case actionType.ADD_LAYOUTDRAWING.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: null,
        isCaseSuccess: true,
        isState: 'Dữ liệu LayoutDrawing đã được thêm mới !',
        dataFetching: true,
      }
    case actionType.UPDATE_APARTMENTLAYOUT.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: null,
        isCaseSuccess: true,
        isState: 'Dữ liệu đã được cập nhật !',
      }
    case actionType.DELETE_APARTMENTLAYOUT.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: null,
        isCaseSuccess: true,
        isState: 'Xóa thành công !',
      }
    case actionType.STATUS_APARTMENTLAYOUT.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        message: null,
        isCaseSuccess: true,
        isState: 'Đã cập nhật trạng thái thành công !',
      }
    case actionType.GET_APARTMENTLAYOUT.FAILURE:
    case actionType.GET_APARTMENTDETAIL.FAILURE:
    case actionType.ADD_APARTMENTLAYOUT.FAILURE:
    case actionType.UPDATE_APARTMENTLAYOUT.FAILURE:
    case actionType.DELETE_APARTMENTLAYOUT.FAILURE:
    case actionType.STATUS_APARTMENTLAYOUT.FAILURE:
    case actionType.GET_LAYOUT_BY_BUILDING.FAILURE:
    case actionType.ADD_LAYOUTDRAWING.FAILURE:
    case actionType.SEARCH_FILTER_APARTMENTLAYOUT.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        message: action.payload.message,
        isCaseSuccess: false,
      }
    case 'RESET_MESSAGE_STATE':
      return {
        ...state,
        isCaseSuccess: null,
      }
    // case "RESET_APARTMENTLAYOUT_STATE":
    //     return DEFAULT_STATE;
    case 'RESET_APARTMENTLAYOUT_STATE':
      return {
        ...state,
        // listApartmentLayout: [],
        layoutDetail: [],
        listFloors: [],
        portfolios: [],
        // LayoutBuilding: [],
        dataFetching: false,
        isFetching: false,
        dataFetched: false,
        error: false,
        message: null,
        isCaseSuccess: null,
        isState: '',
        totalRecords: 0,
      }
    default:
      return state
  }
}

export default apartmentLayoutReducer
