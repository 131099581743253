/** @format */

import { DOMAIN } from '../redux/constants/actionType';
import configApi from '../utils/configApi';

export const fetchStaffBookingApi = configApi({
  baseUrl: DOMAIN,
  path: 'care-staffs/find-one-random',
  method: 'GET',
});
export const fetchBookingApi = configApi({
  baseUrl: DOMAIN,
  path: 'lead-forms',
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
});
export const getDomainApi = configApi({
  baseUrl: DOMAIN,
  path: 'lead-form-domains',
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
});

