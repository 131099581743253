/** @format */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {
    Box,
    ButtonBase,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import {ArrowLeft} from '@material-ui/icons'
import useStyles from './ViewProjectStyles'
import ProjectSidebar from '../../../../components/Sidebar/ProjectSidebar'
import FloorFigure from '../../../../components/Figure/FloorFigure'
import ApartmentSlider from '../../../../components/Slider/ApartmentSlider'
import FullScreenLoading from '../../../../components/common/Loading/FullScreenLoading'
import ShowCompass from '../../../../components/Compass/ShowCompass'
import {DOMAIN_IMAGE} from '../../../../redux/constants/actionType'
import {isNotEmptyObject, isWindow} from "../../../../utils/help";
import {useHistory} from "react-router-dom";

function ViewProject(props) {
    const {project, handleShowModalRegister, projectSlug, buildingSlug, apartmentSlug} = props
    const theme = useTheme()
    const matchMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const matchTablet = useMediaQuery(theme.breakpoints.down('769'))
    const matchIpadPro = useMediaQuery(theme.breakpoints.between('961', '1025'))
    const matchMediumScreen = useMediaQuery(theme.breakpoints.down('1401'))
    const matchSmallScreen = useMediaQuery(theme.breakpoints.down('1100'))
    const matchIframe = useMediaQuery(theme.breakpoints.down('1251'))
    const [toggle, setToggle] = useState(true)
    const [selectedLayout, setSelectedLayout] = useState()
    const [coordinates, setCoordinates] = useState([])
    const [layoutPointHover, setLayoutPointHover] = React.useState({})
    const [imgSize, setImgSize] = useState({width: 330, height: 495})
    const classes = useStyles()
    const history = useHistory()
    const handleToggle = () => {
        setToggle(!toggle)
    }
    const handleOnClickPointHover = () => {
       if (isNotEmptyObject(layoutPointHover?.slug)){
           history.push(`${isWindow && window.location.pathname}/${layoutPointHover?.slug}`)
       }
    }
    useEffect(() => {
        if (props?.floorLink?.layoutCoordinates?.length > 0) {
            const index = props?.floorLink?.layoutCoordinates?.findIndex(
                ({layout}) => layout?.id === selectedLayout?.id,
            )
            setCoordinates(props?.floorLink?.layoutCoordinates[index]?.coordinates)
        } else {
            setCoordinates([])
        }
    }, [selectedLayout])
    useEffect(() => {
        // let width = 350;
        let width = ((isWindow ? window.innerWidth : 1920) * 220) / 1280
        if (matchMobile || matchTablet) {
            width = 230
            setImgSize({width, height: width * 1.5})
        } else if (matchIpadPro) {
            width = 290
            setImgSize({width, height: width * 1.5})
        } else if (matchIframe) {
            width = ((isWindow ? window.innerWidth : 1251) * 180) / 1280
            setImgSize({width, height: width * 1.5})
        } else {
            if (matchSmallScreen) {
                width = ((isWindow ? window.innerWidth : 1920) * 220) / 1280
            }
            setImgSize({width, height: width * 1.5})
        }
    }, [matchMediumScreen, matchIpadPro, matchSmallScreen, isWindow && window?.innerWidth])

    return (
        <Box>
            {Boolean(props.isFetching && props.isLoading)
                ? (<FullScreenLoading/>)
                : <Box className={classes.viewProject}>
                    <Box
                        className={`${
                            matchMobile ? classes.sidebarMobile : classes.sidebar
                        } ${toggle ? 'toggle' : ''}`}
                    >
                        <ProjectSidebar
                            domainAvail={props?.domainAvail}
                            setShowModal={handleShowModalRegister}
                            handleToggle={handleToggle}
                            imgSize={imgSize}
                            projectSlug={projectSlug}
                            buildingSlug={buildingSlug}
                            apartmentSlug={apartmentSlug}
                        />
                        {/* nút thu gọn bộ lọc bên trái*/}
                        <ButtonBase
                            style={{
                                display: toggle ? "block" : "none"
                            }}
                            className={`${classes.sidebarToggle}`}
                            onClick={handleToggle}
                        >
                            <ArrowLeft className={`${!toggle && classes.reverse}`}/>
                        </ButtonBase>
                    </Box>
                    {/* mặt bằng tầng bên phải*/}
                    <Box
                        className={`${matchMobile ? classes.mainMobile : classes.main} ${
                            toggle ? '' : 'expand'
                        } bg-overlay`}
                        style={{
                            backgroundImage: `url('${
                                project?.backgroundSitePlan
                                && DOMAIN_IMAGE + project?.backgroundSitePlan?.url

                            }')`,
                        }}
                    >
                        <div className={classes.bgOverlay}/>
                        {props.apartmentLoading ? (
                            <></>
                            // <FullScreenLoading />
                        ) : (
                            <>
                                <FloorFigure
                                    layoutCoordinates={props?.floorLink?.layoutCoordinates || []}
                                    layoutSrc={props?.floorLink?.imageFloorPlan?.url}
                                    selectedLayout={selectedLayout}
                                    coordinates={coordinates}
                                    setLayoutPointHover={setLayoutPointHover}
                                    handleOnClickPointHover={handleOnClickPointHover}
                                    setSelectedLayout={setSelectedLayout}
                                    setCoordinates={setCoordinates}
                                    listApartments={props?.listApartments}
                                    toggle={toggle}
                                    projectSlug={projectSlug}
                                    floorLink={props?.floorLink}
                                />

                                {/*ảnh la bàn*/}
                                <Box
                                    style={{
                                        position: 'absolute',
                                        bottom: matchTablet
                                            ? (isWindow ? window.screen.width : 768) * (150 / 768)
                                            : matchIpadPro
                                                ? (isWindow ? window.screen.width : 1024) * (170 / 1024)
                                                : matchSmallScreen
                                                    ? (isWindow ? window.screen.width : 1100) * (150 / 1280)
                                                    : toggle
                                                        ? (isWindow ? window.screen.width : 1920) * (170 / 1280)
                                                        : "2rem",

                                        left: !toggle ? (isWindow ? window.screen.width : 1920) * (250 / 1280) + 20 : 0,
                                    }}
                                >
                                    <Box>
                                        <ShowCompass
                                            rote={
                                                props.floorLink && props.floorLink.Compass
                                                    ? props.floorLink.Compass
                                                    : 0
                                            }
                                        />
                                    </Box>
                                </Box>
                                {/* slide cuối cùng của căn hộ / phòng*/}
                                <ApartmentSlider
                                    handleToggle={handleToggle}
                                    selectedLayout={selectedLayout}
                                    layoutPointHover={layoutPointHover}
                                    setSelectedLayout={setSelectedLayout}
                                    toggle={toggle}
                                    setCoordinates={setCoordinates}
                                    listApartments={props?.listApartments}
                                    projectSlug={props?.project?.slug}
                                />
                            </>
                        )}
                    </Box>
                </Box>
            }
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        project: state.project.selectedProject,
        listApartments: state.floor.listApartments,
        isLoading: state.project.isLoading,
        isFetching: state.building.isFetching,
        apartmentLoading: state.floor.isLoading,
        floorLink: state.floor.selectedFloor?.list_floor_link,

    }
}

export default connect(mapStateToProps, null)(ViewProject)


