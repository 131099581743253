/** @format */

import { DOMAIN } from '../redux/constants/actionType';
import configApi from '../utils/configApi';
import LocalWorker from './../utils/localWorker';
const localWorker = LocalWorker();
const token = localWorker.getToken();

export const getFloorDetailByClient = configApi({
  method: 'GET',
  baseUrl: DOMAIN,
  path: 'layouts/list-floor-links',
});

export const getFloorListByBuilding = configApi({
  method: 'GET',
  baseUrl: DOMAIN,
  path: 'list-floors',
});

export const getFloorDetailByBuilding = configApi({
  method: 'GET',
  baseUrl: DOMAIN,
  path: 'list-floors',
});

export const addFloorList = configApi({
  method: 'POST',
  baseUrl: DOMAIN,
  path: 'list-floors/gennerate',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});

export const updateFloor = configApi({
  method: 'PUT',
  baseUrl: DOMAIN,
  path: 'list-floors',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});

export const deleteFloor = configApi({
  method: 'POST',
  baseUrl: DOMAIN,
  path: 'list-floors/deleteAuto',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});

export const getLayout = configApi({
  method: 'GET',
  baseUrl: DOMAIN,
  path: 'layouts',
});

export const getNumDesignByClient = configApi({
  method: 'POST',
  baseUrl: DOMAIN,
  path: 'portfolios/layouts_drawings',
});

export const getListFloorLinkByClient = configApi({
  method: 'GET',
  baseUrl: DOMAIN,
  path: 'list-floor-links',
});
