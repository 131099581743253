/** @format */

import React from 'react';
import {Box, makeStyles} from '@material-ui/core';


export default function FullScreenLoading(props) {
    // const { title } = props
    const classes = useStyles();
    return (
        <>
            <div className="overlay">
                <div className="overlay__inner">
                    <div className="overlay__content"><span className="spinner"/></div>
                </div>
            </div>

        </>
    );
}

const useStyles = makeStyles((theme) => ({

    loadingWrapper: {
        zIndex: 1000,
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'transparent',
        // backgroundColor: '#e8e7e4',
        opacity: 0.7,
    },
    copyright: {
        position: 'fixed',
        top: '70%',
        transform: 'translateX(-50%)',
        left: '50%',
        // position: 'absolute',
        // left: "30%",
        // top: "90%",
        width: '60%',
        textAlign: 'center',
        color: '#fafafa',
        zIndex: 1001,
    },
    copyrightTitle: {
        position: 'fixed',
        top: '70%',
        transform: 'translateX(-50%)',
        left: '50%',
        // position: 'absolute',
        // left: "30%",
        // top: "90%",
        width: '60%',
        textAlign: 'center',
        color: '#000',
        zIndex: 1001,
    },
    boxCopyright: {
        // position:'relative',
        width: '100%',
    },
    loadingGif: {
        maxWidth: 200,
    },
}));
