import {isWindow} from "./help";

const localWorker = () => {
    const localStorage = isWindow ? window.localStorage : undefined
    const getToken = () => {
        return isWindow ? localStorage.getItem('auth-token') : null
    }
    const setToken = (token) => {
        isWindow && localStorage.setItem('auth-token', token)
    }
    const removeToken = () => {
        isWindow && localStorage.removeItem('auth-token')
    }

    const getUser = () => {
        return isWindow ? JSON.parse(localStorage.getItem('user')) : null
    }

    const setUser = (user) => {
        isWindow && localStorage.setItem('user', JSON.stringify(user))
    }

    const removeUser = () => {
        isWindow && localStorage.removeItem('user')
    }

    return {
        getToken,
        setToken,
        removeToken,
        getUser,
        setUser,
        removeUser
    }
}


export default localWorker;