/** @format */

import {makeStyles} from '@material-ui/core';
import {isWindow} from "../../utils/help";

export default makeStyles((theme) => {
    return {
        floorFigureSidebar: {
            overflow:'hidden'
            // backgroundColor: '#FAFCFF',
            // width: '100vw',
            // height: '100vh',
            // position: 'absolute',
            // top: 0,
            // transform: `translateX(calc(100% + 100px))`,
            // transition: '300ms',
            // '&.show': {
            //     transform: `translateX(0)`,
            // },
        },
        navBar: {
            display: 'flex',
            paddingTop: 25,
            paddingBottom:24,
            justifyContent: "center",
            alignItems: "center"
        },
        backBtn: {
            height: '3rem',
            '&.MuiButton-root': {
                backgroundColor: 'white',
                borderRadius: '8px',
                borderColor: theme.palette.secondary.dark,
            },
        },
        titleText: {
            fontWeight: 400,
            color: '#6A6A6A',
            fontSize: 14,
        },
        title: {
            marginLeft: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        titleBottom: {
            fontWeight: 'bold',
            color: 'white',
        },
        inputRoot: {},
        titleTop: {
            display: 'flex',
          marginTop: 4,

        },
        divideTitle: {
            backgroundColor: '#C1C1C1',
            color: "#C1C1C1",
            margin: `0 5px`,
            height: 12,
            alignSelf: "center"
        },
        list: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyItems: 'flex-start',
            marginLeft: '-5px',
        },
        listItem: {
            flexShrink: 0,
            flexGrow: 0,
            justifyContent: 'center',
            width: '20%',
        },
        itemBtn: {
            height: ((isWindow ? window.innerWidth : 1920) * 70) / 475,
            flex: `0 0 ${((isWindow ? window.innerWidth : 1920) * 70) / 475}px`,
            fontSize: ((isWindow ? window.innerWidth : 1920) * 20) / 475,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '10px',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.secondary.dark,
            color: theme.palette.secondary.dark,
            fontWeight: 'bold',
            '&.active': {
                backgroundColor: theme.palette.secondary.dark,
                color: theme.palette.secondary.contrastText,
            },
        },
        floorLabel: {
            height: ((isWindow ? window.innerWidth : 1920) * 70) / 475,
            flex: `0 0 ${((isWindow ? window.innerWidth : 1920) * 70) / 475}px`,
            fontSize: ((isWindow ? window.innerWidth : 1920) * 14) / 475,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.secondary.dark,
            textTransform: 'uppercase',
            '&.active': {
                backgroundColor: theme.palette.secondary.dark,
                color: theme.palette.secondary.contrastText,
            },
        },
        listWrapper: {
            padding: theme.spacing(2),
        },
        autocomplete: {
            background: "#FDFDFD",
            // border: "1px solid #C1C1C1",
            // boxSizing: "border-box",
            // borderRadius: 5,
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                padding: "4px 16x",
                width: "100%",
                fontSize: 16,
                fontWeight: 600,
                color: "#00030E"
            }
        },
    };
});
