export const runtimeConfig =
    typeof window !== 'undefined'
        ? {
            // client
            NODE_ENV: window.env.NODE_ENV,
            RAZZLE_BACKEND_URL: window.env.RAZZLE_BACKEND_URL,
            RAZZLE_USER: window.env.RAZZLE_USER,
            RAZZLE_BASE_URL: window.env.RAZZLE_BASE_URL,
            RAZZLE_GOOGLE_MAP_KEY: window.env.RAZZLE_GOOGLE_MAP_KEY,
            RAZZLE_WEBUSER_FLEXHOME_URL: window.env.RAZZLE_WEBUSER_FLEXHOME_URL,
        }
        : {
            // server
            NODE_ENV: process.env.NODE_ENV,
            RAZZLE_BACKEND_URL: process.env.RAZZLE_BACKEND_URL,
            RAZZLE_USER: process.env.RAZZLE_USER,
            RAZZLE_BASE_URL: process.env.RAZZLE_BASE_URL,
            RAZZLE_GOOGLE_MAP_KEY: process.env.RAZZLE_GOOGLE_MAP_KEY,
            RAZZLE_WEBUSER_FLEXHOME_URL: process.env.RAZZLE_WEBUSER_FLEXHOME_URL,
        };