/** @format */

import {makeStyles} from '@material-ui/core'
import {isWindow} from "../../../../utils/help";
// import IconBack from "../../../assets/images/v2/icon_back.svg";

export const headerStyles = makeStyles((theme) => {
    return {
        '&.MuiAutocomplete-inputRoot[class*="MuiInput-root"][class*="MuiInput-marginDense"] &.MuiAutocomplete-input:first-child':
            {
                padding: '3px 0 0 !important',
            },
        title: {
            fontSize: '24px',
            fontWeight: 'bold',
            [theme.breakpoints.down(1025)]: {
                fontWeight: 600,
                fontSize: 15,
            },
        },
        button: {
            // padding: 0
            fontSize: '16px',
            fontWeight: 600,
            width: '65%',

            '& input':
                {
                    // color: '#fff',
                    fontWeight: 600,
                    /* text-align: center, */
                    fontSize: '16px',
                    padding: '1px 0px',
                },
        },
        infoHeader: {
            textAlign: "center",
            marginTop: 15,
            color: "#6A6A6A",
            fontSize: 14,
            fontWeight: "normal",
            letterSpacing: 1,
        },
        icon: {
            fontSize: 12,
        },
        container: {
            padding: 32,
            backgroundColor: "#FAFCFF",
        },
        item1: {
            display: 'flex',
            alignItems: 'center',
            // height: '100%',
            padding: '10px 0',
            height: '10%',
            justifyContent: "space-between",
        },
        IconBack: {
            cursor: "pointer"
        },
        inputRoot: {
            '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                // padding: '3px 0 0 !important'
            },
        },
        inputColor: {
            // padding: '3px 0 0 !important'
        },
        ".MuiAutocomplete-popper":{
            backgroundColor:"red"
        }
    }
})
export const footerStyles = makeStyles((theme) => {
    return {
        blockLeft: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
        },
        image: {
            maxWidth: 80,
        },
        blockRight: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'flex-end',
        },
        buttonShare: {
            margin: '0 10px',
        },
        icon: {
            paddingRight: 10,
        },
        text: {
            margin: '0 4px',
            fontSize: '12px',
            fontWeight: 'Regular',
            color: '#000',
            [theme.breakpoints.down(1025)]: {
                fontSize: '0.5rem',
                margin: '0 4px',
                fontWeight: 400,
            },
        },
        buttonGroup: {
            textTransform: 'none',
            height: '80%',
            fontSize: '0.6rem',
            [theme.breakpoints.down(1025)]: {
                fontSize: '0.5rem',
            },
        },
        gridContainer: {
            height:(isWindow ? window.innerHeight : 968) * 0.05,
        },
    }
})
