/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect} from 'react'
import {
    List,
    ListItem,
    ButtonBase,
    IconButton,
    Box,
    Typography, Tooltip,
} from '@material-ui/core'
import {createComponent} from '../../utils/createComponent'
import useStyles from './BuildingFigureStyles'
import {useTheme, useMediaQuery} from '@material-ui/core'
import building_mockup from '../../assets/images/building_mockup2.jpg'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import {DOMAIN_IMAGE} from '../../redux/constants/actionType'
import * as action from "../../redux/actions";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {isWindow} from "../../utils/help";
import FloorHighlight from '../Marker/FloorHighlight'

function BuildingFigure({
                            listFloor,
                            featuredImage,
                            imgSize,
                            handleToggle,
                            selectedFloor,
                            apartmentSlug,
                            setSelectedFloor,
                            ...rest
                        }) {
    const theme = useTheme()
    const matchMobile = useMediaQuery(theme.breakpoints.down('xs'))
    const matchTablet = useMediaQuery(theme.breakpoints.down('769'))
    const [disableTop, setDisableTop] = useState(true)
    const [disableBottom, setDisableBottom] = useState(false)
    const [hoverFloor, setHoverFloor] = useState({})
    const classes = useStyles({height: imgSize?.height})
    const history = useHistory()

    const handleDisableButtonScrollFloor = () => {
        const childHeight = document.getElementById('listNum').offsetHeight
        const child = document.getElementById('vertical-scroll')
        const parent = document.getElementById('vertical-parent-scroll').offsetTop
        const top = document.getElementById('vertical-BoxTop-scroll').offsetHeight
        const bottom = document.getElementById('vertical-BoxBottom-scroll').offsetTop
        if ((child.offsetTop + child.scrollTop) === (parent + top)) {
            setDisableTop(true)
            setDisableBottom(false)
        } else {
            if (childHeight - child.scrollTop + (isWindow ? window.innerWidth : 1920) * (210 / 1920) < (bottom - top)) {
                setDisableBottom(true)
            } else {
                setDisableBottom(false)
            }
            setDisableTop(false)
        }
    }
    useEffect(() => {
        handleDisableButtonScrollFloor()
        document.getElementById('vertical-scroll').addEventListener("scroll", function () {
            handleDisableButtonScrollFloor()
        });
    }, [apartmentSlug])

    const handleChangeFocus = (direct) => () => {
        if (direct === 'left') {
            document.getElementById('listNum').scrollLeft -= 100
            document.getElementById('vertical-scroll').scroll({
                top:
                    document.getElementById('vertical-scroll').scrollTop -
                    document.getElementById('vertical-scroll').offsetHeight +
                    40,
                left:
                    document.getElementById('vertical-scroll').scrollLeft -
                    document.getElementById('vertical-scroll').offsetWidth +
                    40,
                behavior: 'smooth',
            })
        }
        if (direct === 'right') {
            document.getElementById('listNum').scrollLeft += 100
            document.getElementById('vertical-scroll').scroll({
                top:
                    document.getElementById('vertical-scroll').scrollTop +
                    document.getElementById('vertical-scroll').offsetHeight -
                    40,
                left:
                    document.getElementById('vertical-scroll').scrollLeft +
                    document.getElementById('vertical-scroll').offsetWidth -
                    40,
                behavior: 'smooth',
            })
        }
    }

    const handleSelectFloor = (item) => () => {
        setSelectedFloor(item);
        history.push(`${item?.floorID}`);
        (matchMobile || matchTablet) && handleToggle()
    }

    const handleHoverFloor = (item) => () => {
        item?.list_floor_link && setHoverFloor(item)
    }


    const renderFloorList = (item, index) => (
        <ListItem className={classes.listItem} key={index}>
            <ButtonBase
                className={`${classes.itemBtn} ${item?.floorID === selectedFloor?.floorID && 'active'} ${
                    item?.floorID === hoverFloor?.floorID && item?.floorID !== selectedFloor?.floorID && 'hover'
                }`}
                onClick={handleSelectFloor(item)}
                onMouseEnter={handleHoverFloor(item)}
                disabled={
                    !item?.list_floor_link ||
                    item?.list_floor_link?.layoutCoordinates.length <= 0
                }
            >
                {item?.label}
            </ButtonBase>
        </ListItem>
    )

    return (
        <FigureWrapper className={classes.figureWrapper}>
            <BoardFloor className={classes.boardFloor} id={'vertical-parent-scroll'}>
                <Box id={'vertical-BoxTop-scroll'} className={classes.actionList}>
                    <Box style={{position: 'relative'}}>
                        <Typography className={classes.actionText}>Chọn tầng</Typography>
                        {/*<Divider className={classes.divider}/>*/}
                    </Box>
                    <IconButton
                        style={{
                            opacity: disableTop ? "0.5" : 1
                        }}
                        disabled={disableTop}
                        size='small'
                        className={classes.btnFloor}
                        onClick={handleChangeFocus('left')}
                    >
                        <KeyboardArrowUpIcon/>
                    </IconButton>

                </Box>
                <div
                    className={classes.listWrapper}
                    onMouseLeave={() => setHoverFloor(-1)}
                    id={'vertical-scroll'}
                >
                    <List className={classes.list} id={'listNum'}>
                        {listFloor?.map(renderFloorList)}
                    </List>
                </div>
                <Box id={'vertical-BoxBottom-scroll'} className={classes.actionList}>
                    {/*<Divider className={classes.divider}/>*/}
                    <IconButton
                        style={{
                            opacity: disableBottom ? "0.5" : 1
                        }}
                        disabled={disableBottom}
                        size='small'
                        className={classes.btnFloor}
                        onClick={handleChangeFocus('right')}
                    >
                        <KeyboardArrowDownIcon/>
                    </IconButton>
                </Box>
            </BoardFloor>
            <ImageBlock className={classes.figureImage}>
                {selectedFloor &&
                    <FloorHighlight
                        fontSize={12}
                        widthImg={imgSize?.width}
                        heightImg={imgSize?.height}
                        image={DOMAIN_IMAGE + featuredImage?.url || building_mockup}
                        tagFloor={selectedFloor}
                        readonly={true}
                    />
                }
            </ImageBlock>

        </FigureWrapper>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {

        getFloorDetail: ({id, buildingId, floorName}) =>
            dispatch(
                action.getFloorDetailByClient.request({id, buildingId, floorName}),
            ),
        setSelectedFloor: (data) => {
            dispatch(action.setSelectedFloor.success(data))
        },

    }
}
const mapStateToProps = (state) => {
    return {
        featuredImage: state.building.selectedBuilding?.overview_image,
        listFloor: state.floor.listFloor,
        selectedFloor: state.floor.selectedFloor,

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BuildingFigure)

const FigureWrapper = createComponent()
const ImageBlock = createComponent()
const BoardFloor = createComponent()
