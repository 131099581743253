/** @format */

import React from 'react'
import { Grid, Box, ButtonBase } from '@material-ui/core'
import useStyles from './DesignGallerySliderStyles'
import Arrow from '../../../assets/images/right@2x.png'
import { DOMAIN_IMAGE } from '../../../redux/constants/actionType'
import ImageNotAvailable from '../../../assets/images/v2/image-not-avalible.png'
// import * as url from "url";

export function SliderItem({ item, index, currentIndex }) {
  const classes = useStyles()
  return (
    <Box
    //  style={{ padding: `5px 0px` }}
    >
      <Grid
        container
        className={`${classes.sliderItem} ${
          currentIndex === index && classes.active
        }`}
        component={ButtonBase}
      >
        { item.vr_url ? (
          <Grid item xs={12}>
            <Box
              className={`${classes.VRItem} ${
                currentIndex === index ? classes.VRActive : classes.VRImage
              }`}
              style={{
                backgroundImage: `url(${item?.url ? DOMAIN_IMAGE + item?.url :  ImageNotAvailable})`,
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Box
              className={`${
                currentIndex === index
                  ? classes.sliderImageActive
                  : classes.sliderImage
              }`}
              style={{ backgroundImage: `url(${item?.url ? DOMAIN_IMAGE + item?.url : ImageNotAvailable})` }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export function SliderPrevArrow(props) {
  // className, style in props for default setting
  const { onClick } = props
  const classes = useStyles()
  return (
    <Box
      onClick={onClick}
      className={`${classes.sliderArrow} prev`}
      component={ButtonBase}
      style={{ backgroundImage: `url(${Arrow})` }}
    />
  )
}

export function SliderNextArrow(props) {
  // className, style in props for default setting
  const { onClick } = props
  const classes = useStyles()
  return (
    <Box
      onClick={onClick}
      className={`${classes.sliderArrow} next`}
      component={ButtonBase}
      style={{ backgroundImage: `url(${Arrow})` }}
    />
  )
}
