/** @format */

import * as actionType from '../constants/actionType';

export const DEFAULT_STATE_PORTFOLIOS = {
  portfolios: [],
  listNumDesign: [],
  isLoading: false,
  error: false,
  message: '',
  resetState: 0,
  id: ''
};

const projectReducer = (state = DEFAULT_STATE_PORTFOLIOS, action) => {
  let { type, payload } = action;
  switch (type) {
    case actionType.GET_PORTFOLIOS_BY_LAYOUTDRAWING.REQUEST:
    case actionType.GET_LIST_NUM_DESIGN_BY_CLIENT.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: '',
        resetState: Math.random(10),
      };
    case actionType.GET_PORTFOLIOS_BY_LAYOUTDRAWING.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: '',
        portfolios: payload.portfolios,
        id: payload.id
      };
    case actionType.GET_LIST_NUM_DESIGN_BY_CLIENT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: '',
        listNumDesign: payload.listNumDesign,
      };
    case actionType.GET_PORTFOLIOS_BY_LAYOUTDRAWING.FAILURE:
    case actionType.GET_LIST_NUM_DESIGN_BY_CLIENT.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: action.payload.message,
      };
    case actionType.CLEAR_STATE_APARTMENT_BY_CLIENT.REQUEST:
      console.log("states", state)
      return {
        ...state,
        resetState: Math.random(10),
      };
    default:
      return state;
  }
};

export default projectReducer;
