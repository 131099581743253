/** @format */
/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react'

function HomeComponent(props) {
    const {
        image,
        widthImg,
        heightImg,
    } = props

    return (
        <div>
            <canvas
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: '4px',
                    // boxShadow: '2px 1px 6px',
                    width: "100%",
                }}
                id='floor-canvas'
                width={widthImg}
                height={heightImg}
            />
        </div>
    )
}

export default HomeComponent
