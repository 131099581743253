/** @format */

import React, {useEffect, useRef, useState} from 'react'
import Slider from 'react-slick'
import {useTheme, useMediaQuery, ButtonBase} from '@material-ui/core'
import {
    SliderItem,
    SliderNextArrow,
    SliderPrevArrow,
    SliderRightArrow,
    SliderLeftArrow,
} from './ApartmentSliderFragments'
import useStyles from './ApartmentSliderStyles'
import {ArrowLeft} from "@material-ui/icons";
import {isNotEmptyObject, isWindow} from "../../utils/help";

export default function ApartmentSlider({
                                            toggle,
                                            listApartments,
                                            projectSlug,
                                            setSelectedLayout,
                                            setCoordinates,
                                            selectedLayout,
                                            layoutPointHover,
                                            ...rest
                                        }) {
    const classes = useStyles()
    const theme = useTheme()
    const matchMobile = useMediaQuery(theme.breakpoints.down('xs'))
    const matchTablet = useMediaQuery(theme.breakpoints.down('769'))
    const matchIpadPro = useMediaQuery(theme.breakpoints.between('961', '1025'))
    const matchSmallScreen = useMediaQuery(theme.breakpoints.down('1100'))
    const matchIframe = useMediaQuery(theme.breakpoints.down('1251'))
    const matchMediumScreen = useMediaQuery(theme.breakpoints.down('1441'))
    const ref = useRef();
    const [settings, setSettings] = useState({
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        vertical: false,
        swipe: false,
        beforeChange: function (currentSlide, nextSlide) {
            // console.log(222, listApartments.length, currentSlide, nextSlide)
        },
        afterChange: function (currentSlide) {
            // console.log(333, currentSlide)

        },
        nextArrow: <SliderRightArrow/>,
        prevArrow: <SliderLeftArrow/>,
    })

    useEffect(() => {
        if (
            matchTablet ||
            matchIpadPro ||
            matchMediumScreen ||
            matchSmallScreen ||
            matchIframe ||
            toggle
        ) {
            // side collapse or match tablet + lap + desk
            setSettings({
                ...settings,
                arrows: true,
                swipe: false,
                vertical: false,
                verticalSwiping: false,
                slidesToShow: 4,
                nextArrow: <SliderRightArrow/>,
                prevArrow: <SliderLeftArrow/>,
            })
            if (!toggle && matchMediumScreen) {
                setSettings({
                    ...settings,
                    arrows: true,
                    vertical: true,
                    verticalSwiping: true,
                    slidesToShow: 3,
                    slidesToScroll: 4,
                    nextArrow: <SliderNextArrow/>,
                    prevArrow: <SliderPrevArrow/>,
                })
            }

            if (toggle && matchIframe) {
                setSettings({
                    ...settings,
                    arrows: true,
                    swipe: false,
                    vertical: false,
                    verticalSwiping: false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    nextArrow: <SliderRightArrow/>,
                    prevArrow: <SliderLeftArrow/>,
                })
            }

            if (toggle && matchSmallScreen) {
                setSettings({
                    ...settings,
                    swipe: true,
                    vertical: false,
                    verticalSwiping: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                })
            }

            if (!toggle && matchSmallScreen) {
                setSettings({
                    ...settings,
                    swipe: true,
                    vertical: false,
                    verticalSwiping: false,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                })
            }

            if (!toggle && matchTablet) {
                setSettings({
                    ...settings,
                    swipe: true,
                    vertical: false,
                    verticalSwiping: false,
                    slidesToShow: 3,
                    slidesToScroll: 3,

                    arrows: false,
                })
            }

            if (toggle && matchTablet) {
                setSettings({
                    ...settings,
                    swipe: true,
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    arrows: false,
                })
            }
        } else {
            // side expand over md screen
            setSettings({
                ...settings,
                arrows: true,
                vertical: true,
                verticalSwiping: true,
                slidesToShow: 4,
                nextArrow: <SliderNextArrow/>,
                prevArrow: <SliderPrevArrow/>,
            })
        }
        // eslint-disable-next-line
    }, [
        toggle,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        isWindow && window?.screen?.width,
        matchIpadPro,
        matchSmallScreen,
        matchMediumScreen,
        matchMobile,
        matchTablet,
    ])
    const handleHoverOut = () => {
        setCoordinates([])
        setSelectedLayout()
    }
    useEffect(() => {
        if (isNotEmptyObject(layoutPointHover)) {
            const index = listApartments.findIndex(item => item?.id === layoutPointHover?.id)
            if (index >= 0) {
                ref.current.slickGoTo(index)
            }
        }
    }, [layoutPointHover])

    useEffect(() => {
        let slickListDiv = document.getElementsByClassName('slick-slider')[0]
        slickListDiv?.addEventListener('wheel', event => {
            event.preventDefault()
            event.deltaY > 0 ? ref.current.slickNext() : ref.current.slickPrev()
        })
    }, [])

    return (
        <>
            <div
                onMouseLeave={handleHoverOut}
                className={`${classes.slideWrapper} ${toggle || matchIpadPro || matchTablet ? 'row' : 'column'
                }`}
            >
                <div className={classes.titleSlide}>
                    Chọn căn hộ để xem thiết kế mẫu
                </div>
                {listApartments.length > 0 && (
                    <Slider ref={ref} {...settings}>
                        {listApartments?.map((item, index) => (
                            <SliderItem
                                key={index}
                                item={item}
                                setSelectedLayout={setSelectedLayout}
                                layoutPointHover={layoutPointHover}
                            />
                        ))}
                    </Slider>
                )}
            </div>
            <ButtonBase
                style={{
                    display: toggle ? "none" : "block"
                }}
                className={classes.sidebarToggle}
                onClick={rest.handleToggle}
            >
                <ArrowLeft/>
            </ButtonBase>
        </>

    )
}
