/** @format */

import { takeEvery, put } from '@redux-saga/core/effects';
import * as actionType from '../constants/actionType';
import * as action from '../actions';
import * as floorLinkApi from '../../api/floorLinkApi';

function* handleGetFloorLink({ payload }) {
  try {
    let res = yield floorLinkApi.getFloorLink(
      {
        buildingId: payload.id,
      },
      null,
      null,
    );


    res = res.sort((a, b) => {
      if (+a?.list_floors[0]?.floorID > +b?.list_floors[0]?.floorID) {
        return 1;
      }
      if (+a?.list_floors[0]?.floorID < +b?.list_floors[0]?.floorID) {
        return -1;
      }
      return 0;
    });

    yield put(action.getFloorLink.success({ res }));
    //Fetch Api
  } catch (error) {
    yield put(action.getFloorLink.failure(error));
  }
}
function* handleUpdateFloorLink({ payload }) {
  try {
    const res = yield floorLinkApi.updateFloorLink(
      payload.floorLink,
      { id: payload.floorLink.id },
      null,
    );

    yield put(action.updateFloorLink.success(res));
    yield put(action.getFloorLink.request({ id: payload.buildingId }));
    //Fetch Api
  } catch (error) {
    yield put(action.updateFloorLink.failure(error));
  }
}

const floorLinkSaga = [
  takeEvery(actionType.GET_FLOOR_LINK.REQUEST, handleGetFloorLink),
  takeEvery(actionType.UPDATE_FLOOR_LINK.REQUEST, handleUpdateFloorLink),
];

export default floorLinkSaga;
